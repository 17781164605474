import { AWS_TOKEN, REDIRECT_TO_SIGNUP_AWS } from "./keys";

// Remove the AWS token immediately, because we don't want it lingering
// and interfering with future sign-up requests.
export const getAndRemoveAwsSubscriptionToken = () => {
  const awsToken = localStorage.getItem(AWS_TOKEN) ?? undefined;
  localStorage.removeItem(AWS_TOKEN);
  return awsToken;
};

export const setAwsSubscriptionToken = (awsToken: string) => {
  localStorage.setItem(AWS_TOKEN, awsToken);
};

export const setRedirectToSignupAws = () => {
  localStorage.setItem(REDIRECT_TO_SIGNUP_AWS, "true");
};

export const getRedirectToSignupAws = () => {
  return localStorage.getItem(REDIRECT_TO_SIGNUP_AWS) === "true";
};

export const removeRedirectToSignupAws = () => {
  localStorage.removeItem(REDIRECT_TO_SIGNUP_AWS);
};
