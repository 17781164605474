import { Job, JobStateEnum, JobType } from "../../admin/apiV2Client";
import { CorpusJobs } from "./buildJobsMap";

// Jobs that are active have the highest priority, followed by
// those that the user can act upon. The ones that the user
// can't act upon are last.
const prioritizedJobStates = [
  JobStateEnum.started, // Active
  JobStateEnum.failed_will_retry, // Active
  JobStateEnum.aborted, // Active, because the system aborted it and might retry it
  JobStateEnum.failed, // Actionable by retrying
  JobStateEnum.queued, // Can't do anything
  JobStateEnum.unknown, // Can't do anything
  JobStateEnum.completed // Doesn't matter any more
];

export const sortJobsByPriority = (corpusJobs: Partial<CorpusJobs>) => {
  let flattenedJobs: Job[] = [];

  // https://stackoverflow.com/questions/52856496/typescript-object-keys-return-string
  (Object.keys(corpusJobs) as unknown as Array<JobType>).forEach((jobType) => {
    if (jobType) {
      const jobs = corpusJobs[jobType];
      if (jobs) {
        flattenedJobs = flattenedJobs.concat(jobs);
      }
    }
  });

  return flattenedJobs.sort((a, b) => {
    if (!a.state || !b.state) return 0;

    const aPriority = prioritizedJobStates.indexOf(a.state);
    const bPriority = prioritizedJobStates.indexOf(b.state);

    if (aPriority < bPriority) {
      return -1;
    }

    if (aPriority > bPriority) {
      return 1;
    }

    return 0;
  });
};
