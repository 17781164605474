import { SettingsFlow, UpdateSettingsFlowBody } from "@ory/client";
import { NodeMessages, UserSettingsCard } from "@ory/elements";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { orySdk } from "../../../utils/auth/orySdk";
import { logout } from "../../../contexts/user/commonAuth";
import { VuiCard, VuiFlexContainer, VuiFlexItem, VuiLink, VuiSpacer, VuiSpinner, VuiTitle } from "@vectara/vectara-ui";
import logo from "../../../images/logo-vectara-dark.png";

// Ory Elements
// optional fontawesome icons
import "@ory/elements/assets/fa-brands.min.css";
import "@ory/elements/assets/fa-solid.min.css";
import "@ory/elements/assets/fontawesome.min.css";
// optional fonts
import "@ory/elements/assets/inter-font.css";
import "@ory/elements/assets/jetbrains-mono-font.css";
// required styles for Ory Elements
import "@ory/elements/style.css";
import { useNotificationsContext } from "../../../contexts/NotificationsContext";
import { useUserContext } from "../../../contexts/UserContext";
import { insertCustomerId, insertUserName } from "../../../utils/queryParams";
import { getSession } from "../../../utils/localStorage/customer";
import { extractOryErrorMessage } from "./extractOryErrorMessage";
import { datadogRum } from "@datadog/browser-rum";

export default function SetNewOryPassword() {
  const { deauthenticate } = useUserContext();
  const [flow, setFlow] = useState<SettingsFlow | null>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const { addNotification } = useNotificationsContext();
  const navigate = useNavigate();

  const getFlow = useCallback(
    (flowId: string) =>
      orySdk
        .getSettingsFlow({ id: flowId })
        .then(({ data: flow }) => setFlow(flow))
        .catch((e) => console.log("failed to getFlow", e)),
    []
  );

  const createFlow = () => {
    orySdk
      .createBrowserSettingsFlow()
      .then(({ data: flow }) => {
        setSearchParams({ ["flow"]: flow.id });
        setFlow(flow);
      })
      .catch((e) => console.log("failed to createFlow", e));
  };

  const onSubmit = async (body: UpdateSettingsFlowBody) => {
    // something unexpected went wrong and the flow was not set
    if (!flow) return navigate("/setNewPassword", { replace: true });
    try {
      const response = await orySdk.updateSettingsFlow({ flow: flow.id, updateSettingsFlowBody: body });
      setFlow(response.data);
      await logout(true);
      navigate("/");
    } catch (e: any) {
      const errorMessage = extractOryErrorMessage(e);
      datadogRum.addError(e);
      const sessionInfo = getSession();
      const queryString = insertUserName(
        sessionInfo?.email,
        insertCustomerId(String(sessionInfo?.customerId ?? ""), searchParams)
      ).toString();
      // In case errorMessage has some value, we show notification with respective msg
      if (errorMessage) {
        console.log("error", e);
        addNotification(errorMessage, "danger");
      }
      // Status Code: 400 happens in case of weak password
      // In all other cases the user should be redirected to /requestResetPassword
      if (e.response.status !== 400) {
        console.log("failed to submit password", e);
        // Logging out in case of any other error
        await deauthenticate(queryString, "/requestResetPassword");
      }
    }
  };

  useEffect(() => {
    const flowId = searchParams.get("flow");
    if (flowId) {
      getFlow(flowId).catch(createFlow);
      return;
    }
    createFlow();
  }, []);

  return (
    <div>
      <VuiCard
        padding="l"
        className="loggedOutPageForm"
        header={
          <VuiFlexContainer
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing="s"
            className="loggedOutFormHeader"
          >
            <VuiFlexItem grow={false} shrink={false}>
              <VuiLink href="/">
                <img src={logo} alt="Vectara logo" className="signupFormLogo" />
              </VuiLink>
            </VuiFlexItem>

            <VuiFlexItem grow={1} shrink={1}>
              <VuiTitle size="s" align="center" data-testid="setNewPasswordTitle">
                <h1>Set new password</h1>
              </VuiTitle>
            </VuiFlexItem>
          </VuiFlexContainer>
        }
        body={
          <div style={{ maxWidth: "fit-content", margin: "auto" }}>
            {flow ? (
              <>
                <div style={{ textAlign: "center" }}>
                  <NodeMessages uiMessages={flow.ui.messages} />
                </div>

                <VuiSpacer size="l" />

                <UserSettingsCard
                  title=""
                  key={0}
                  flow={flow}
                  method="password"
                  includeScripts={false}
                  onSubmit={({ body }) => onSubmit(body as UpdateSettingsFlowBody)}
                />
              </>
            ) : (
              <VuiSpinner size="m" />
            )}
          </div>
        }
      />
    </div>
  );
}
