import { createContext, useContext, ReactNode, useRef, useMemo } from "react";
import { debounce } from "lodash";
import { CorporaState, CorporaStates, UploadConfig } from "../types/corporaState";
import { useNotificationsContext } from "./NotificationsContext";
import { UploadStatus, uploadFile } from "../admin/UploadApi";

type UploadContextType = {
  getCurrentFile: (corpusKey: string) => string | undefined;
  getFailedFiles: (corpusKey: string) => Array<UploadConfig & { msg: string; code: string }>;
  getNumTotal: (corpusKey: string) => number;
  getNumOk: (corpusKey: string) => number;
  getStatus: (corpusKey: string) => "notStarted" | "complete" | "inProgress";
  getPercentComplete: (corpusKey: string) => number;
  uploadBatch: ({
    corpusKey,
    corpusName,
    uploadUrl,
    getJwt,
    uploadQueue,
    numTotal
  }: {
    corpusKey: string;
    corpusName: string;
    uploadUrl: string;
    getJwt: () => Promise<string>;
    uploadQueue: UploadConfig[];
    numTotal: number;
  }) => Promise<void>;
};
const UploadContext = createContext<UploadContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
  corporaStates: CorporaStates;
  setCorporaStates: React.Dispatch<React.SetStateAction<CorporaStates>>;
};

function displayCorpusName(corpusName: string) {
  if (corpusName.length > 24) {
    return corpusName.slice(0, 24) + "...";
  }
  return corpusName;
}

const getUploadStatus = (uploadState?: CorporaState["uploadFile"]) => {
  if (!uploadState) return "notStarted";

  if (uploadState.numTotal === 0) {
    return "notStarted";
  }

  if (uploadState.numOk + uploadState.failedFiles.length === uploadState.numTotal) {
    return "complete";
  }

  return "inProgress";
};

export const UploadContextProvider = ({ children, corporaStates, setCorporaStates }: Props) => {
  const { addNotification } = useNotificationsContext();
  const corporaStatesRef = useRef(corporaStates);
  corporaStatesRef.current = corporaStates;

  const getUploadingState = (corpusKey: string) => {
    return corporaStates[corpusKey]?.uploadFile;
  };

  const getCurrentFile = (corpusKey: string) => {
    const state = getUploadingState(corpusKey);
    return state?.currentFile;
  };

  const getCodes = (corpusKey: string) => {
    const state = getUploadingState(corpusKey);
    return state?.codes;
  };

  const getFailedFiles = (corpusKey: string) => {
    const state = getUploadingState(corpusKey);
    return state?.failedFiles ?? [];
  };

  const getNumTotal = (corpusKey: string) => {
    const state = getUploadingState(corpusKey);
    return state?.numTotal ?? 0;
  };

  const getNumOk = (corpusKey: string) => {
    const state = getUploadingState(corpusKey);
    return state?.numOk ?? 0;
  };

  const getStatus = (corpusKey: string) => {
    const state = getUploadingState(corpusKey);
    return getUploadStatus(state);
  };

  const getPercentComplete = (corpusKey: string) => {
    const state = getUploadingState(corpusKey);
    if (!state) return 0;
    return (state.numOk + state.failedFiles.length) / state.numTotal;
  };

  const onBatchUploadStart = (corpusKey: string, numTotal: number) => {
    setCorporaStates((prev) => {
      const prevCorpusState = prev[corpusKey] ?? {};
      const prevUploadingState = prevCorpusState.uploadFile;

      if (!prevUploadingState || getUploadStatus(prevUploadingState) !== "inProgress") {
        // If the upload state doesn't exist, initialize it.
        // And if it's not in progress, overwrite it.
        return {
          ...prev,
          [corpusKey]: {
            ...prevCorpusState,
            uploadFile: {
              numFailed: 0,
              numOk: 0,
              numTotal,
              failedFiles: [],
              codes: [],
              currentFile: ""
            }
          }
        };
      } else {
        // If the upload state is already in progress, update it with the new batch.
        return {
          ...prev,
          [corpusKey]: {
            ...prevCorpusState,
            uploadFile: {
              ...(prevUploadingState ?? {}),
              numTotal: prevUploadingState.numTotal + numTotal
            }
          }
        };
      }
    });
  };

  const onBatchUploadStartFile = (corpusKey: string, currentFile: string) => {
    setCorporaStates((prev) => {
      const prevCorpusState = prev[corpusKey] ?? {};
      const prevUploadingState = prevCorpusState.uploadFile ?? {};
      return {
        ...prev,
        [corpusKey]: {
          ...prevCorpusState,
          uploadFile: {
            ...prevUploadingState,
            currentFile
          }
        }
      };
    });
  };

  const onBatchUploadUpdate = (
    corpusKey: string,
    corpusName: string,
    uploadConfig: UploadConfig,
    uploadStatus: UploadStatus
  ) => {
    setCorporaStates((prev) => {
      const prevState = prev[corpusKey];
      const nextUploadingState = { ...prevState.uploadFile };

      if (uploadStatus.status === "success") {
        nextUploadingState.numOk = prevState.uploadFile.numOk + 1;
      } else if (uploadStatus.status === "error") {
        const { error, code } = uploadStatus;
        nextUploadingState.failedFiles = prevState.uploadFile.failedFiles.concat({
          ...error,
          ...uploadConfig
        });
        nextUploadingState.codes = prevState.uploadFile.codes.concat(code);
      }

      // This is kind of hacky.
      // * We do this in a callback instead of inside of a useEffect because
      //   an imperative approach requires minimal state and render cycle
      //   finessing.
      // * We do this in a callback instead of at the end of uploadBatch because
      //   uploadBatch only knows when the last file has *started* uploading,
      //   but not when it's completed.
      // * We do this inside of setCorporaStates, because otherwise
      //   we'd be referring to stale corporaStates values.
      onBatchUploadComplete(
        corpusKey,
        corpusName,
        nextUploadingState.numOk,
        nextUploadingState.failedFiles.length,
        nextUploadingState.numTotal
      );

      return {
        ...prev,
        [corpusKey]: {
          ...prevState,
          uploadFile: nextUploadingState
        }
      };
    });
  };

  // This debounce is a hack to prevent multiple notifications from appearing
  // if a couple parallel uploads complete at the same time. This can be tested locally
  // by uploading two documents at the same time.
  // https://dmitripavlutin.com/react-throttle-debounce/
  const onBatchUploadComplete = useMemo(
    () =>
      debounce((corpusKey: string, corpusName: string, numOk: number, numFailed: number, numTotal: number) => {
        if (numFailed + numOk < numTotal) return;

        const codes = getCodes(corpusKey);
        const codesMessage = codes && codes.length > 0 ? ` with status codes: [${[...new Set(codes)]}]` : "";

        // Partial success.
        if (numFailed > 0 && numFailed < numTotal) {
          addNotification(
            `${numTotal - numFailed}/${numTotal} files uploaded for corpus ${corpusKey}: ${displayCorpusName(
              corpusName
            )}. ${numFailed} files failed${codesMessage}`,
            "warning"
          );
          return;
        }

        // Complete failure.
        if (numFailed === numTotal) {
          addNotification(
            `${numTotal - numFailed}/${numTotal} files uploaded for corpus ${corpusKey}: ${displayCorpusName(
              corpusName
            )}. ${numFailed} files failed${codesMessage}`,
            "danger"
          );
          return;
        }

        // Complete success.
        if (numFailed === 0) {
          addNotification(
            `${numTotal}/${numTotal} files uploaded for corpus ${corpusKey}: ${displayCorpusName(corpusName)}.`,
            "success"
          );
        }
      }, 300),
    []
  );

  const uploadBatch = async ({
    corpusKey,
    corpusName,
    uploadUrl,
    getJwt,
    uploadQueue,
    numTotal
  }: {
    uploadUrl: string;
    corpusName: string;
    corpusKey: string;
    getJwt: () => Promise<string>;
    uploadQueue: UploadConfig[];
    numTotal: number;
  }) => {
    // This number depends on how many encoders are available on the back-end.
    const MAX_PARALLEL_UPLOADS = 8;
    let numParallelUploads = 0;

    const startUpload = async () => {
      while (uploadQueue.length > 0 && numParallelUploads < MAX_PARALLEL_UPLOADS) {
        const nextUpload = uploadQueue.pop();
        if (nextUpload) {
          numParallelUploads++;
          onBatchUploadStartFile(corpusKey, nextUpload.fullPath);

          // Couple generation of the JWT with generation of the request,
          // so that the JWT is as fresh as possible. Otherwise we risk
          // the JWT expiring before the request is sent.
          const jwt = await getJwt();

          const upload = uploadFile(
            nextUpload.upload,
            uploadUrl,
            jwt,
            onBatchUploadUpdate.bind(null, corpusKey, corpusName, nextUpload)
          );

          upload.then(() => {
            numParallelUploads--;
            startUpload();
          });
        }
      }
    };

    onBatchUploadStart(corpusKey, numTotal);
    startUpload();
  };

  return (
    <UploadContext.Provider
      value={{
        getCurrentFile,
        getFailedFiles,
        getNumTotal,
        getNumOk,
        getStatus,
        getPercentComplete,
        uploadBatch
      }}
    >
      {children}
    </UploadContext.Provider>
  );
};

export const useUploadContext = () => {
  const context = useContext(UploadContext);
  if (context === undefined) {
    throw new Error("useUploadContext must be used within a UploadContextProvider");
  }
  return context;
};
