// source: admin/admin_metric.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var status_pb = require('../status_pb.js');
goog.object.extend(proto, status_pb);
goog.exportSymbol('proto.com.vectara.admin.AbsoluteWindow', null, global);
goog.exportSymbol('proto.com.vectara.admin.IndexingMetric', null, global);
goog.exportSymbol('proto.com.vectara.admin.ServingMetric', null, global);
goog.exportSymbol('proto.com.vectara.admin.UsageMetricsRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.UsageMetricsRequest.MetricType', null, global);
goog.exportSymbol('proto.com.vectara.admin.UsageMetricsResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.UsageMetricsResponse.IntervalValue', null, global);
goog.exportSymbol('proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.ValueCase', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.AbsoluteWindow = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.AbsoluteWindow, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.AbsoluteWindow.displayName = 'proto.com.vectara.admin.AbsoluteWindow';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.UsageMetricsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.UsageMetricsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.UsageMetricsRequest.displayName = 'proto.com.vectara.admin.UsageMetricsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.IndexingMetric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.IndexingMetric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.IndexingMetric.displayName = 'proto.com.vectara.admin.IndexingMetric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ServingMetric = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ServingMetric, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ServingMetric.displayName = 'proto.com.vectara.admin.ServingMetric';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.UsageMetricsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.UsageMetricsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.UsageMetricsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.UsageMetricsResponse.displayName = 'proto.com.vectara.admin.UsageMetricsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.oneofGroups_);
};
goog.inherits(proto.com.vectara.admin.UsageMetricsResponse.IntervalValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.displayName = 'proto.com.vectara.admin.UsageMetricsResponse.IntervalValue';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.AbsoluteWindow.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.AbsoluteWindow.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.AbsoluteWindow} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.AbsoluteWindow.toObject = function(includeInstance, msg) {
  var f, obj = {
    startEpochSecs: jspb.Message.getFieldWithDefault(msg, 1, 0),
    endEpochSecs: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.AbsoluteWindow}
 */
proto.com.vectara.admin.AbsoluteWindow.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.AbsoluteWindow;
  return proto.com.vectara.admin.AbsoluteWindow.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.AbsoluteWindow} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.AbsoluteWindow}
 */
proto.com.vectara.admin.AbsoluteWindow.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartEpochSecs(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEndEpochSecs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.AbsoluteWindow.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.AbsoluteWindow.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.AbsoluteWindow} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.AbsoluteWindow.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStartEpochSecs();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getEndEpochSecs();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
};


/**
 * optional int64 start_epoch_secs = 1;
 * @return {number}
 */
proto.com.vectara.admin.AbsoluteWindow.prototype.getStartEpochSecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.AbsoluteWindow} returns this
 */
proto.com.vectara.admin.AbsoluteWindow.prototype.setStartEpochSecs = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 end_epoch_secs = 2;
 * @return {number}
 */
proto.com.vectara.admin.AbsoluteWindow.prototype.getEndEpochSecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.AbsoluteWindow} returns this
 */
proto.com.vectara.admin.AbsoluteWindow.prototype.setEndEpochSecs = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.UsageMetricsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.UsageMetricsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.UsageMetricsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UsageMetricsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    window: (f = msg.getWindow()) && proto.com.vectara.admin.AbsoluteWindow.toObject(includeInstance, f),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    aggregationIntervalSecs: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.UsageMetricsRequest}
 */
proto.com.vectara.admin.UsageMetricsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.UsageMetricsRequest;
  return proto.com.vectara.admin.UsageMetricsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.UsageMetricsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.UsageMetricsRequest}
 */
proto.com.vectara.admin.UsageMetricsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 2:
      var value = new proto.com.vectara.admin.AbsoluteWindow;
      reader.readMessage(value,proto.com.vectara.admin.AbsoluteWindow.deserializeBinaryFromReader);
      msg.setWindow(value);
      break;
    case 3:
      var value = /** @type {!proto.com.vectara.admin.UsageMetricsRequest.MetricType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAggregationIntervalSecs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.UsageMetricsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.UsageMetricsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.UsageMetricsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UsageMetricsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getWindow();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.vectara.admin.AbsoluteWindow.serializeBinaryToWriter
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getAggregationIntervalSecs();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.com.vectara.admin.UsageMetricsRequest.MetricType = {
  METRICTYPE__NONE: 0,
  METRICTYPE__INDEXING: 1,
  METRICTYPE__SERVING: 2
};

/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.UsageMetricsRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.UsageMetricsRequest} returns this
 */
proto.com.vectara.admin.UsageMetricsRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional AbsoluteWindow window = 2;
 * @return {?proto.com.vectara.admin.AbsoluteWindow}
 */
proto.com.vectara.admin.UsageMetricsRequest.prototype.getWindow = function() {
  return /** @type{?proto.com.vectara.admin.AbsoluteWindow} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.AbsoluteWindow, 2));
};


/**
 * @param {?proto.com.vectara.admin.AbsoluteWindow|undefined} value
 * @return {!proto.com.vectara.admin.UsageMetricsRequest} returns this
*/
proto.com.vectara.admin.UsageMetricsRequest.prototype.setWindow = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.UsageMetricsRequest} returns this
 */
proto.com.vectara.admin.UsageMetricsRequest.prototype.clearWindow = function() {
  return this.setWindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.UsageMetricsRequest.prototype.hasWindow = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MetricType type = 3;
 * @return {!proto.com.vectara.admin.UsageMetricsRequest.MetricType}
 */
proto.com.vectara.admin.UsageMetricsRequest.prototype.getType = function() {
  return /** @type {!proto.com.vectara.admin.UsageMetricsRequest.MetricType} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.vectara.admin.UsageMetricsRequest.MetricType} value
 * @return {!proto.com.vectara.admin.UsageMetricsRequest} returns this
 */
proto.com.vectara.admin.UsageMetricsRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional int64 aggregation_interval_secs = 4;
 * @return {number}
 */
proto.com.vectara.admin.UsageMetricsRequest.prototype.getAggregationIntervalSecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.UsageMetricsRequest} returns this
 */
proto.com.vectara.admin.UsageMetricsRequest.prototype.setAggregationIntervalSecs = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.IndexingMetric.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.IndexingMetric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.IndexingMetric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.IndexingMetric.toObject = function(includeInstance, msg) {
  var f, obj = {
    docCount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    docPartCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    docPartBytes: jspb.Message.getFieldWithDefault(msg, 3, 0),
    startEpochSecs: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.IndexingMetric}
 */
proto.com.vectara.admin.IndexingMetric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.IndexingMetric;
  return proto.com.vectara.admin.IndexingMetric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.IndexingMetric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.IndexingMetric}
 */
proto.com.vectara.admin.IndexingMetric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDocCount(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDocPartCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setDocPartBytes(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartEpochSecs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.IndexingMetric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.IndexingMetric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.IndexingMetric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.IndexingMetric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocCount();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getDocPartCount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getDocPartBytes();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getStartEpochSecs();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional uint64 doc_count = 1;
 * @return {number}
 */
proto.com.vectara.admin.IndexingMetric.prototype.getDocCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.IndexingMetric} returns this
 */
proto.com.vectara.admin.IndexingMetric.prototype.setDocCount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 doc_part_count = 2;
 * @return {number}
 */
proto.com.vectara.admin.IndexingMetric.prototype.getDocPartCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.IndexingMetric} returns this
 */
proto.com.vectara.admin.IndexingMetric.prototype.setDocPartCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 doc_part_bytes = 3;
 * @return {number}
 */
proto.com.vectara.admin.IndexingMetric.prototype.getDocPartBytes = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.IndexingMetric} returns this
 */
proto.com.vectara.admin.IndexingMetric.prototype.setDocPartBytes = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 start_epoch_secs = 4;
 * @return {number}
 */
proto.com.vectara.admin.IndexingMetric.prototype.getStartEpochSecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.IndexingMetric} returns this
 */
proto.com.vectara.admin.IndexingMetric.prototype.setStartEpochSecs = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ServingMetric.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ServingMetric.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ServingMetric} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ServingMetric.toObject = function(includeInstance, msg) {
  var f, obj = {
    rowsRead: jspb.Message.getFieldWithDefault(msg, 1, 0),
    queryCount: jspb.Message.getFieldWithDefault(msg, 2, 0),
    startEpochSecs: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ServingMetric}
 */
proto.com.vectara.admin.ServingMetric.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ServingMetric;
  return proto.com.vectara.admin.ServingMetric.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ServingMetric} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ServingMetric}
 */
proto.com.vectara.admin.ServingMetric.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRowsRead(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setQueryCount(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setStartEpochSecs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ServingMetric.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ServingMetric.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ServingMetric} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ServingMetric.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRowsRead();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getQueryCount();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getStartEpochSecs();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
};


/**
 * optional uint64 rows_read = 1;
 * @return {number}
 */
proto.com.vectara.admin.ServingMetric.prototype.getRowsRead = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ServingMetric} returns this
 */
proto.com.vectara.admin.ServingMetric.prototype.setRowsRead = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 query_count = 2;
 * @return {number}
 */
proto.com.vectara.admin.ServingMetric.prototype.getQueryCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ServingMetric} returns this
 */
proto.com.vectara.admin.ServingMetric.prototype.setQueryCount = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 start_epoch_secs = 3;
 * @return {number}
 */
proto.com.vectara.admin.ServingMetric.prototype.getStartEpochSecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ServingMetric} returns this
 */
proto.com.vectara.admin.ServingMetric.prototype.setStartEpochSecs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.UsageMetricsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.UsageMetricsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.UsageMetricsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.UsageMetricsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UsageMetricsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.toObject, includeInstance),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.UsageMetricsResponse}
 */
proto.com.vectara.admin.UsageMetricsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.UsageMetricsResponse;
  return proto.com.vectara.admin.UsageMetricsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.UsageMetricsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.UsageMetricsResponse}
 */
proto.com.vectara.admin.UsageMetricsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.UsageMetricsResponse.IntervalValue;
      reader.readMessage(value,proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    case 2:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.UsageMetricsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.UsageMetricsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.UsageMetricsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UsageMetricsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.ValueCase = {
  VALUE_NOT_SET: 0,
  INDEXING_VALUE: 1,
  SERVING_VALUE: 2
};

/**
 * @return {proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.ValueCase}
 */
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.prototype.getValueCase = function() {
  return /** @type {proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.ValueCase} */(jspb.Message.computeOneofCase(this, proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.UsageMetricsResponse.IntervalValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    indexingValue: (f = msg.getIndexingValue()) && proto.com.vectara.admin.IndexingMetric.toObject(includeInstance, f),
    servingValue: (f = msg.getServingValue()) && proto.com.vectara.admin.ServingMetric.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.UsageMetricsResponse.IntervalValue}
 */
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.UsageMetricsResponse.IntervalValue;
  return proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.UsageMetricsResponse.IntervalValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.UsageMetricsResponse.IntervalValue}
 */
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.IndexingMetric;
      reader.readMessage(value,proto.com.vectara.admin.IndexingMetric.deserializeBinaryFromReader);
      msg.setIndexingValue(value);
      break;
    case 2:
      var value = new proto.com.vectara.admin.ServingMetric;
      reader.readMessage(value,proto.com.vectara.admin.ServingMetric.deserializeBinaryFromReader);
      msg.setServingValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.UsageMetricsResponse.IntervalValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIndexingValue();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.vectara.admin.IndexingMetric.serializeBinaryToWriter
    );
  }
  f = message.getServingValue();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.vectara.admin.ServingMetric.serializeBinaryToWriter
    );
  }
};


/**
 * optional IndexingMetric indexing_value = 1;
 * @return {?proto.com.vectara.admin.IndexingMetric}
 */
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.prototype.getIndexingValue = function() {
  return /** @type{?proto.com.vectara.admin.IndexingMetric} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.IndexingMetric, 1));
};


/**
 * @param {?proto.com.vectara.admin.IndexingMetric|undefined} value
 * @return {!proto.com.vectara.admin.UsageMetricsResponse.IntervalValue} returns this
*/
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.prototype.setIndexingValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.UsageMetricsResponse.IntervalValue} returns this
 */
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.prototype.clearIndexingValue = function() {
  return this.setIndexingValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.prototype.hasIndexingValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional ServingMetric serving_value = 2;
 * @return {?proto.com.vectara.admin.ServingMetric}
 */
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.prototype.getServingValue = function() {
  return /** @type{?proto.com.vectara.admin.ServingMetric} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.ServingMetric, 2));
};


/**
 * @param {?proto.com.vectara.admin.ServingMetric|undefined} value
 * @return {!proto.com.vectara.admin.UsageMetricsResponse.IntervalValue} returns this
*/
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.prototype.setServingValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.UsageMetricsResponse.IntervalValue} returns this
 */
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.prototype.clearServingValue = function() {
  return this.setServingValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.UsageMetricsResponse.IntervalValue.prototype.hasServingValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated IntervalValue values = 1;
 * @return {!Array<!proto.com.vectara.admin.UsageMetricsResponse.IntervalValue>}
 */
proto.com.vectara.admin.UsageMetricsResponse.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.UsageMetricsResponse.IntervalValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.UsageMetricsResponse.IntervalValue, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.UsageMetricsResponse.IntervalValue>} value
 * @return {!proto.com.vectara.admin.UsageMetricsResponse} returns this
*/
proto.com.vectara.admin.UsageMetricsResponse.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.UsageMetricsResponse.IntervalValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.UsageMetricsResponse.IntervalValue}
 */
proto.com.vectara.admin.UsageMetricsResponse.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.UsageMetricsResponse.IntervalValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.UsageMetricsResponse} returns this
 */
proto.com.vectara.admin.UsageMetricsResponse.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};


/**
 * optional com.vectara.Status status = 2;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.UsageMetricsResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 2));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.UsageMetricsResponse} returns this
*/
proto.com.vectara.admin.UsageMetricsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.UsageMetricsResponse} returns this
 */
proto.com.vectara.admin.UsageMetricsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.UsageMetricsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.com.vectara.admin);
