// source: admin/admin_stats.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var status_pb = require('../status_pb.js');
goog.object.extend(proto, status_pb);
goog.exportSymbol('proto.com.vectara.admin.GenerativeStats', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadUsageStatsRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadUsageStatsResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.UsageStats', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.UsageStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.UsageStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.UsageStats.displayName = 'proto.com.vectara.admin.UsageStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.GenerativeStats = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.GenerativeStats, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.GenerativeStats.displayName = 'proto.com.vectara.admin.GenerativeStats';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadUsageStatsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ReadUsageStatsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadUsageStatsRequest.displayName = 'proto.com.vectara.admin.ReadUsageStatsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadUsageStatsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.ReadUsageStatsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ReadUsageStatsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadUsageStatsResponse.displayName = 'proto.com.vectara.admin.ReadUsageStatsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.UsageStats.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.UsageStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.UsageStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UsageStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    tsStart: jspb.Message.getFieldWithDefault(msg, 1, 0),
    tsEnd: jspb.Message.getFieldWithDefault(msg, 2, 0),
    actualCount: jspb.Message.getFieldWithDefault(msg, 3, 0),
    allowedCount: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.UsageStats}
 */
proto.com.vectara.admin.UsageStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.UsageStats;
  return proto.com.vectara.admin.UsageStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.UsageStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.UsageStats}
 */
proto.com.vectara.admin.UsageStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTsStart(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTsEnd(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setActualCount(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAllowedCount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.UsageStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.UsageStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.UsageStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.UsageStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTsStart();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getTsEnd();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getActualCount();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getAllowedCount();
  if (f !== 0) {
    writer.writeInt64(
      4,
      f
    );
  }
};


/**
 * optional int64 ts_start = 1;
 * @return {number}
 */
proto.com.vectara.admin.UsageStats.prototype.getTsStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.UsageStats} returns this
 */
proto.com.vectara.admin.UsageStats.prototype.setTsStart = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int64 ts_end = 2;
 * @return {number}
 */
proto.com.vectara.admin.UsageStats.prototype.getTsEnd = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.UsageStats} returns this
 */
proto.com.vectara.admin.UsageStats.prototype.setTsEnd = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional int64 actual_count = 3;
 * @return {number}
 */
proto.com.vectara.admin.UsageStats.prototype.getActualCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.UsageStats} returns this
 */
proto.com.vectara.admin.UsageStats.prototype.setActualCount = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional int64 allowed_count = 4;
 * @return {number}
 */
proto.com.vectara.admin.UsageStats.prototype.getAllowedCount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.UsageStats} returns this
 */
proto.com.vectara.admin.UsageStats.prototype.setAllowedCount = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.GenerativeStats.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.GenerativeStats.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.GenerativeStats} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.GenerativeStats.toObject = function(includeInstance, msg) {
  var f, obj = {
    modelName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    usageStats: (f = msg.getUsageStats()) && proto.com.vectara.admin.UsageStats.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.GenerativeStats}
 */
proto.com.vectara.admin.GenerativeStats.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.GenerativeStats;
  return proto.com.vectara.admin.GenerativeStats.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.GenerativeStats} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.GenerativeStats}
 */
proto.com.vectara.admin.GenerativeStats.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModelName(value);
      break;
    case 10:
      var value = new proto.com.vectara.admin.UsageStats;
      reader.readMessage(value,proto.com.vectara.admin.UsageStats.deserializeBinaryFromReader);
      msg.setUsageStats(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.GenerativeStats.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.GenerativeStats.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.GenerativeStats} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.GenerativeStats.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getModelName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getUsageStats();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.com.vectara.admin.UsageStats.serializeBinaryToWriter
    );
  }
};


/**
 * optional string model_name = 5;
 * @return {string}
 */
proto.com.vectara.admin.GenerativeStats.prototype.getModelName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.GenerativeStats} returns this
 */
proto.com.vectara.admin.GenerativeStats.prototype.setModelName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional UsageStats usage_stats = 10;
 * @return {?proto.com.vectara.admin.UsageStats}
 */
proto.com.vectara.admin.GenerativeStats.prototype.getUsageStats = function() {
  return /** @type{?proto.com.vectara.admin.UsageStats} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.UsageStats, 10));
};


/**
 * @param {?proto.com.vectara.admin.UsageStats|undefined} value
 * @return {!proto.com.vectara.admin.GenerativeStats} returns this
*/
proto.com.vectara.admin.GenerativeStats.prototype.setUsageStats = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.GenerativeStats} returns this
 */
proto.com.vectara.admin.GenerativeStats.prototype.clearUsageStats = function() {
  return this.setUsageStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.GenerativeStats.prototype.hasUsageStats = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadUsageStatsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadUsageStatsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadUsageStatsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadUsageStatsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadUsageStatsRequest}
 */
proto.com.vectara.admin.ReadUsageStatsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadUsageStatsRequest;
  return proto.com.vectara.admin.ReadUsageStatsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadUsageStatsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadUsageStatsRequest}
 */
proto.com.vectara.admin.ReadUsageStatsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadUsageStatsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadUsageStatsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadUsageStatsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadUsageStatsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ReadUsageStatsResponse.repeatedFields_ = [1,2,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadUsageStatsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadUsageStatsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadUsageStatsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    queryStatsList: jspb.Message.toObjectList(msg.getQueryStatsList(),
    proto.com.vectara.admin.UsageStats.toObject, includeInstance),
    indexStatsList: jspb.Message.toObjectList(msg.getIndexStatsList(),
    proto.com.vectara.admin.UsageStats.toObject, includeInstance),
    corporaStats: (f = msg.getCorporaStats()) && proto.com.vectara.admin.UsageStats.toObject(includeInstance, f),
    usersStats: (f = msg.getUsersStats()) && proto.com.vectara.admin.UsageStats.toObject(includeInstance, f),
    storageStats: (f = msg.getStorageStats()) && proto.com.vectara.admin.UsageStats.toObject(includeInstance, f),
    generativeStats: (f = msg.getGenerativeStats()) && proto.com.vectara.admin.UsageStats.toObject(includeInstance, f),
    generativeModelStatsList: jspb.Message.toObjectList(msg.getGenerativeModelStatsList(),
    proto.com.vectara.admin.GenerativeStats.toObject, includeInstance),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadUsageStatsResponse;
  return proto.com.vectara.admin.ReadUsageStatsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadUsageStatsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.UsageStats;
      reader.readMessage(value,proto.com.vectara.admin.UsageStats.deserializeBinaryFromReader);
      msg.addQueryStats(value);
      break;
    case 2:
      var value = new proto.com.vectara.admin.UsageStats;
      reader.readMessage(value,proto.com.vectara.admin.UsageStats.deserializeBinaryFromReader);
      msg.addIndexStats(value);
      break;
    case 3:
      var value = new proto.com.vectara.admin.UsageStats;
      reader.readMessage(value,proto.com.vectara.admin.UsageStats.deserializeBinaryFromReader);
      msg.setCorporaStats(value);
      break;
    case 4:
      var value = new proto.com.vectara.admin.UsageStats;
      reader.readMessage(value,proto.com.vectara.admin.UsageStats.deserializeBinaryFromReader);
      msg.setUsersStats(value);
      break;
    case 5:
      var value = new proto.com.vectara.admin.UsageStats;
      reader.readMessage(value,proto.com.vectara.admin.UsageStats.deserializeBinaryFromReader);
      msg.setStorageStats(value);
      break;
    case 6:
      var value = new proto.com.vectara.admin.UsageStats;
      reader.readMessage(value,proto.com.vectara.admin.UsageStats.deserializeBinaryFromReader);
      msg.setGenerativeStats(value);
      break;
    case 7:
      var value = new proto.com.vectara.admin.GenerativeStats;
      reader.readMessage(value,proto.com.vectara.admin.GenerativeStats.deserializeBinaryFromReader);
      msg.addGenerativeModelStats(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadUsageStatsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadUsageStatsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadUsageStatsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getQueryStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.UsageStats.serializeBinaryToWriter
    );
  }
  f = message.getIndexStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.vectara.admin.UsageStats.serializeBinaryToWriter
    );
  }
  f = message.getCorporaStats();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.vectara.admin.UsageStats.serializeBinaryToWriter
    );
  }
  f = message.getUsersStats();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.com.vectara.admin.UsageStats.serializeBinaryToWriter
    );
  }
  f = message.getStorageStats();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.vectara.admin.UsageStats.serializeBinaryToWriter
    );
  }
  f = message.getGenerativeStats();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.com.vectara.admin.UsageStats.serializeBinaryToWriter
    );
  }
  f = message.getGenerativeModelStatsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.com.vectara.admin.GenerativeStats.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * repeated UsageStats query_stats = 1;
 * @return {!Array<!proto.com.vectara.admin.UsageStats>}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.getQueryStatsList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.UsageStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.UsageStats, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.UsageStats>} value
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse} returns this
*/
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.setQueryStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.UsageStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.UsageStats}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.addQueryStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.UsageStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse} returns this
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.clearQueryStatsList = function() {
  return this.setQueryStatsList([]);
};


/**
 * repeated UsageStats index_stats = 2;
 * @return {!Array<!proto.com.vectara.admin.UsageStats>}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.getIndexStatsList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.UsageStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.UsageStats, 2));
};


/**
 * @param {!Array<!proto.com.vectara.admin.UsageStats>} value
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse} returns this
*/
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.setIndexStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.vectara.admin.UsageStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.UsageStats}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.addIndexStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.vectara.admin.UsageStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse} returns this
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.clearIndexStatsList = function() {
  return this.setIndexStatsList([]);
};


/**
 * optional UsageStats corpora_stats = 3;
 * @return {?proto.com.vectara.admin.UsageStats}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.getCorporaStats = function() {
  return /** @type{?proto.com.vectara.admin.UsageStats} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.UsageStats, 3));
};


/**
 * @param {?proto.com.vectara.admin.UsageStats|undefined} value
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse} returns this
*/
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.setCorporaStats = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse} returns this
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.clearCorporaStats = function() {
  return this.setCorporaStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.hasCorporaStats = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional UsageStats users_stats = 4;
 * @return {?proto.com.vectara.admin.UsageStats}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.getUsersStats = function() {
  return /** @type{?proto.com.vectara.admin.UsageStats} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.UsageStats, 4));
};


/**
 * @param {?proto.com.vectara.admin.UsageStats|undefined} value
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse} returns this
*/
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.setUsersStats = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse} returns this
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.clearUsersStats = function() {
  return this.setUsersStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.hasUsersStats = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional UsageStats storage_stats = 5;
 * @return {?proto.com.vectara.admin.UsageStats}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.getStorageStats = function() {
  return /** @type{?proto.com.vectara.admin.UsageStats} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.UsageStats, 5));
};


/**
 * @param {?proto.com.vectara.admin.UsageStats|undefined} value
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse} returns this
*/
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.setStorageStats = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse} returns this
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.clearStorageStats = function() {
  return this.setStorageStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.hasStorageStats = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional UsageStats generative_stats = 6;
 * @return {?proto.com.vectara.admin.UsageStats}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.getGenerativeStats = function() {
  return /** @type{?proto.com.vectara.admin.UsageStats} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.UsageStats, 6));
};


/**
 * @param {?proto.com.vectara.admin.UsageStats|undefined} value
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse} returns this
*/
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.setGenerativeStats = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse} returns this
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.clearGenerativeStats = function() {
  return this.setGenerativeStats(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.hasGenerativeStats = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated GenerativeStats generative_model_stats = 7;
 * @return {!Array<!proto.com.vectara.admin.GenerativeStats>}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.getGenerativeModelStatsList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.GenerativeStats>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.GenerativeStats, 7));
};


/**
 * @param {!Array<!proto.com.vectara.admin.GenerativeStats>} value
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse} returns this
*/
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.setGenerativeModelStatsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.com.vectara.admin.GenerativeStats=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.GenerativeStats}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.addGenerativeModelStats = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.com.vectara.admin.GenerativeStats, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse} returns this
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.clearGenerativeModelStatsList = function() {
  return this.setGenerativeModelStatsList([]);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse} returns this
*/
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadUsageStatsResponse} returns this
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadUsageStatsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};


goog.object.extend(exports, proto.com.vectara.admin);
