export const CURRENCIES = [
  { name: "AED", code: 784 },
  { name: "AFN", code: 971 },
  { name: "ALL", code: 8 },
  { name: "AMD", code: 51 },
  { name: "ANG", code: 532 },
  { name: "AOA", code: 973 },
  { name: "ARS", code: 32 },
  { name: "AUD", code: 36 },
  { name: "AWG", code: 533 },
  { name: "AZN", code: 944 },
  { name: "BAM", code: 977 },
  { name: "BBD", code: 52 },
  { name: "BDT", code: 50 },
  { name: "BGN", code: 975 },
  { name: "BHD", code: 48 },
  { name: "BIF", code: 108 },
  { name: "BMD", code: 60 },
  { name: "BND", code: 96 },
  { name: "BOB", code: 68 },
  { name: "BOV", code: 984 },
  { name: "BRL", code: 986 },
  { name: "BSD", code: 44 },
  { name: "BTN", code: 64 },
  { name: "BWP", code: 72 },
  { name: "BYN", code: 933 },
  { name: "BYR", code: 974 },
  { name: "BZD", code: 84 },
  { name: "CAD", code: 124 },
  { name: "CDF", code: 976 },
  { name: "CHE", code: 947 },
  { name: "CHF", code: 756 },
  { name: "CHW", code: 948 },
  { name: "CLF", code: 990 },
  { name: "CLP", code: 152 },
  { name: "CNY", code: 156 },
  { name: "COP", code: 170 },
  { name: "COU", code: 970 },
  { name: "CRC", code: 188 },
  { name: "CUC", code: 931 },
  { name: "CUP", code: 192 },
  { name: "CVE", code: 132 },
  { name: "CZK", code: 203 },
  { name: "DJF", code: 262 },
  { name: "DKK", code: 208 },
  { name: "DOP", code: 214 },
  { name: "DZD", code: 12 },
  { name: "EGP", code: 818 },
  { name: "ERN", code: 232 },
  { name: "ETB", code: 230 },
  { name: "EUR", code: 978 },
  { name: "FJD", code: 242 },
  { name: "FKP", code: 238 },
  { name: "GBP", code: 826 },
  { name: "GEL", code: 981 },
  { name: "GHS", code: 936 },
  { name: "GIP", code: 292 },
  { name: "GMD", code: 270 },
  { name: "GNF", code: 324 },
  { name: "GTQ", code: 320 },
  { name: "GYD", code: 328 },
  { name: "HKD", code: 344 },
  { name: "HNL", code: 340 },
  { name: "HRK", code: 191 },
  { name: "HTG", code: 332 },
  { name: "HUF", code: 348 },
  { name: "IDR", code: 360 },
  { name: "ILS", code: 376 },
  { name: "INR", code: 356 },
  { name: "IQD", code: 368 },
  { name: "IRR", code: 364 },
  { name: "ISK", code: 352 },
  { name: "JMD", code: 388 },
  { name: "JOD", code: 400 },
  { name: "JPY", code: 392 },
  { name: "KES", code: 404 },
  { name: "KGS", code: 417 },
  { name: "KHR", code: 116 },
  { name: "KMF", code: 174 },
  { name: "KPW", code: 408 },
  { name: "KRW", code: 410 },
  { name: "KWD", code: 414 },
  { name: "KYD", code: 136 },
  { name: "KZT", code: 398 },
  { name: "LAK", code: 418 },
  { name: "LBP", code: 422 },
  { name: "LKR", code: 144 },
  { name: "LRD", code: 430 },
  { name: "LSL", code: 426 },
  { name: "LYD", code: 434 },
  { name: "MAD", code: 504 },
  { name: "MDL", code: 498 },
  { name: "MGA", code: 969 },
  { name: "MKD", code: 807 },
  { name: "MMK", code: 104 },
  { name: "MNT", code: 496 },
  { name: "MOP", code: 446 },
  { name: "MRO", code: 478 },
  { name: "MUR", code: 480 },
  { name: "MVR", code: 462 },
  { name: "MWK", code: 454 },
  { name: "MXN", code: 484 },
  { name: "MXV", code: 979 },
  { name: "MYR", code: 458 },
  { name: "MZN", code: 943 },
  { name: "NAD", code: 516 },
  { name: "NGN", code: 566 },
  { name: "NIO", code: 558 },
  { name: "NOK", code: 578 },
  { name: "NPR", code: 524 },
  { name: "NZD", code: 554 },
  { name: "OMR", code: 512 },
  { name: "PAB", code: 590 },
  { name: "PEN", code: 604 },
  { name: "PGK", code: 598 },
  { name: "PHP", code: 608 },
  { name: "PKR", code: 586 },
  { name: "PLN", code: 985 },
  { name: "PYG", code: 600 },
  { name: "QAR", code: 634 },
  { name: "RON", code: 946 },
  { name: "RSD", code: 941 },
  { name: "RUB", code: 643 },
  { name: "RWF", code: 646 },
  { name: "SAR", code: 682 },
  { name: "SBD", code: 90 },
  { name: "SCR", code: 690 },
  { name: "SDG", code: 938 },
  { name: "SEK", code: 752 },
  { name: "SGD", code: 702 },
  { name: "SHP", code: 654 },
  { name: "SLL", code: 694 },
  { name: "SOS", code: 706 },
  { name: "SRD", code: 968 },
  { name: "SSP", code: 728 },
  { name: "STD", code: 678 },
  { name: "SVC", code: 222 },
  { name: "SYP", code: 760 },
  { name: "SZL", code: 748 },
  { name: "THB", code: 764 },
  { name: "TJS", code: 972 },
  { name: "TMT", code: 934 },
  { name: "TND", code: 788 },
  { name: "TOP", code: 776 },
  { name: "TRY", code: 949 },
  { name: "TTD", code: 780 },
  { name: "TWD", code: 901 },
  { name: "TZS", code: 834 },
  { name: "UAH", code: 980 },
  { name: "UGX", code: 800 },
  { name: "USD", code: 840 },
  { name: "USN", code: 997 },
  { name: "UYI", code: 940 },
  { name: "UYU", code: 858 },
  { name: "UZS", code: 860 },
  { name: "VEF", code: 937 },
  { name: "VND", code: 704 },
  { name: "VUV", code: 548 },
  { name: "WST", code: 882 },
  { name: "XAF", code: 950 },
  { name: "XAG", code: 961 },
  { name: "XAU", code: 959 },
  { name: "XBA", code: 955 },
  { name: "XBB", code: 956 },
  { name: "XBC", code: 957 },
  { name: "XBD", code: 958 },
  { name: "XCD", code: 951 },
  { name: "XDR", code: 960 },
  { name: "XOF", code: 952 },
  { name: "XPD", code: 964 },
  { name: "XPF", code: 953 },
  { name: "XPT", code: 962 },
  { name: "XSU", code: 994 },
  { name: "XTS", code: 963 },
  { name: "XUA", code: 965 },
  { name: "XXX", code: 999 },
  { name: "YER", code: 886 },
  { name: "ZAR", code: 710 },
  { name: "ZMW", code: 967 },
  { name: "ZWL", code: 932 }
];
