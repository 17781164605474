import { useState } from "react";
import { BiCodeAlt, BiRightArrowAlt } from "react-icons/bi";
import { ReactSearch } from "@vectara/react-search";
import { VuiButtonSecondary, VuiButtonTertiary, VuiIcon, VuiPopover, VuiSpacer } from "@vectara/vectara-ui";
import { docs } from "../../../../utils/links";
import { useConfigContext } from "../../../../contexts/ConfigContext";

export const ApiMenu = () => {
  const { getIsSearchSummaryEnabled, setIsSearchSummaryEnabled } = useConfigContext();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <VuiPopover
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      button={
        <VuiButtonTertiary
          data-testid="apiButton"
          color="primary"
          icon={
            <VuiIcon>
              <BiCodeAlt />
            </VuiIcon>
          }
        >
          API docs
        </VuiButtonTertiary>
      }
      anchorSide="right"
      padding
    >
      <div className="apiMenu">
        <ReactSearch
          // Ofer’s docs corpus
          customerId="1526022105"
          corpusId="232"
          apiKey="zqt_WvU_2ewh7ZGRwq8LdL2SV8B9RJmVGyUm1VAuOw"
          buttonLabel="Search docs"
          placeholder="Search the docs"
          openResultsInNewTab={true}
          onToggleSummary={(isEnabled: boolean) => setIsSearchSummaryEnabled(isEnabled)}
          isSummaryToggleVisible={true}
          isSummaryToggleInitiallyEnabled={getIsSearchSummaryEnabled()}
        />

        <VuiSpacer size="s" />

        <VuiButtonSecondary
          size="m"
          color="primary"
          href={docs.apiPlayground}
          target="_blank"
          track={false}
          icon={
            <VuiIcon>
              <BiRightArrowAlt />
            </VuiIcon>
          }
          iconSide="right"
        >
          Open API docs
        </VuiButtonSecondary>
      </div>
    </VuiPopover>
  );
};
