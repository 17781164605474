import { createContext, useState, useContext, ReactNode, useCallback, useEffect } from "react";
import { IconName, IconNames } from "@blueprintjs/icons";
import { useUserContext } from "./UserContext";
import { formattedDate } from "./utils/dateHelpers";
import { ShowToaster, ToastMessageType } from "../views/common/Toaster";
import { db } from "./utils/db";
import { Customer } from "../types/user";
import { Notification } from "../types/notification";

interface NotificationsContextType {
  addNotification: (message: string, intent: ToastMessageType, timeout?: number) => void;
  getNotifications: () => Promise<Notification[]>;
  deleteNotification: (id: number) => Promise<void>;
  clearNotifications: () => Promise<void>;
  updatedNotifications: number;
  notifications: Notification[];
  unreadCount: number;
}

const NotificationsContext = createContext<NotificationsContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const NotificationsContextProvider = ({ children }: Props) => {
  const { customer } = useUserContext();
  const [updatedNotifications, setUpdatedNotifications] = useState(0);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const getNotifications = async () => {
    const { customerId, userName } = customer ?? {};
    const notifications: Notification[] = await db.collection("notifications").get();
    if (notifications?.length > 0) {
      if (customerId) {
        const currCusNotifications = notifications.filter(
          (notification: Notification) =>
            (notification.customerId === customerId || notification.customerId === "") &&
            (notification.userName === userName || notification.userName === "")
        );
        return currCusNotifications;
      } else {
        const currCusNotifications = notifications?.filter(
          (notification: Notification) => notification.customerId === "" && notification.userName === ""
        );
        return currCusNotifications;
      }
    } else {
      return [];
    }
  };

  const loadNotifications = useCallback(() => {
    getNotifications().then((notifications: Notification[]) => {
      const unreadNotifications = notifications?.filter((notification) => !notification.read);
      const data = notifications?.reverse();
      setNotifications(data || []);
      setUnreadCount(unreadNotifications?.length ?? 0);
    });
  }, [getNotifications]);

  useEffect(() => {
    loadNotifications();
  }, []);

  const addNotification = async (message: string, toastMessageType: ToastMessageType, timeout = 5000) => {
    const { customerId, userName } = customer ?? {};

    const TOAST_MESSAGE_TYPE_TO_ICON: Record<Exclude<ToastMessageType, "none">, IconName> = {
      danger: IconNames.ERROR,
      warning: IconNames.WARNING_SIGN,
      success: IconNames.TICK,
      primary: IconNames.INFO_SIGN
    };
    const TOAST_MESSAGE_TYPE_TO_TITLE: Record<Exclude<ToastMessageType, "none">, string> = {
      danger: "Error",
      warning: "Warning",
      success: "Success",
      primary: "Primary"
    };

    if (toastMessageType === "none") {
      return;
    }

    ShowToaster(
      message,
      toastMessageType,
      timeout, // timeout
      TOAST_MESSAGE_TYPE_TO_ICON[toastMessageType]
    );

    const data = await db.collection("notifications").get();
    let id = data?.length > 0 ? data[data.length - 1]["id"] : 0;
    await db.collection("notifications").add({
      id: ++id,
      desc: message,
      title: TOAST_MESSAGE_TYPE_TO_TITLE[toastMessageType],
      time: formattedDate(),
      read: false,
      customerId: customerId ? customerId : "",
      userName: userName ? userName : ""
    });
    updateNotifications();
  };

  const deleteNotification = async (id: number) => {
    await db.collection("notifications").doc({ id }).update({
      read: true
    });
    updateNotifications();
  };

  const clearNotifications = async () => {
    const notifications = await db.collection("notifications").get();
    if (!notifications) {
      return;
    }

    const currentCustomerId = customer?.customerId;
    const custNotificatons = notifications?.filter(({ customerId }: Customer) => customerId === currentCustomerId);
    if (currentCustomerId && custNotificatons?.length > 0) {
      db.collection("notifications").doc({ customerId: currentCustomerId }).delete();
    }

    const globalNotifications = notifications?.filter(({ customerId }: Customer) => customerId === "");
    if (globalNotifications?.length > 0) {
      db.collection("notifications").doc({ customerId: "" }).delete();
    }

    updateNotifications();
  };

  const updateNotifications = () => {
    setUpdatedNotifications((n) => n + 1);
  };

  return (
    <NotificationsContext.Provider
      value={{
        addNotification,
        getNotifications,
        deleteNotification,
        clearNotifications,
        updatedNotifications,
        notifications,
        unreadCount
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsContext = () => {
  const context = useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error("useNotificationsContext must be used within a NotificationsContextProvider");
  }
  return context;
};
