import { VuiFlexItem, VuiText } from "@vectara/vectara-ui";

type Props = {
  children: React.ReactNode;
};

export const HeaderBreadcrumbLabel = ({ children }: Props) => {
  return (
    <VuiFlexItem>
      <VuiText className="headerBreadcrumbLabel">
        <p>{children}</p>
      </VuiText>
    </VuiFlexItem>
  );
};
