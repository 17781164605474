// source: admin/admin_country.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.com.vectara.admin.CountryCode', null, global);
/**
 * @enum {number}
 */
proto.com.vectara.admin.CountryCode = {
  COUNTRY_UNSPECIFIED: 0,
  COUNTRY_CODE_AFG: 4,
  COUNTRY_CODE_ALB: 8,
  COUNTRY_CODE_ATA: 10,
  COUNTRY_CODE_DZA: 12,
  COUNTRY_CODE_ASM: 16,
  COUNTRY_CODE_AND: 20,
  COUNTRY_CODE_AGO: 24,
  COUNTRY_CODE_ATG: 28,
  COUNTRY_CODE_AZE: 31,
  COUNTRY_CODE_ARG: 32,
  COUNTRY_CODE_AUS: 36,
  COUNTRY_CODE_AUT: 40,
  COUNTRY_CODE_BHS: 44,
  COUNTRY_CODE_BHR: 48,
  COUNTRY_CODE_BGD: 50,
  COUNTRY_CODE_ARM: 51,
  COUNTRY_CODE_BRB: 52,
  COUNTRY_CODE_BEL: 56,
  COUNTRY_CODE_BMU: 60,
  COUNTRY_CODE_BTN: 64,
  COUNTRY_CODE_BOL: 68,
  COUNTRY_CODE_BIH: 70,
  COUNTRY_CODE_BWA: 72,
  COUNTRY_CODE_BVT: 74,
  COUNTRY_CODE_BRA: 76,
  COUNTRY_CODE_BLZ: 84,
  COUNTRY_CODE_IOT: 86,
  COUNTRY_CODE_SLB: 90,
  COUNTRY_CODE_VGB: 92,
  COUNTRY_CODE_BRN: 96,
  COUNTRY_CODE_BGR: 100,
  COUNTRY_CODE_MMR: 104,
  COUNTRY_CODE_BDI: 108,
  COUNTRY_CODE_BLR: 112,
  COUNTRY_CODE_KHM: 116,
  COUNTRY_CODE_CMR: 120,
  COUNTRY_CODE_CAN: 124,
  COUNTRY_CODE_CPV: 132,
  COUNTRY_CODE_CYM: 136,
  COUNTRY_CODE_CAF: 140,
  COUNTRY_CODE_LKA: 144,
  COUNTRY_CODE_TCD: 148,
  COUNTRY_CODE_CHL: 152,
  COUNTRY_CODE_CHN: 156,
  COUNTRY_CODE_TWN: 158,
  COUNTRY_CODE_CXR: 162,
  COUNTRY_CODE_CCK: 166,
  COUNTRY_CODE_COL: 170,
  COUNTRY_CODE_COM: 174,
  COUNTRY_CODE_MYT: 175,
  COUNTRY_CODE_COG: 178,
  COUNTRY_CODE_COD: 180,
  COUNTRY_CODE_COK: 184,
  COUNTRY_CODE_CRI: 188,
  COUNTRY_CODE_HRV: 191,
  COUNTRY_CODE_CUB: 192,
  COUNTRY_CODE_CYP: 196,
  COUNTRY_CODE_CZE: 203,
  COUNTRY_CODE_BEN: 204,
  COUNTRY_CODE_DNK: 208,
  COUNTRY_CODE_DMA: 212,
  COUNTRY_CODE_DOM: 214,
  COUNTRY_CODE_ECU: 218,
  COUNTRY_CODE_SLV: 222,
  COUNTRY_CODE_GNQ: 226,
  COUNTRY_CODE_ETH: 231,
  COUNTRY_CODE_ERI: 232,
  COUNTRY_CODE_EST: 233,
  COUNTRY_CODE_FRO: 234,
  COUNTRY_CODE_FLK: 238,
  COUNTRY_CODE_SGS: 239,
  COUNTRY_CODE_FJI: 242,
  COUNTRY_CODE_FIN: 246,
  COUNTRY_CODE_ALA: 248,
  COUNTRY_CODE_FRA: 250,
  COUNTRY_CODE_GUF: 254,
  COUNTRY_CODE_PYF: 258,
  COUNTRY_CODE_ATF: 260,
  COUNTRY_CODE_DJI: 262,
  COUNTRY_CODE_GAB: 266,
  COUNTRY_CODE_GEO: 268,
  COUNTRY_CODE_GMB: 270,
  COUNTRY_CODE_PSE: 275,
  COUNTRY_CODE_DEU: 276,
  COUNTRY_CODE_GHA: 288,
  COUNTRY_CODE_GIB: 292,
  COUNTRY_CODE_KIR: 296,
  COUNTRY_CODE_GRC: 300,
  COUNTRY_CODE_GRL: 304,
  COUNTRY_CODE_GRD: 308,
  COUNTRY_CODE_GLP: 312,
  COUNTRY_CODE_GUM: 316,
  COUNTRY_CODE_GTM: 320,
  COUNTRY_CODE_GIN: 324,
  COUNTRY_CODE_GUY: 328,
  COUNTRY_CODE_HTI: 332,
  COUNTRY_CODE_HMD: 334,
  COUNTRY_CODE_VAT: 336,
  COUNTRY_CODE_HND: 340,
  COUNTRY_CODE_HKG: 344,
  COUNTRY_CODE_HUN: 348,
  COUNTRY_CODE_ISL: 352,
  COUNTRY_CODE_IND: 356,
  COUNTRY_CODE_IDN: 360,
  COUNTRY_CODE_IRN: 364,
  COUNTRY_CODE_IRQ: 368,
  COUNTRY_CODE_IRL: 372,
  COUNTRY_CODE_ISR: 376,
  COUNTRY_CODE_ITA: 380,
  COUNTRY_CODE_CIV: 384,
  COUNTRY_CODE_JAM: 388,
  COUNTRY_CODE_JPN: 392,
  COUNTRY_CODE_KAZ: 398,
  COUNTRY_CODE_JOR: 400,
  COUNTRY_CODE_KEN: 404,
  COUNTRY_CODE_PRK: 408,
  COUNTRY_CODE_KOR: 410,
  COUNTRY_CODE_KWT: 414,
  COUNTRY_CODE_KGZ: 417,
  COUNTRY_CODE_LAO: 418,
  COUNTRY_CODE_LBN: 422,
  COUNTRY_CODE_LSO: 426,
  COUNTRY_CODE_LVA: 428,
  COUNTRY_CODE_LBR: 430,
  COUNTRY_CODE_LBY: 434,
  COUNTRY_CODE_LIE: 438,
  COUNTRY_CODE_LTU: 440,
  COUNTRY_CODE_LUX: 442,
  COUNTRY_CODE_MAC: 446,
  COUNTRY_CODE_MDG: 450,
  COUNTRY_CODE_MWI: 454,
  COUNTRY_CODE_MYS: 458,
  COUNTRY_CODE_MDV: 462,
  COUNTRY_CODE_MLI: 466,
  COUNTRY_CODE_MLT: 470,
  COUNTRY_CODE_MTQ: 474,
  COUNTRY_CODE_MRT: 478,
  COUNTRY_CODE_MUS: 480,
  COUNTRY_CODE_MEX: 484,
  COUNTRY_CODE_MCO: 492,
  COUNTRY_CODE_MNG: 496,
  COUNTRY_CODE_MDA: 498,
  COUNTRY_CODE_MNE: 499,
  COUNTRY_CODE_MSR: 500,
  COUNTRY_CODE_MAR: 504,
  COUNTRY_CODE_MOZ: 508,
  COUNTRY_CODE_OMN: 512,
  COUNTRY_CODE_NAM: 516,
  COUNTRY_CODE_NRU: 520,
  COUNTRY_CODE_NPL: 524,
  COUNTRY_CODE_NLD: 528,
  COUNTRY_CODE_CUW: 531,
  COUNTRY_CODE_ABW: 533,
  COUNTRY_CODE_SXM: 534,
  COUNTRY_CODE_BES: 535,
  COUNTRY_CODE_NCL: 540,
  COUNTRY_CODE_VUT: 548,
  COUNTRY_CODE_NZL: 554,
  COUNTRY_CODE_NIC: 558,
  COUNTRY_CODE_NER: 562,
  COUNTRY_CODE_NGA: 566,
  COUNTRY_CODE_NIU: 570,
  COUNTRY_CODE_NFK: 574,
  COUNTRY_CODE_NOR: 578,
  COUNTRY_CODE_MNP: 580,
  COUNTRY_CODE_UMI: 581,
  COUNTRY_CODE_FSM: 583,
  COUNTRY_CODE_MHL: 584,
  COUNTRY_CODE_PLW: 585,
  COUNTRY_CODE_PAK: 586,
  COUNTRY_CODE_PAN: 591,
  COUNTRY_CODE_PNG: 598,
  COUNTRY_CODE_PRY: 600,
  COUNTRY_CODE_PER: 604,
  COUNTRY_CODE_PHL: 608,
  COUNTRY_CODE_PCN: 612,
  COUNTRY_CODE_POL: 616,
  COUNTRY_CODE_PRT: 620,
  COUNTRY_CODE_GNB: 624,
  COUNTRY_CODE_TLS: 626,
  COUNTRY_CODE_PRI: 630,
  COUNTRY_CODE_QAT: 634,
  COUNTRY_CODE_REU: 638,
  COUNTRY_CODE_ROU: 642,
  COUNTRY_CODE_RUS: 643,
  COUNTRY_CODE_RWA: 646,
  COUNTRY_CODE_BLM: 652,
  COUNTRY_CODE_SHN: 654,
  COUNTRY_CODE_KNA: 659,
  COUNTRY_CODE_AIA: 660,
  COUNTRY_CODE_LCA: 662,
  COUNTRY_CODE_MAF: 663,
  COUNTRY_CODE_SPM: 666,
  COUNTRY_CODE_VCT: 670,
  COUNTRY_CODE_SMR: 674,
  COUNTRY_CODE_STP: 678,
  COUNTRY_CODE_SAU: 682,
  COUNTRY_CODE_SEN: 686,
  COUNTRY_CODE_SRB: 688,
  COUNTRY_CODE_SYC: 690,
  COUNTRY_CODE_SLE: 694,
  COUNTRY_CODE_SGP: 702,
  COUNTRY_CODE_SVK: 703,
  COUNTRY_CODE_VNM: 704,
  COUNTRY_CODE_SVN: 705,
  COUNTRY_CODE_SOM: 706,
  COUNTRY_CODE_ZAF: 710,
  COUNTRY_CODE_ZWE: 716,
  COUNTRY_CODE_ESP: 724,
  COUNTRY_CODE_SSD: 728,
  COUNTRY_CODE_SDN: 729,
  COUNTRY_CODE_ESH: 732,
  COUNTRY_CODE_SUR: 740,
  COUNTRY_CODE_SJM: 744,
  COUNTRY_CODE_SWZ: 748,
  COUNTRY_CODE_SWE: 752,
  COUNTRY_CODE_CHE: 756,
  COUNTRY_CODE_SYR: 760,
  COUNTRY_CODE_TJK: 762,
  COUNTRY_CODE_THA: 764,
  COUNTRY_CODE_TGO: 768,
  COUNTRY_CODE_TKL: 772,
  COUNTRY_CODE_TON: 776,
  COUNTRY_CODE_TTO: 780,
  COUNTRY_CODE_ARE: 784,
  COUNTRY_CODE_TUN: 788,
  COUNTRY_CODE_TUR: 792,
  COUNTRY_CODE_TKM: 795,
  COUNTRY_CODE_TCA: 796,
  COUNTRY_CODE_TUV: 798,
  COUNTRY_CODE_UGA: 800,
  COUNTRY_CODE_UKR: 804,
  COUNTRY_CODE_MKD: 807,
  COUNTRY_CODE_EGY: 818,
  COUNTRY_CODE_GBR: 826,
  COUNTRY_CODE_GGY: 831,
  COUNTRY_CODE_JEY: 832,
  COUNTRY_CODE_IMN: 833,
  COUNTRY_CODE_TZA: 834,
  COUNTRY_CODE_USA: 840,
  COUNTRY_CODE_VIR: 850,
  COUNTRY_CODE_BFA: 854,
  COUNTRY_CODE_URY: 858,
  COUNTRY_CODE_UZB: 860,
  COUNTRY_CODE_VEN: 862,
  COUNTRY_CODE_WLF: 876,
  COUNTRY_CODE_WSM: 882,
  COUNTRY_CODE_YEM: 887,
  COUNTRY_CODE_ZMB: 894
};

goog.object.extend(exports, proto.com.vectara.admin);
