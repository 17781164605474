import { BillingDetailType } from "../../../../generated_protos/admin/admin_billing_pb";
import { BillingPeriodData } from "../types";

export const getBundlesQuantity = (billingPeriodData: BillingPeriodData) => {
  return (
    billingPeriodData
      .filter((data) => {
        return data.billingDetailType === BillingDetailType.BILLING_DETAIL_TYPE__BUNDLE;
      })
      .at(0)?.bundlesActivated || 0
  );
};
