// source: admin/admin_account.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var admin_admin_metric_pb = require('../admin/admin_metric_pb.js');
goog.object.extend(proto, admin_admin_metric_pb);
var admin_admin_user_pb = require('../admin/admin_user_pb.js');
goog.object.extend(proto, admin_admin_user_pb);
var status_pb = require('../status_pb.js');
goog.object.extend(proto, status_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.com.vectara.admin.ClientDataKey', null, global);
goog.exportSymbol('proto.com.vectara.admin.ComputeAccountSizeRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ComputeAccountSizeResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.DeleteClientDataRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.DeleteClientDataResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ExtendSessionRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ExtendSessionResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.GetClientDataRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.GetClientDataResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.GetCustomerLoginAttribsRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.GetCustomerLoginAttribsRequest.OneofLoginhandleCase', null, global);
goog.exportSymbol('proto.com.vectara.admin.GetCustomerLoginAttribsResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.KeyValue', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadAWSMPDetailsRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadAWSMPDetailsResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadAccountSizeRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadAccountSizeResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.SetClientDataRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.SetClientDataResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.SleepRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.SleepResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.StatusOrData', null, global);
goog.exportSymbol('proto.com.vectara.admin.StatusOrData.OneofGetresponseCase', null, global);
goog.exportSymbol('proto.com.vectara.admin.TextSize', null, global);
goog.exportSymbol('proto.com.vectara.admin.TimeAndSize', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ComputeAccountSizeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ComputeAccountSizeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ComputeAccountSizeRequest.displayName = 'proto.com.vectara.admin.ComputeAccountSizeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.TextSize = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.TextSize, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.TextSize.displayName = 'proto.com.vectara.admin.TextSize';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ComputeAccountSizeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ComputeAccountSizeResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ComputeAccountSizeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ComputeAccountSizeResponse.displayName = 'proto.com.vectara.admin.ComputeAccountSizeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadAccountSizeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ReadAccountSizeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadAccountSizeRequest.displayName = 'proto.com.vectara.admin.ReadAccountSizeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.TimeAndSize = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.TimeAndSize, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.TimeAndSize.displayName = 'proto.com.vectara.admin.TimeAndSize';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadAccountSizeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ReadAccountSizeResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ReadAccountSizeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadAccountSizeResponse.displayName = 'proto.com.vectara.admin.ReadAccountSizeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.SleepRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.SleepRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.SleepRequest.displayName = 'proto.com.vectara.admin.SleepRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.SleepResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.SleepResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.SleepResponse.displayName = 'proto.com.vectara.admin.SleepResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.KeyValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.KeyValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.KeyValue.displayName = 'proto.com.vectara.admin.KeyValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.GetClientDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.GetClientDataRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.GetClientDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.GetClientDataRequest.displayName = 'proto.com.vectara.admin.GetClientDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.GetClientDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.GetClientDataResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.GetClientDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.GetClientDataResponse.displayName = 'proto.com.vectara.admin.GetClientDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.DeleteClientDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.DeleteClientDataRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.DeleteClientDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.DeleteClientDataRequest.displayName = 'proto.com.vectara.admin.DeleteClientDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.DeleteClientDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.DeleteClientDataResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.DeleteClientDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.DeleteClientDataResponse.displayName = 'proto.com.vectara.admin.DeleteClientDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.SetClientDataRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.SetClientDataRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.SetClientDataRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.SetClientDataRequest.displayName = 'proto.com.vectara.admin.SetClientDataRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.SetClientDataResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.SetClientDataResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.SetClientDataResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.SetClientDataResponse.displayName = 'proto.com.vectara.admin.SetClientDataResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.StatusOrData = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.vectara.admin.StatusOrData.oneofGroups_);
};
goog.inherits(proto.com.vectara.admin.StatusOrData, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.StatusOrData.displayName = 'proto.com.vectara.admin.StatusOrData';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest.displayName = 'proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse.displayName = 'proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ExtendSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ExtendSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ExtendSessionRequest.displayName = 'proto.com.vectara.admin.ExtendSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ExtendSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ExtendSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ExtendSessionResponse.displayName = 'proto.com.vectara.admin.ExtendSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.vectara.admin.GetCustomerLoginAttribsRequest.oneofGroups_);
};
goog.inherits(proto.com.vectara.admin.GetCustomerLoginAttribsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.GetCustomerLoginAttribsRequest.displayName = 'proto.com.vectara.admin.GetCustomerLoginAttribsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.GetCustomerLoginAttribsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.GetCustomerLoginAttribsResponse.displayName = 'proto.com.vectara.admin.GetCustomerLoginAttribsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadAWSMPDetailsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ReadAWSMPDetailsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadAWSMPDetailsRequest.displayName = 'proto.com.vectara.admin.ReadAWSMPDetailsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.ReadAWSMPDetailsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ReadAWSMPDetailsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadAWSMPDetailsResponse.displayName = 'proto.com.vectara.admin.ReadAWSMPDetailsResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ComputeAccountSizeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ComputeAccountSizeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ComputeAccountSizeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ComputeAccountSizeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ComputeAccountSizeRequest}
 */
proto.com.vectara.admin.ComputeAccountSizeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ComputeAccountSizeRequest;
  return proto.com.vectara.admin.ComputeAccountSizeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ComputeAccountSizeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ComputeAccountSizeRequest}
 */
proto.com.vectara.admin.ComputeAccountSizeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ComputeAccountSizeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ComputeAccountSizeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ComputeAccountSizeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ComputeAccountSizeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.TextSize.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.TextSize.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.TextSize} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.TextSize.toObject = function(includeInstance, msg) {
  var f, obj = {
    numChars: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numMetadataChars: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numDocs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numParts: jspb.Message.getFieldWithDefault(msg, 4, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.TextSize}
 */
proto.com.vectara.admin.TextSize.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.TextSize;
  return proto.com.vectara.admin.TextSize.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.TextSize} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.TextSize}
 */
proto.com.vectara.admin.TextSize.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNumChars(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNumMetadataChars(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNumDocs(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setNumParts(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.TextSize.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.TextSize.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.TextSize} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.TextSize.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNumChars();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
  f = message.getNumMetadataChars();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getNumDocs();
  if (f !== 0) {
    writer.writeUint64(
      3,
      f
    );
  }
  f = message.getNumParts();
  if (f !== 0) {
    writer.writeUint64(
      4,
      f
    );
  }
};


/**
 * optional uint64 num_chars = 1;
 * @return {number}
 */
proto.com.vectara.admin.TextSize.prototype.getNumChars = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.TextSize} returns this
 */
proto.com.vectara.admin.TextSize.prototype.setNumChars = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint64 num_metadata_chars = 2;
 * @return {number}
 */
proto.com.vectara.admin.TextSize.prototype.getNumMetadataChars = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.TextSize} returns this
 */
proto.com.vectara.admin.TextSize.prototype.setNumMetadataChars = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint64 num_docs = 3;
 * @return {number}
 */
proto.com.vectara.admin.TextSize.prototype.getNumDocs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.TextSize} returns this
 */
proto.com.vectara.admin.TextSize.prototype.setNumDocs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint64 num_parts = 4;
 * @return {number}
 */
proto.com.vectara.admin.TextSize.prototype.getNumParts = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.TextSize} returns this
 */
proto.com.vectara.admin.TextSize.prototype.setNumParts = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ComputeAccountSizeResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ComputeAccountSizeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ComputeAccountSizeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ComputeAccountSizeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ComputeAccountSizeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    sizeList: jspb.Message.toObjectList(msg.getSizeList(),
    proto.com.vectara.admin.TextSize.toObject, includeInstance),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ComputeAccountSizeResponse}
 */
proto.com.vectara.admin.ComputeAccountSizeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ComputeAccountSizeResponse;
  return proto.com.vectara.admin.ComputeAccountSizeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ComputeAccountSizeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ComputeAccountSizeResponse}
 */
proto.com.vectara.admin.ComputeAccountSizeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.TextSize;
      reader.readMessage(value,proto.com.vectara.admin.TextSize.deserializeBinaryFromReader);
      msg.addSize(value);
      break;
    case 2:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ComputeAccountSizeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ComputeAccountSizeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ComputeAccountSizeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ComputeAccountSizeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSizeList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.TextSize.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TextSize size = 1;
 * @return {!Array<!proto.com.vectara.admin.TextSize>}
 */
proto.com.vectara.admin.ComputeAccountSizeResponse.prototype.getSizeList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.TextSize>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.TextSize, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.TextSize>} value
 * @return {!proto.com.vectara.admin.ComputeAccountSizeResponse} returns this
*/
proto.com.vectara.admin.ComputeAccountSizeResponse.prototype.setSizeList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.TextSize=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.TextSize}
 */
proto.com.vectara.admin.ComputeAccountSizeResponse.prototype.addSize = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.TextSize, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ComputeAccountSizeResponse} returns this
 */
proto.com.vectara.admin.ComputeAccountSizeResponse.prototype.clearSizeList = function() {
  return this.setSizeList([]);
};


/**
 * optional com.vectara.Status status = 2;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ComputeAccountSizeResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 2));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ComputeAccountSizeResponse} returns this
*/
proto.com.vectara.admin.ComputeAccountSizeResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ComputeAccountSizeResponse} returns this
 */
proto.com.vectara.admin.ComputeAccountSizeResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ComputeAccountSizeResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadAccountSizeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadAccountSizeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadAccountSizeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadAccountSizeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    absoluteWindow: (f = msg.getAbsoluteWindow()) && admin_admin_metric_pb.AbsoluteWindow.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadAccountSizeRequest}
 */
proto.com.vectara.admin.ReadAccountSizeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadAccountSizeRequest;
  return proto.com.vectara.admin.ReadAccountSizeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadAccountSizeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadAccountSizeRequest}
 */
proto.com.vectara.admin.ReadAccountSizeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new admin_admin_metric_pb.AbsoluteWindow;
      reader.readMessage(value,admin_admin_metric_pb.AbsoluteWindow.deserializeBinaryFromReader);
      msg.setAbsoluteWindow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadAccountSizeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadAccountSizeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadAccountSizeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadAccountSizeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAbsoluteWindow();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      admin_admin_metric_pb.AbsoluteWindow.serializeBinaryToWriter
    );
  }
};


/**
 * optional AbsoluteWindow absolute_window = 1;
 * @return {?proto.com.vectara.admin.AbsoluteWindow}
 */
proto.com.vectara.admin.ReadAccountSizeRequest.prototype.getAbsoluteWindow = function() {
  return /** @type{?proto.com.vectara.admin.AbsoluteWindow} */ (
    jspb.Message.getWrapperField(this, admin_admin_metric_pb.AbsoluteWindow, 1));
};


/**
 * @param {?proto.com.vectara.admin.AbsoluteWindow|undefined} value
 * @return {!proto.com.vectara.admin.ReadAccountSizeRequest} returns this
*/
proto.com.vectara.admin.ReadAccountSizeRequest.prototype.setAbsoluteWindow = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadAccountSizeRequest} returns this
 */
proto.com.vectara.admin.ReadAccountSizeRequest.prototype.clearAbsoluteWindow = function() {
  return this.setAbsoluteWindow(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadAccountSizeRequest.prototype.hasAbsoluteWindow = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.TimeAndSize.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.TimeAndSize.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.TimeAndSize} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.TimeAndSize.toObject = function(includeInstance, msg) {
  var f, obj = {
    tsEffective: (f = msg.getTsEffective()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    size: (f = msg.getSize()) && proto.com.vectara.admin.TextSize.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.TimeAndSize}
 */
proto.com.vectara.admin.TimeAndSize.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.TimeAndSize;
  return proto.com.vectara.admin.TimeAndSize.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.TimeAndSize} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.TimeAndSize}
 */
proto.com.vectara.admin.TimeAndSize.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTsEffective(value);
      break;
    case 2:
      var value = new proto.com.vectara.admin.TextSize;
      reader.readMessage(value,proto.com.vectara.admin.TextSize.deserializeBinaryFromReader);
      msg.setSize(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.TimeAndSize.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.TimeAndSize.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.TimeAndSize} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.TimeAndSize.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTsEffective();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getSize();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.vectara.admin.TextSize.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp ts_effective = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.com.vectara.admin.TimeAndSize.prototype.getTsEffective = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.com.vectara.admin.TimeAndSize} returns this
*/
proto.com.vectara.admin.TimeAndSize.prototype.setTsEffective = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.TimeAndSize} returns this
 */
proto.com.vectara.admin.TimeAndSize.prototype.clearTsEffective = function() {
  return this.setTsEffective(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.TimeAndSize.prototype.hasTsEffective = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TextSize size = 2;
 * @return {?proto.com.vectara.admin.TextSize}
 */
proto.com.vectara.admin.TimeAndSize.prototype.getSize = function() {
  return /** @type{?proto.com.vectara.admin.TextSize} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.TextSize, 2));
};


/**
 * @param {?proto.com.vectara.admin.TextSize|undefined} value
 * @return {!proto.com.vectara.admin.TimeAndSize} returns this
*/
proto.com.vectara.admin.TimeAndSize.prototype.setSize = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.TimeAndSize} returns this
 */
proto.com.vectara.admin.TimeAndSize.prototype.clearSize = function() {
  return this.setSize(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.TimeAndSize.prototype.hasSize = function() {
  return jspb.Message.getField(this, 2) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ReadAccountSizeResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadAccountSizeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadAccountSizeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadAccountSizeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadAccountSizeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    timeddataList: jspb.Message.toObjectList(msg.getTimeddataList(),
    proto.com.vectara.admin.TimeAndSize.toObject, includeInstance),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadAccountSizeResponse}
 */
proto.com.vectara.admin.ReadAccountSizeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadAccountSizeResponse;
  return proto.com.vectara.admin.ReadAccountSizeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadAccountSizeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadAccountSizeResponse}
 */
proto.com.vectara.admin.ReadAccountSizeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.TimeAndSize;
      reader.readMessage(value,proto.com.vectara.admin.TimeAndSize.deserializeBinaryFromReader);
      msg.addTimeddata(value);
      break;
    case 2:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadAccountSizeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadAccountSizeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadAccountSizeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadAccountSizeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimeddataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.TimeAndSize.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * repeated TimeAndSize timedData = 1;
 * @return {!Array<!proto.com.vectara.admin.TimeAndSize>}
 */
proto.com.vectara.admin.ReadAccountSizeResponse.prototype.getTimeddataList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.TimeAndSize>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.TimeAndSize, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.TimeAndSize>} value
 * @return {!proto.com.vectara.admin.ReadAccountSizeResponse} returns this
*/
proto.com.vectara.admin.ReadAccountSizeResponse.prototype.setTimeddataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.TimeAndSize=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.TimeAndSize}
 */
proto.com.vectara.admin.ReadAccountSizeResponse.prototype.addTimeddata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.TimeAndSize, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadAccountSizeResponse} returns this
 */
proto.com.vectara.admin.ReadAccountSizeResponse.prototype.clearTimeddataList = function() {
  return this.setTimeddataList([]);
};


/**
 * optional com.vectara.Status status = 2;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReadAccountSizeResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 2));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReadAccountSizeResponse} returns this
*/
proto.com.vectara.admin.ReadAccountSizeResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadAccountSizeResponse} returns this
 */
proto.com.vectara.admin.ReadAccountSizeResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadAccountSizeResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.SleepRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.SleepRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.SleepRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.SleepRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    duration: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.SleepRequest}
 */
proto.com.vectara.admin.SleepRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.SleepRequest;
  return proto.com.vectara.admin.SleepRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.SleepRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.SleepRequest}
 */
proto.com.vectara.admin.SleepRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.SleepRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.SleepRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.SleepRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.SleepRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDuration();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 duration = 1;
 * @return {number}
 */
proto.com.vectara.admin.SleepRequest.prototype.getDuration = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.SleepRequest} returns this
 */
proto.com.vectara.admin.SleepRequest.prototype.setDuration = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.SleepResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.SleepResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.SleepResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.SleepResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.SleepResponse}
 */
proto.com.vectara.admin.SleepResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.SleepResponse;
  return proto.com.vectara.admin.SleepResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.SleepResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.SleepResponse}
 */
proto.com.vectara.admin.SleepResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.SleepResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.SleepResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.SleepResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.SleepResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.KeyValue.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.KeyValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.KeyValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.KeyValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    key: jspb.Message.getFieldWithDefault(msg, 1, 0),
    data: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.KeyValue}
 */
proto.com.vectara.admin.KeyValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.KeyValue;
  return proto.com.vectara.admin.KeyValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.KeyValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.KeyValue}
 */
proto.com.vectara.admin.KeyValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.com.vectara.admin.ClientDataKey} */ (reader.readEnum());
      msg.setKey(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.KeyValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.KeyValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.KeyValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.KeyValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKey();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getData();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional ClientDataKey key = 1;
 * @return {!proto.com.vectara.admin.ClientDataKey}
 */
proto.com.vectara.admin.KeyValue.prototype.getKey = function() {
  return /** @type {!proto.com.vectara.admin.ClientDataKey} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.com.vectara.admin.ClientDataKey} value
 * @return {!proto.com.vectara.admin.KeyValue} returns this
 */
proto.com.vectara.admin.KeyValue.prototype.setKey = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string data = 2;
 * @return {string}
 */
proto.com.vectara.admin.KeyValue.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.KeyValue} returns this
 */
proto.com.vectara.admin.KeyValue.prototype.setData = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.GetClientDataRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.GetClientDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.GetClientDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.GetClientDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.GetClientDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.GetClientDataRequest}
 */
proto.com.vectara.admin.GetClientDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.GetClientDataRequest;
  return proto.com.vectara.admin.GetClientDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.GetClientDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.GetClientDataRequest}
 */
proto.com.vectara.admin.GetClientDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.com.vectara.admin.ClientDataKey>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addKey(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.GetClientDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.GetClientDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.GetClientDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.GetClientDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated ClientDataKey key = 1;
 * @return {!Array<!proto.com.vectara.admin.ClientDataKey>}
 */
proto.com.vectara.admin.GetClientDataRequest.prototype.getKeyList = function() {
  return /** @type {!Array<!proto.com.vectara.admin.ClientDataKey>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.ClientDataKey>} value
 * @return {!proto.com.vectara.admin.GetClientDataRequest} returns this
 */
proto.com.vectara.admin.GetClientDataRequest.prototype.setKeyList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.com.vectara.admin.ClientDataKey} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.GetClientDataRequest} returns this
 */
proto.com.vectara.admin.GetClientDataRequest.prototype.addKey = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.GetClientDataRequest} returns this
 */
proto.com.vectara.admin.GetClientDataRequest.prototype.clearKeyList = function() {
  return this.setKeyList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.GetClientDataResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.GetClientDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.GetClientDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.GetClientDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.GetClientDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusordataList: jspb.Message.toObjectList(msg.getStatusordataList(),
    proto.com.vectara.admin.StatusOrData.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.GetClientDataResponse}
 */
proto.com.vectara.admin.GetClientDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.GetClientDataResponse;
  return proto.com.vectara.admin.GetClientDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.GetClientDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.GetClientDataResponse}
 */
proto.com.vectara.admin.GetClientDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.StatusOrData;
      reader.readMessage(value,proto.com.vectara.admin.StatusOrData.deserializeBinaryFromReader);
      msg.addStatusordata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.GetClientDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.GetClientDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.GetClientDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.GetClientDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusordataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.StatusOrData.serializeBinaryToWriter
    );
  }
};


/**
 * repeated StatusOrData statusOrData = 1;
 * @return {!Array<!proto.com.vectara.admin.StatusOrData>}
 */
proto.com.vectara.admin.GetClientDataResponse.prototype.getStatusordataList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.StatusOrData>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.StatusOrData, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.StatusOrData>} value
 * @return {!proto.com.vectara.admin.GetClientDataResponse} returns this
*/
proto.com.vectara.admin.GetClientDataResponse.prototype.setStatusordataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.StatusOrData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.StatusOrData}
 */
proto.com.vectara.admin.GetClientDataResponse.prototype.addStatusordata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.StatusOrData, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.GetClientDataResponse} returns this
 */
proto.com.vectara.admin.GetClientDataResponse.prototype.clearStatusordataList = function() {
  return this.setStatusordataList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.DeleteClientDataRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.DeleteClientDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.DeleteClientDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.DeleteClientDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.DeleteClientDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    keyList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.DeleteClientDataRequest}
 */
proto.com.vectara.admin.DeleteClientDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.DeleteClientDataRequest;
  return proto.com.vectara.admin.DeleteClientDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.DeleteClientDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.DeleteClientDataRequest}
 */
proto.com.vectara.admin.DeleteClientDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<!proto.com.vectara.admin.ClientDataKey>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addKey(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.DeleteClientDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.DeleteClientDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.DeleteClientDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.DeleteClientDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeyList();
  if (f.length > 0) {
    writer.writePackedEnum(
      1,
      f
    );
  }
};


/**
 * repeated ClientDataKey key = 1;
 * @return {!Array<!proto.com.vectara.admin.ClientDataKey>}
 */
proto.com.vectara.admin.DeleteClientDataRequest.prototype.getKeyList = function() {
  return /** @type {!Array<!proto.com.vectara.admin.ClientDataKey>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.ClientDataKey>} value
 * @return {!proto.com.vectara.admin.DeleteClientDataRequest} returns this
 */
proto.com.vectara.admin.DeleteClientDataRequest.prototype.setKeyList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!proto.com.vectara.admin.ClientDataKey} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.DeleteClientDataRequest} returns this
 */
proto.com.vectara.admin.DeleteClientDataRequest.prototype.addKey = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.DeleteClientDataRequest} returns this
 */
proto.com.vectara.admin.DeleteClientDataRequest.prototype.clearKeyList = function() {
  return this.setKeyList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.DeleteClientDataResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.DeleteClientDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.DeleteClientDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.DeleteClientDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.DeleteClientDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    status_pb.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.DeleteClientDataResponse}
 */
proto.com.vectara.admin.DeleteClientDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.DeleteClientDataResponse;
  return proto.com.vectara.admin.DeleteClientDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.DeleteClientDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.DeleteClientDataResponse}
 */
proto.com.vectara.admin.DeleteClientDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.DeleteClientDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.DeleteClientDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.DeleteClientDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.DeleteClientDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * repeated com.vectara.Status status = 1;
 * @return {!Array<!proto.com.vectara.Status>}
 */
proto.com.vectara.admin.DeleteClientDataResponse.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.com.vectara.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, status_pb.Status, 1));
};


/**
 * @param {!Array<!proto.com.vectara.Status>} value
 * @return {!proto.com.vectara.admin.DeleteClientDataResponse} returns this
*/
proto.com.vectara.admin.DeleteClientDataResponse.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.Status}
 */
proto.com.vectara.admin.DeleteClientDataResponse.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.DeleteClientDataResponse} returns this
 */
proto.com.vectara.admin.DeleteClientDataResponse.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.SetClientDataRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.SetClientDataRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.SetClientDataRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.SetClientDataRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.SetClientDataRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestList: jspb.Message.toObjectList(msg.getRequestList(),
    proto.com.vectara.admin.KeyValue.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.SetClientDataRequest}
 */
proto.com.vectara.admin.SetClientDataRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.SetClientDataRequest;
  return proto.com.vectara.admin.SetClientDataRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.SetClientDataRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.SetClientDataRequest}
 */
proto.com.vectara.admin.SetClientDataRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.KeyValue;
      reader.readMessage(value,proto.com.vectara.admin.KeyValue.deserializeBinaryFromReader);
      msg.addRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.SetClientDataRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.SetClientDataRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.SetClientDataRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.SetClientDataRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.KeyValue.serializeBinaryToWriter
    );
  }
};


/**
 * repeated KeyValue request = 1;
 * @return {!Array<!proto.com.vectara.admin.KeyValue>}
 */
proto.com.vectara.admin.SetClientDataRequest.prototype.getRequestList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.KeyValue>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.KeyValue, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.KeyValue>} value
 * @return {!proto.com.vectara.admin.SetClientDataRequest} returns this
*/
proto.com.vectara.admin.SetClientDataRequest.prototype.setRequestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.KeyValue=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.KeyValue}
 */
proto.com.vectara.admin.SetClientDataRequest.prototype.addRequest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.KeyValue, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.SetClientDataRequest} returns this
 */
proto.com.vectara.admin.SetClientDataRequest.prototype.clearRequestList = function() {
  return this.setRequestList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.SetClientDataResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.SetClientDataResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.SetClientDataResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.SetClientDataResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.SetClientDataResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    status_pb.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.SetClientDataResponse}
 */
proto.com.vectara.admin.SetClientDataResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.SetClientDataResponse;
  return proto.com.vectara.admin.SetClientDataResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.SetClientDataResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.SetClientDataResponse}
 */
proto.com.vectara.admin.SetClientDataResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.SetClientDataResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.SetClientDataResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.SetClientDataResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.SetClientDataResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * repeated com.vectara.Status status = 1;
 * @return {!Array<!proto.com.vectara.Status>}
 */
proto.com.vectara.admin.SetClientDataResponse.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.com.vectara.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, status_pb.Status, 1));
};


/**
 * @param {!Array<!proto.com.vectara.Status>} value
 * @return {!proto.com.vectara.admin.SetClientDataResponse} returns this
*/
proto.com.vectara.admin.SetClientDataResponse.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.Status}
 */
proto.com.vectara.admin.SetClientDataResponse.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.SetClientDataResponse} returns this
 */
proto.com.vectara.admin.SetClientDataResponse.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.vectara.admin.StatusOrData.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.com.vectara.admin.StatusOrData.OneofGetresponseCase = {
  ONEOF_GETRESPONSE_NOT_SET: 0,
  DATA: 1,
  STATUS: 2
};

/**
 * @return {proto.com.vectara.admin.StatusOrData.OneofGetresponseCase}
 */
proto.com.vectara.admin.StatusOrData.prototype.getOneofGetresponseCase = function() {
  return /** @type {proto.com.vectara.admin.StatusOrData.OneofGetresponseCase} */(jspb.Message.computeOneofCase(this, proto.com.vectara.admin.StatusOrData.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.StatusOrData.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.StatusOrData.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.StatusOrData} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.StatusOrData.toObject = function(includeInstance, msg) {
  var f, obj = {
    data: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.StatusOrData}
 */
proto.com.vectara.admin.StatusOrData.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.StatusOrData;
  return proto.com.vectara.admin.StatusOrData.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.StatusOrData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.StatusOrData}
 */
proto.com.vectara.admin.StatusOrData.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setData(value);
      break;
    case 2:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.StatusOrData.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.StatusOrData.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.StatusOrData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.StatusOrData.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional string data = 1;
 * @return {string}
 */
proto.com.vectara.admin.StatusOrData.prototype.getData = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.StatusOrData} returns this
 */
proto.com.vectara.admin.StatusOrData.prototype.setData = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.com.vectara.admin.StatusOrData.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.admin.StatusOrData} returns this
 */
proto.com.vectara.admin.StatusOrData.prototype.clearData = function() {
  return jspb.Message.setOneofField(this, 1, proto.com.vectara.admin.StatusOrData.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.StatusOrData.prototype.hasData = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional com.vectara.Status status = 2;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.StatusOrData.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 2));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.StatusOrData} returns this
*/
proto.com.vectara.admin.StatusOrData.prototype.setStatus = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.vectara.admin.StatusOrData.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.StatusOrData} returns this
 */
proto.com.vectara.admin.StatusOrData.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.StatusOrData.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    username: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest}
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest;
  return proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest}
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional uint32 customer_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest} returns this
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string username = 2;
 * @return {string}
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest} returns this
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse}
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse;
  return proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse}
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.vectara.Status status = 1;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse} returns this
*/
proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse} returns this
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.InitiatePasswordRecoveryFlowResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ExtendSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ExtendSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ExtendSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ExtendSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ExtendSessionRequest}
 */
proto.com.vectara.admin.ExtendSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ExtendSessionRequest;
  return proto.com.vectara.admin.ExtendSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ExtendSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ExtendSessionRequest}
 */
proto.com.vectara.admin.ExtendSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ExtendSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ExtendSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ExtendSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ExtendSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.com.vectara.admin.ExtendSessionRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ExtendSessionRequest} returns this
 */
proto.com.vectara.admin.ExtendSessionRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ExtendSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ExtendSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ExtendSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ExtendSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ExtendSessionResponse}
 */
proto.com.vectara.admin.ExtendSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ExtendSessionResponse;
  return proto.com.vectara.admin.ExtendSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ExtendSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ExtendSessionResponse}
 */
proto.com.vectara.admin.ExtendSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ExtendSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ExtendSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ExtendSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ExtendSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.vectara.Status status = 1;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ExtendSessionResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ExtendSessionResponse} returns this
*/
proto.com.vectara.admin.ExtendSessionResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ExtendSessionResponse} returns this
 */
proto.com.vectara.admin.ExtendSessionResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ExtendSessionResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.oneofGroups_ = [[1,10,15]];

/**
 * @enum {number}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.OneofLoginhandleCase = {
  ONEOF_LOGINHANDLE_NOT_SET: 0,
  CUSTOMER_ID: 1,
  ACCOUNT_ALIAS: 10,
  ROOT_EMAIL: 15
};

/**
 * @return {proto.com.vectara.admin.GetCustomerLoginAttribsRequest.OneofLoginhandleCase}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.getOneofLoginhandleCase = function() {
  return /** @type {proto.com.vectara.admin.GetCustomerLoginAttribsRequest.OneofLoginhandleCase} */(jspb.Message.computeOneofCase(this, proto.com.vectara.admin.GetCustomerLoginAttribsRequest.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.GetCustomerLoginAttribsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.GetCustomerLoginAttribsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    accountAlias: jspb.Message.getFieldWithDefault(msg, 10, ""),
    rootEmail: jspb.Message.getFieldWithDefault(msg, 15, ""),
    handle: jspb.Message.getFieldWithDefault(msg, 20, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsRequest}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.GetCustomerLoginAttribsRequest;
  return proto.com.vectara.admin.GetCustomerLoginAttribsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.GetCustomerLoginAttribsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsRequest}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCustomerId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountAlias(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setRootEmail(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.GetCustomerLoginAttribsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.GetCustomerLoginAttribsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {number} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 10));
  if (f != null) {
    writer.writeString(
      10,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 15));
  if (f != null) {
    writer.writeString(
      15,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 20));
  if (f != null) {
    writer.writeString(
      20,
      f
    );
  }
};


/**
 * optional uint32 customer_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsRequest} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.com.vectara.admin.GetCustomerLoginAttribsRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsRequest} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.clearCustomerId = function() {
  return jspb.Message.setOneofField(this, 1, proto.com.vectara.admin.GetCustomerLoginAttribsRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.hasCustomerId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string account_alias = 10;
 * @return {string}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.getAccountAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsRequest} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.setAccountAlias = function(value) {
  return jspb.Message.setOneofField(this, 10, proto.com.vectara.admin.GetCustomerLoginAttribsRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsRequest} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.clearAccountAlias = function() {
  return jspb.Message.setOneofField(this, 10, proto.com.vectara.admin.GetCustomerLoginAttribsRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.hasAccountAlias = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional string root_email = 15;
 * @return {string}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.getRootEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsRequest} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.setRootEmail = function(value) {
  return jspb.Message.setOneofField(this, 15, proto.com.vectara.admin.GetCustomerLoginAttribsRequest.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsRequest} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.clearRootEmail = function() {
  return jspb.Message.setOneofField(this, 15, proto.com.vectara.admin.GetCustomerLoginAttribsRequest.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.hasRootEmail = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional string handle = 20;
 * @return {string}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.getHandle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsRequest} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.setHandle = function(value) {
  return jspb.Message.setField(this, 20, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsRequest} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.clearHandle = function() {
  return jspb.Message.setField(this, 20, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsRequest.prototype.hasHandle = function() {
  return jspb.Message.getField(this, 20) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.GetCustomerLoginAttribsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    region: jspb.Message.getFieldWithDefault(msg, 1, ""),
    userPoolId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    userPoolWebClientId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    servingEndpoint: jspb.Message.getFieldWithDefault(msg, 4, ""),
    indexingEndpoint: jspb.Message.getFieldWithDefault(msg, 5, ""),
    adminEndpoint: jspb.Message.getFieldWithDefault(msg, 6, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 7, 0),
    isAwsMpCustomer: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    defaultChatHistoryCorpusId: jspb.Message.getFieldWithDefault(msg, 9, 0),
    isExpired: jspb.Message.getBooleanFieldWithDefault(msg, 10, false),
    expiryTs: jspb.Message.getFieldWithDefault(msg, 11, 0),
    userType: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.GetCustomerLoginAttribsResponse;
  return proto.com.vectara.admin.GetCustomerLoginAttribsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setRegion(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPoolId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserPoolWebClientId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setServingEndpoint(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIndexingEndpoint(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAdminEndpoint(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCustomerId(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAwsMpCustomer(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDefaultChatHistoryCorpusId(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsExpired(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setExpiryTs(value);
      break;
    case 12:
      var value = /** @type {!proto.com.vectara.admin.UserType} */ (reader.readEnum());
      msg.setUserType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.GetCustomerLoginAttribsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRegion();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUserPoolId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getUserPoolWebClientId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getServingEndpoint();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIndexingEndpoint();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAdminEndpoint();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeUint32(
      7,
      f
    );
  }
  f = message.getIsAwsMpCustomer();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getDefaultChatHistoryCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      9,
      f
    );
  }
  f = message.getIsExpired();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
  f = message.getExpiryTs();
  if (f !== 0) {
    writer.writeInt64(
      11,
      f
    );
  }
  f = message.getUserType();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
};


/**
 * optional string region = 1;
 * @return {string}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.getRegion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.setRegion = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string user_pool_id = 2;
 * @return {string}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.getUserPoolId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.setUserPoolId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string user_pool_web_client_id = 3;
 * @return {string}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.getUserPoolWebClientId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.setUserPoolWebClientId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string serving_endpoint = 4;
 * @return {string}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.getServingEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.setServingEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string indexing_endpoint = 5;
 * @return {string}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.getIndexingEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.setIndexingEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string admin_endpoint = 6;
 * @return {string}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.getAdminEndpoint = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.setAdminEndpoint = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional uint32 customer_id = 7;
 * @return {number}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional bool is_aws_mp_customer = 8;
 * @return {boolean}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.getIsAwsMpCustomer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.setIsAwsMpCustomer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional uint32 default_chat_history_corpus_id = 9;
 * @return {number}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.getDefaultChatHistoryCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.setDefaultChatHistoryCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 9, value);
};


/**
 * optional bool is_expired = 10;
 * @return {boolean}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.getIsExpired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.setIsExpired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};


/**
 * optional int64 expiry_ts = 11;
 * @return {number}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.getExpiryTs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.setExpiryTs = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * optional UserType user_type = 12;
 * @return {!proto.com.vectara.admin.UserType}
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.getUserType = function() {
  return /** @type {!proto.com.vectara.admin.UserType} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.com.vectara.admin.UserType} value
 * @return {!proto.com.vectara.admin.GetCustomerLoginAttribsResponse} returns this
 */
proto.com.vectara.admin.GetCustomerLoginAttribsResponse.prototype.setUserType = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadAWSMPDetailsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadAWSMPDetailsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadAWSMPDetailsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadAWSMPDetailsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadAWSMPDetailsRequest}
 */
proto.com.vectara.admin.ReadAWSMPDetailsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadAWSMPDetailsRequest;
  return proto.com.vectara.admin.ReadAWSMPDetailsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadAWSMPDetailsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadAWSMPDetailsRequest}
 */
proto.com.vectara.admin.ReadAWSMPDetailsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCustomerId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadAWSMPDetailsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadAWSMPDetailsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadAWSMPDetailsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadAWSMPDetailsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 customer_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.ReadAWSMPDetailsRequest.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ReadAWSMPDetailsRequest} returns this
 */
proto.com.vectara.admin.ReadAWSMPDetailsRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadAWSMPDetailsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadAWSMPDetailsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    awsId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    awsAccountId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    productIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    pricingPlanId: jspb.Message.getFieldWithDefault(msg, 4, ""),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadAWSMPDetailsResponse}
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadAWSMPDetailsResponse;
  return proto.com.vectara.admin.ReadAWSMPDetailsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadAWSMPDetailsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadAWSMPDetailsResponse}
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAwsId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAwsAccountId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.addProductId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setPricingPlanId(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadAWSMPDetailsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadAWSMPDetailsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAwsId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAwsAccountId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getProductIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      3,
      f
    );
  }
  f = message.getPricingPlanId();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional string aws_id = 1;
 * @return {string}
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.prototype.getAwsId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ReadAWSMPDetailsResponse} returns this
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.prototype.setAwsId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string aws_account_id = 2;
 * @return {string}
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.prototype.getAwsAccountId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ReadAWSMPDetailsResponse} returns this
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.prototype.setAwsAccountId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated string product_id = 3;
 * @return {!Array<string>}
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.prototype.getProductIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.vectara.admin.ReadAWSMPDetailsResponse} returns this
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.prototype.setProductIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ReadAWSMPDetailsResponse} returns this
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.prototype.addProductId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadAWSMPDetailsResponse} returns this
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.prototype.clearProductIdList = function() {
  return this.setProductIdList([]);
};


/**
 * optional string pricing_plan_id = 4;
 * @return {string}
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.prototype.getPricingPlanId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ReadAWSMPDetailsResponse} returns this
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.prototype.setPricingPlanId = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReadAWSMPDetailsResponse} returns this
*/
proto.com.vectara.admin.ReadAWSMPDetailsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadAWSMPDetailsResponse} returns this
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadAWSMPDetailsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};


/**
 * @enum {number}
 */
proto.com.vectara.admin.ClientDataKey = {
  CLIENT_DATA_KEY__UNDEFINED: 0,
  CLIENT_DATA_KEY__CORPORA_MRU: 1,
  CLIENT_DATA_KEY__ACTIVE_CORPUS: 2,
  CLIENT_DATA_KEY__ACTIVE_FILTER: 3,
  CLIENT_DATA_KEY__CONFIG: 4
};

goog.object.extend(exports, proto.com.vectara.admin);
