import { GenerationPreset, Llm } from "../../admin/apiV2Client";

export const selectGenerationPreset = (generationPresets: GenerationPreset[], llmName: Llm["name"]) => {
  const availableGenerationPresets = [];

  // Find the generation preset tagged as default.
  for (const generationPreset of generationPresets) {
    const { llm_name, default: isDefault } = generationPreset;
    if (llm_name === llmName) {
      if (isDefault) {
        return generationPreset;
      }
      availableGenerationPresets.push(generationPreset);
    }
  }

  // Default to the first generation preset if none is
  // tagged as the default.
  return availableGenerationPresets.length > 0 ? availableGenerationPresets[0] : undefined;
};
