import { IconName, Toaster } from "@blueprintjs/core";
import { createRoot } from "react-dom/client";

/** Singleton toaster instance. Create separate instances for different options. */
let toaster: InstanceType<typeof Toaster> | null = null;

if (!toaster) {
  const el = document.getElementById("toaster");
  if (el) {
    const toasterRoot = createRoot(el);
    toasterRoot.render(
      <Toaster
        ref={(instance) => {
          toaster = instance;
        }}
      />
    );
  }
}

export type ToastMessageType = "none" | "primary" | "success" | "warning" | "danger";

export const ShowToaster = (message: string, intent: ToastMessageType, timeout = 5000, icon: IconName) => {
  toaster?.show({
    message,
    intent,
    timeout,
    icon
  });
};
