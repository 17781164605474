import { apiV2Client } from "./apiV2Client";

export const sendFetchJobsRequest = async (jwt: string, customerId: string, corpusKeys: string[]) => {
  const { data, error } = await apiV2Client.GET("/v2/jobs", {
    headers: {
      "customer-id": customerId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`
    },
    params: {
      query: {
        state: ["queued", "started", "failed", "failed_will_retry"],
        corpus_key: corpusKeys
      }
    }
  });

  if (error) {
    throw error;
  }

  return data;
};
