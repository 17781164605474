// source: admin/admin_security.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var status_pb = require('../status_pb.js');
goog.object.extend(proto, status_pb);
goog.exportSymbol('proto.com.vectara.admin.CorpusDefaultRole', null, global);
goog.exportSymbol('proto.com.vectara.admin.CorpusRole', null, global);
goog.exportSymbol('proto.com.vectara.admin.CorpusUserRole', null, global);
goog.exportSymbol('proto.com.vectara.admin.CustomerRole', null, global);
goog.exportSymbol('proto.com.vectara.admin.CustomerUserRole', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListCorpusDefaultRolesRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListCorpusRolesRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListCorpusRolesResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.RoleOneofCase', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListCustomerRolesRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListCustomerRolesResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.LoginRedirectRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.LoginRedirectResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.RoleRequestList', null, global);
goog.exportSymbol('proto.com.vectara.admin.RoleRequestList.Role', null, global);
goog.exportSymbol('proto.com.vectara.admin.RoleRequestList.Role.RoleOneofCase', null, global);
goog.exportSymbol('proto.com.vectara.admin.RoleResponseList', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.CorpusUserRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.CorpusUserRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.CorpusUserRole.displayName = 'proto.com.vectara.admin.CorpusUserRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.CorpusDefaultRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.CorpusDefaultRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.CorpusDefaultRole.displayName = 'proto.com.vectara.admin.CorpusDefaultRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.CustomerUserRole = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.CustomerUserRole, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.CustomerUserRole.displayName = 'proto.com.vectara.admin.CustomerUserRole';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.RoleRequestList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.RoleRequestList.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.RoleRequestList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.RoleRequestList.displayName = 'proto.com.vectara.admin.RoleRequestList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.RoleRequestList.Role = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.vectara.admin.RoleRequestList.Role.oneofGroups_);
};
goog.inherits(proto.com.vectara.admin.RoleRequestList.Role, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.RoleRequestList.Role.displayName = 'proto.com.vectara.admin.RoleRequestList.Role';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.RoleResponseList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.RoleResponseList.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.RoleResponseList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.RoleResponseList.displayName = 'proto.com.vectara.admin.RoleResponseList';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListCorpusRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ListCorpusRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListCorpusRolesRequest.displayName = 'proto.com.vectara.admin.ListCorpusRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ListCorpusDefaultRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListCorpusDefaultRolesRequest.displayName = 'proto.com.vectara.admin.ListCorpusDefaultRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListCustomerRolesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ListCustomerRolesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListCustomerRolesRequest.displayName = 'proto.com.vectara.admin.ListCustomerRolesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListCorpusRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.ListCorpusRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ListCorpusRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListCorpusRolesResponse.displayName = 'proto.com.vectara.admin.ListCorpusRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.oneofGroups_);
};
goog.inherits(proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.displayName = 'proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListCustomerRolesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.ListCustomerRolesResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ListCustomerRolesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListCustomerRolesResponse.displayName = 'proto.com.vectara.admin.ListCustomerRolesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.LoginRedirectRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.LoginRedirectRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.LoginRedirectRequest.displayName = 'proto.com.vectara.admin.LoginRedirectRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.LoginRedirectResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.LoginRedirectResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.LoginRedirectResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.LoginRedirectResponse.displayName = 'proto.com.vectara.admin.LoginRedirectResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.CorpusUserRole.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.CorpusUserRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.CorpusUserRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CorpusUserRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    role: jspb.Message.getFieldWithDefault(msg, 3, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.CorpusUserRole}
 */
proto.com.vectara.admin.CorpusUserRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.CorpusUserRole;
  return proto.com.vectara.admin.CorpusUserRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.CorpusUserRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.CorpusUserRole}
 */
proto.com.vectara.admin.CorpusUserRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {!proto.com.vectara.admin.CorpusRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.CorpusUserRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.CorpusUserRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.CorpusUserRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CorpusUserRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.CorpusUserRole.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.CorpusUserRole} returns this
 */
proto.com.vectara.admin.CorpusUserRole.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 user_id = 2;
 * @return {number}
 */
proto.com.vectara.admin.CorpusUserRole.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.CorpusUserRole} returns this
 */
proto.com.vectara.admin.CorpusUserRole.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional CorpusRole role = 3;
 * @return {!proto.com.vectara.admin.CorpusRole}
 */
proto.com.vectara.admin.CorpusUserRole.prototype.getRole = function() {
  return /** @type {!proto.com.vectara.admin.CorpusRole} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.vectara.admin.CorpusRole} value
 * @return {!proto.com.vectara.admin.CorpusUserRole} returns this
 */
proto.com.vectara.admin.CorpusUserRole.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string comment = 4;
 * @return {string}
 */
proto.com.vectara.admin.CorpusUserRole.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.CorpusUserRole} returns this
 */
proto.com.vectara.admin.CorpusUserRole.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.CorpusDefaultRole.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.CorpusDefaultRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.CorpusDefaultRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CorpusDefaultRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    role: jspb.Message.getFieldWithDefault(msg, 2, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.CorpusDefaultRole}
 */
proto.com.vectara.admin.CorpusDefaultRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.CorpusDefaultRole;
  return proto.com.vectara.admin.CorpusDefaultRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.CorpusDefaultRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.CorpusDefaultRole}
 */
proto.com.vectara.admin.CorpusDefaultRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 2:
      var value = /** @type {!proto.com.vectara.admin.CorpusRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.CorpusDefaultRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.CorpusDefaultRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.CorpusDefaultRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CorpusDefaultRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.CorpusDefaultRole.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.CorpusDefaultRole} returns this
 */
proto.com.vectara.admin.CorpusDefaultRole.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CorpusRole role = 2;
 * @return {!proto.com.vectara.admin.CorpusRole}
 */
proto.com.vectara.admin.CorpusDefaultRole.prototype.getRole = function() {
  return /** @type {!proto.com.vectara.admin.CorpusRole} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.vectara.admin.CorpusRole} value
 * @return {!proto.com.vectara.admin.CorpusDefaultRole} returns this
 */
proto.com.vectara.admin.CorpusDefaultRole.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.com.vectara.admin.CorpusDefaultRole.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.CorpusDefaultRole} returns this
 */
proto.com.vectara.admin.CorpusDefaultRole.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.CustomerUserRole.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.CustomerUserRole.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.CustomerUserRole} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CustomerUserRole.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    role: jspb.Message.getFieldWithDefault(msg, 3, 0),
    comment: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.CustomerUserRole}
 */
proto.com.vectara.admin.CustomerUserRole.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.CustomerUserRole;
  return proto.com.vectara.admin.CustomerUserRole.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.CustomerUserRole} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.CustomerUserRole}
 */
proto.com.vectara.admin.CustomerUserRole.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {!proto.com.vectara.admin.CustomerRole} */ (reader.readEnum());
      msg.setRole(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.CustomerUserRole.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.CustomerUserRole.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.CustomerUserRole} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.CustomerUserRole.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRole();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional uint32 user_id = 2;
 * @return {number}
 */
proto.com.vectara.admin.CustomerUserRole.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.CustomerUserRole} returns this
 */
proto.com.vectara.admin.CustomerUserRole.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional CustomerRole role = 3;
 * @return {!proto.com.vectara.admin.CustomerRole}
 */
proto.com.vectara.admin.CustomerUserRole.prototype.getRole = function() {
  return /** @type {!proto.com.vectara.admin.CustomerRole} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.vectara.admin.CustomerRole} value
 * @return {!proto.com.vectara.admin.CustomerUserRole} returns this
 */
proto.com.vectara.admin.CustomerUserRole.prototype.setRole = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string comment = 4;
 * @return {string}
 */
proto.com.vectara.admin.CustomerUserRole.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.CustomerUserRole} returns this
 */
proto.com.vectara.admin.CustomerUserRole.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.RoleRequestList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.RoleRequestList.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.RoleRequestList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.RoleRequestList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.RoleRequestList.toObject = function(includeInstance, msg) {
  var f, obj = {
    requestList: jspb.Message.toObjectList(msg.getRequestList(),
    proto.com.vectara.admin.RoleRequestList.Role.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.RoleRequestList}
 */
proto.com.vectara.admin.RoleRequestList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.RoleRequestList;
  return proto.com.vectara.admin.RoleRequestList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.RoleRequestList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.RoleRequestList}
 */
proto.com.vectara.admin.RoleRequestList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.RoleRequestList.Role;
      reader.readMessage(value,proto.com.vectara.admin.RoleRequestList.Role.deserializeBinaryFromReader);
      msg.addRequest(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.RoleRequestList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.RoleRequestList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.RoleRequestList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.RoleRequestList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRequestList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.RoleRequestList.Role.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.vectara.admin.RoleRequestList.Role.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.com.vectara.admin.RoleRequestList.Role.RoleOneofCase = {
  ROLE_ONEOF_NOT_SET: 0,
  CORPUS_USER_ROLE: 1,
  CORPUS_DEFAULT_ROLE: 2,
  CUSTOMER_USER_ROLE: 3
};

/**
 * @return {proto.com.vectara.admin.RoleRequestList.Role.RoleOneofCase}
 */
proto.com.vectara.admin.RoleRequestList.Role.prototype.getRoleOneofCase = function() {
  return /** @type {proto.com.vectara.admin.RoleRequestList.Role.RoleOneofCase} */(jspb.Message.computeOneofCase(this, proto.com.vectara.admin.RoleRequestList.Role.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.RoleRequestList.Role.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.RoleRequestList.Role.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.RoleRequestList.Role} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.RoleRequestList.Role.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusUserRole: (f = msg.getCorpusUserRole()) && proto.com.vectara.admin.CorpusUserRole.toObject(includeInstance, f),
    corpusDefaultRole: (f = msg.getCorpusDefaultRole()) && proto.com.vectara.admin.CorpusDefaultRole.toObject(includeInstance, f),
    customerUserRole: (f = msg.getCustomerUserRole()) && proto.com.vectara.admin.CustomerUserRole.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.RoleRequestList.Role}
 */
proto.com.vectara.admin.RoleRequestList.Role.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.RoleRequestList.Role;
  return proto.com.vectara.admin.RoleRequestList.Role.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.RoleRequestList.Role} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.RoleRequestList.Role}
 */
proto.com.vectara.admin.RoleRequestList.Role.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.CorpusUserRole;
      reader.readMessage(value,proto.com.vectara.admin.CorpusUserRole.deserializeBinaryFromReader);
      msg.setCorpusUserRole(value);
      break;
    case 2:
      var value = new proto.com.vectara.admin.CorpusDefaultRole;
      reader.readMessage(value,proto.com.vectara.admin.CorpusDefaultRole.deserializeBinaryFromReader);
      msg.setCorpusDefaultRole(value);
      break;
    case 3:
      var value = new proto.com.vectara.admin.CustomerUserRole;
      reader.readMessage(value,proto.com.vectara.admin.CustomerUserRole.deserializeBinaryFromReader);
      msg.setCustomerUserRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.RoleRequestList.Role.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.RoleRequestList.Role.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.RoleRequestList.Role} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.RoleRequestList.Role.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusUserRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.vectara.admin.CorpusUserRole.serializeBinaryToWriter
    );
  }
  f = message.getCorpusDefaultRole();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.vectara.admin.CorpusDefaultRole.serializeBinaryToWriter
    );
  }
  f = message.getCustomerUserRole();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.vectara.admin.CustomerUserRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional CorpusUserRole corpus_user_role = 1;
 * @return {?proto.com.vectara.admin.CorpusUserRole}
 */
proto.com.vectara.admin.RoleRequestList.Role.prototype.getCorpusUserRole = function() {
  return /** @type{?proto.com.vectara.admin.CorpusUserRole} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.CorpusUserRole, 1));
};


/**
 * @param {?proto.com.vectara.admin.CorpusUserRole|undefined} value
 * @return {!proto.com.vectara.admin.RoleRequestList.Role} returns this
*/
proto.com.vectara.admin.RoleRequestList.Role.prototype.setCorpusUserRole = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.com.vectara.admin.RoleRequestList.Role.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.RoleRequestList.Role} returns this
 */
proto.com.vectara.admin.RoleRequestList.Role.prototype.clearCorpusUserRole = function() {
  return this.setCorpusUserRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.RoleRequestList.Role.prototype.hasCorpusUserRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CorpusDefaultRole corpus_default_role = 2;
 * @return {?proto.com.vectara.admin.CorpusDefaultRole}
 */
proto.com.vectara.admin.RoleRequestList.Role.prototype.getCorpusDefaultRole = function() {
  return /** @type{?proto.com.vectara.admin.CorpusDefaultRole} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.CorpusDefaultRole, 2));
};


/**
 * @param {?proto.com.vectara.admin.CorpusDefaultRole|undefined} value
 * @return {!proto.com.vectara.admin.RoleRequestList.Role} returns this
*/
proto.com.vectara.admin.RoleRequestList.Role.prototype.setCorpusDefaultRole = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.vectara.admin.RoleRequestList.Role.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.RoleRequestList.Role} returns this
 */
proto.com.vectara.admin.RoleRequestList.Role.prototype.clearCorpusDefaultRole = function() {
  return this.setCorpusDefaultRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.RoleRequestList.Role.prototype.hasCorpusDefaultRole = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional CustomerUserRole customer_user_role = 3;
 * @return {?proto.com.vectara.admin.CustomerUserRole}
 */
proto.com.vectara.admin.RoleRequestList.Role.prototype.getCustomerUserRole = function() {
  return /** @type{?proto.com.vectara.admin.CustomerUserRole} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.CustomerUserRole, 3));
};


/**
 * @param {?proto.com.vectara.admin.CustomerUserRole|undefined} value
 * @return {!proto.com.vectara.admin.RoleRequestList.Role} returns this
*/
proto.com.vectara.admin.RoleRequestList.Role.prototype.setCustomerUserRole = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.com.vectara.admin.RoleRequestList.Role.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.RoleRequestList.Role} returns this
 */
proto.com.vectara.admin.RoleRequestList.Role.prototype.clearCustomerUserRole = function() {
  return this.setCustomerUserRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.RoleRequestList.Role.prototype.hasCustomerUserRole = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Role request = 1;
 * @return {!Array<!proto.com.vectara.admin.RoleRequestList.Role>}
 */
proto.com.vectara.admin.RoleRequestList.prototype.getRequestList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.RoleRequestList.Role>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.RoleRequestList.Role, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.RoleRequestList.Role>} value
 * @return {!proto.com.vectara.admin.RoleRequestList} returns this
*/
proto.com.vectara.admin.RoleRequestList.prototype.setRequestList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.RoleRequestList.Role=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.RoleRequestList.Role}
 */
proto.com.vectara.admin.RoleRequestList.prototype.addRequest = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.RoleRequestList.Role, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.RoleRequestList} returns this
 */
proto.com.vectara.admin.RoleRequestList.prototype.clearRequestList = function() {
  return this.setRequestList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.RoleResponseList.repeatedFields_ = [1000];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.RoleResponseList.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.RoleResponseList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.RoleResponseList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.RoleResponseList.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    status_pb.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.RoleResponseList}
 */
proto.com.vectara.admin.RoleResponseList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.RoleResponseList;
  return proto.com.vectara.admin.RoleResponseList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.RoleResponseList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.RoleResponseList}
 */
proto.com.vectara.admin.RoleResponseList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.RoleResponseList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.RoleResponseList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.RoleResponseList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.RoleResponseList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * repeated com.vectara.Status status = 1000;
 * @return {!Array<!proto.com.vectara.Status>}
 */
proto.com.vectara.admin.RoleResponseList.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.com.vectara.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {!Array<!proto.com.vectara.Status>} value
 * @return {!proto.com.vectara.admin.RoleResponseList} returns this
*/
proto.com.vectara.admin.RoleResponseList.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1000, value);
};


/**
 * @param {!proto.com.vectara.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.Status}
 */
proto.com.vectara.admin.RoleResponseList.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1000, opt_value, proto.com.vectara.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.RoleResponseList} returns this
 */
proto.com.vectara.admin.RoleResponseList.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListCorpusRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListCorpusRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListCorpusRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListCorpusRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    corpusId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    roleFilter: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numResults: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageKey: msg.getPageKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListCorpusRolesRequest}
 */
proto.com.vectara.admin.ListCorpusRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListCorpusRolesRequest;
  return proto.com.vectara.admin.ListCorpusRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListCorpusRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListCorpusRolesRequest}
 */
proto.com.vectara.admin.ListCorpusRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 3:
      var value = /** @type {!proto.com.vectara.admin.CorpusRole} */ (reader.readEnum());
      msg.setRoleFilter(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumResults(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListCorpusRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListCorpusRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListCorpusRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListCorpusRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getRoleFilter();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getNumResults();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * optional uint32 user_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.ListCorpusRolesRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ListCorpusRolesRequest} returns this
 */
proto.com.vectara.admin.ListCorpusRolesRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 corpus_id = 2;
 * @return {number}
 */
proto.com.vectara.admin.ListCorpusRolesRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ListCorpusRolesRequest} returns this
 */
proto.com.vectara.admin.ListCorpusRolesRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional CorpusRole role_filter = 3;
 * @return {!proto.com.vectara.admin.CorpusRole}
 */
proto.com.vectara.admin.ListCorpusRolesRequest.prototype.getRoleFilter = function() {
  return /** @type {!proto.com.vectara.admin.CorpusRole} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.vectara.admin.CorpusRole} value
 * @return {!proto.com.vectara.admin.ListCorpusRolesRequest} returns this
 */
proto.com.vectara.admin.ListCorpusRolesRequest.prototype.setRoleFilter = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 num_results = 4;
 * @return {number}
 */
proto.com.vectara.admin.ListCorpusRolesRequest.prototype.getNumResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ListCorpusRolesRequest} returns this
 */
proto.com.vectara.admin.ListCorpusRolesRequest.prototype.setNumResults = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bytes page_key = 5;
 * @return {string}
 */
proto.com.vectara.admin.ListCorpusRolesRequest.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes page_key = 5;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.admin.ListCorpusRolesRequest.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListCorpusRolesRequest.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.admin.ListCorpusRolesRequest} returns this
 */
proto.com.vectara.admin.ListCorpusRolesRequest.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListCorpusDefaultRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListCorpusDefaultRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    roleFilter: jspb.Message.getFieldWithDefault(msg, 2, 0),
    numResults: jspb.Message.getFieldWithDefault(msg, 3, 0),
    pageKey: msg.getPageKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListCorpusDefaultRolesRequest}
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListCorpusDefaultRolesRequest;
  return proto.com.vectara.admin.ListCorpusDefaultRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListCorpusDefaultRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListCorpusDefaultRolesRequest}
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 2:
      var value = /** @type {!proto.com.vectara.admin.CorpusRole} */ (reader.readEnum());
      msg.setRoleFilter(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumResults(value);
      break;
    case 4:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListCorpusDefaultRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListCorpusDefaultRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRoleFilter();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getNumResults();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      4,
      f
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ListCorpusDefaultRolesRequest} returns this
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CorpusRole role_filter = 2;
 * @return {!proto.com.vectara.admin.CorpusRole}
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest.prototype.getRoleFilter = function() {
  return /** @type {!proto.com.vectara.admin.CorpusRole} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.vectara.admin.CorpusRole} value
 * @return {!proto.com.vectara.admin.ListCorpusDefaultRolesRequest} returns this
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest.prototype.setRoleFilter = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional uint32 num_results = 3;
 * @return {number}
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest.prototype.getNumResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ListCorpusDefaultRolesRequest} returns this
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest.prototype.setNumResults = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional bytes page_key = 4;
 * @return {string}
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * optional bytes page_key = 4;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 4;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.admin.ListCorpusDefaultRolesRequest} returns this
 */
proto.com.vectara.admin.ListCorpusDefaultRolesRequest.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListCustomerRolesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListCustomerRolesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListCustomerRolesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListCustomerRolesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    roleFilter: jspb.Message.getFieldWithDefault(msg, 3, 0),
    numResults: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pageKey: msg.getPageKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListCustomerRolesRequest}
 */
proto.com.vectara.admin.ListCustomerRolesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListCustomerRolesRequest;
  return proto.com.vectara.admin.ListCustomerRolesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListCustomerRolesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListCustomerRolesRequest}
 */
proto.com.vectara.admin.ListCustomerRolesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setUserId(value);
      break;
    case 3:
      var value = /** @type {!proto.com.vectara.admin.CustomerRole} */ (reader.readEnum());
      msg.setRoleFilter(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumResults(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListCustomerRolesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListCustomerRolesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListCustomerRolesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListCustomerRolesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getRoleFilter();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getNumResults();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
};


/**
 * optional uint32 user_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.ListCustomerRolesRequest.prototype.getUserId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ListCustomerRolesRequest} returns this
 */
proto.com.vectara.admin.ListCustomerRolesRequest.prototype.setUserId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CustomerRole role_filter = 3;
 * @return {!proto.com.vectara.admin.CustomerRole}
 */
proto.com.vectara.admin.ListCustomerRolesRequest.prototype.getRoleFilter = function() {
  return /** @type {!proto.com.vectara.admin.CustomerRole} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.com.vectara.admin.CustomerRole} value
 * @return {!proto.com.vectara.admin.ListCustomerRolesRequest} returns this
 */
proto.com.vectara.admin.ListCustomerRolesRequest.prototype.setRoleFilter = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional uint32 num_results = 4;
 * @return {number}
 */
proto.com.vectara.admin.ListCustomerRolesRequest.prototype.getNumResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ListCustomerRolesRequest} returns this
 */
proto.com.vectara.admin.ListCustomerRolesRequest.prototype.setNumResults = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional bytes page_key = 5;
 * @return {string}
 */
proto.com.vectara.admin.ListCustomerRolesRequest.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes page_key = 5;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.admin.ListCustomerRolesRequest.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListCustomerRolesRequest.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.admin.ListCustomerRolesRequest} returns this
 */
proto.com.vectara.admin.ListCustomerRolesRequest.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ListCorpusRolesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListCorpusRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListCorpusRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListCorpusRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    roleList: jspb.Message.toObjectList(msg.getRoleList(),
    proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.toObject, includeInstance),
    pageKey: msg.getPageKey_asB64(),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListCorpusRolesResponse}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListCorpusRolesResponse;
  return proto.com.vectara.admin.ListCorpusRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListCorpusRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListCorpusRolesResponse}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType;
      reader.readMessage(value,proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.deserializeBinaryFromReader);
      msg.addRole(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListCorpusRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListCorpusRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListCorpusRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.serializeBinaryToWriter
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.RoleOneofCase = {
  ROLE_ONEOF_NOT_SET: 0,
  CORPUS_USER_ROLE: 1,
  CORPUS_DEFAULT_ROLE: 2
};

/**
 * @return {proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.RoleOneofCase}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.prototype.getRoleOneofCase = function() {
  return /** @type {proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.RoleOneofCase} */(jspb.Message.computeOneofCase(this, proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusUserRole: (f = msg.getCorpusUserRole()) && proto.com.vectara.admin.CorpusUserRole.toObject(includeInstance, f),
    corpusDefaultRole: (f = msg.getCorpusDefaultRole()) && proto.com.vectara.admin.CorpusDefaultRole.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType;
  return proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.CorpusUserRole;
      reader.readMessage(value,proto.com.vectara.admin.CorpusUserRole.deserializeBinaryFromReader);
      msg.setCorpusUserRole(value);
      break;
    case 2:
      var value = new proto.com.vectara.admin.CorpusDefaultRole;
      reader.readMessage(value,proto.com.vectara.admin.CorpusDefaultRole.deserializeBinaryFromReader);
      msg.setCorpusDefaultRole(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusUserRole();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.vectara.admin.CorpusUserRole.serializeBinaryToWriter
    );
  }
  f = message.getCorpusDefaultRole();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.com.vectara.admin.CorpusDefaultRole.serializeBinaryToWriter
    );
  }
};


/**
 * optional CorpusUserRole corpus_user_role = 1;
 * @return {?proto.com.vectara.admin.CorpusUserRole}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.prototype.getCorpusUserRole = function() {
  return /** @type{?proto.com.vectara.admin.CorpusUserRole} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.CorpusUserRole, 1));
};


/**
 * @param {?proto.com.vectara.admin.CorpusUserRole|undefined} value
 * @return {!proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType} returns this
*/
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.prototype.setCorpusUserRole = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType} returns this
 */
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.prototype.clearCorpusUserRole = function() {
  return this.setCorpusUserRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.prototype.hasCorpusUserRole = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional CorpusDefaultRole corpus_default_role = 2;
 * @return {?proto.com.vectara.admin.CorpusDefaultRole}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.prototype.getCorpusDefaultRole = function() {
  return /** @type{?proto.com.vectara.admin.CorpusDefaultRole} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.CorpusDefaultRole, 2));
};


/**
 * @param {?proto.com.vectara.admin.CorpusDefaultRole|undefined} value
 * @return {!proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType} returns this
*/
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.prototype.setCorpusDefaultRole = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType} returns this
 */
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.prototype.clearCorpusDefaultRole = function() {
  return this.setCorpusDefaultRole(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType.prototype.hasCorpusDefaultRole = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated CorpusRoleType role = 1;
 * @return {!Array<!proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType>}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.prototype.getRoleList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType>} value
 * @return {!proto.com.vectara.admin.ListCorpusRolesResponse} returns this
*/
proto.com.vectara.admin.ListCorpusRolesResponse.prototype.setRoleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.prototype.addRole = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.ListCorpusRolesResponse.CorpusRoleType, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ListCorpusRolesResponse} returns this
 */
proto.com.vectara.admin.ListCorpusRolesResponse.prototype.clearRoleList = function() {
  return this.setRoleList([]);
};


/**
 * optional bytes page_key = 2;
 * @return {string}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes page_key = 2;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.admin.ListCorpusRolesResponse} returns this
 */
proto.com.vectara.admin.ListCorpusRolesResponse.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ListCorpusRolesResponse} returns this
*/
proto.com.vectara.admin.ListCorpusRolesResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ListCorpusRolesResponse} returns this
 */
proto.com.vectara.admin.ListCorpusRolesResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ListCorpusRolesResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ListCustomerRolesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListCustomerRolesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListCustomerRolesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListCustomerRolesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListCustomerRolesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    roleList: jspb.Message.toObjectList(msg.getRoleList(),
    proto.com.vectara.admin.CustomerUserRole.toObject, includeInstance),
    pageKey: msg.getPageKey_asB64(),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListCustomerRolesResponse}
 */
proto.com.vectara.admin.ListCustomerRolesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListCustomerRolesResponse;
  return proto.com.vectara.admin.ListCustomerRolesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListCustomerRolesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListCustomerRolesResponse}
 */
proto.com.vectara.admin.ListCustomerRolesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.CustomerUserRole;
      reader.readMessage(value,proto.com.vectara.admin.CustomerUserRole.deserializeBinaryFromReader);
      msg.addRole(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListCustomerRolesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListCustomerRolesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListCustomerRolesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListCustomerRolesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoleList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.CustomerUserRole.serializeBinaryToWriter
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CustomerUserRole role = 1;
 * @return {!Array<!proto.com.vectara.admin.CustomerUserRole>}
 */
proto.com.vectara.admin.ListCustomerRolesResponse.prototype.getRoleList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.CustomerUserRole>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.CustomerUserRole, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.CustomerUserRole>} value
 * @return {!proto.com.vectara.admin.ListCustomerRolesResponse} returns this
*/
proto.com.vectara.admin.ListCustomerRolesResponse.prototype.setRoleList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.CustomerUserRole=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.CustomerUserRole}
 */
proto.com.vectara.admin.ListCustomerRolesResponse.prototype.addRole = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.CustomerUserRole, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ListCustomerRolesResponse} returns this
 */
proto.com.vectara.admin.ListCustomerRolesResponse.prototype.clearRoleList = function() {
  return this.setRoleList([]);
};


/**
 * optional bytes page_key = 2;
 * @return {string}
 */
proto.com.vectara.admin.ListCustomerRolesResponse.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes page_key = 2;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.admin.ListCustomerRolesResponse.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListCustomerRolesResponse.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.admin.ListCustomerRolesResponse} returns this
 */
proto.com.vectara.admin.ListCustomerRolesResponse.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ListCustomerRolesResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ListCustomerRolesResponse} returns this
*/
proto.com.vectara.admin.ListCustomerRolesResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ListCustomerRolesResponse} returns this
 */
proto.com.vectara.admin.ListCustomerRolesResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ListCustomerRolesResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.LoginRedirectRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.LoginRedirectRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.LoginRedirectRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.LoginRedirectRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    accountAlias: jspb.Message.getFieldWithDefault(msg, 10, ""),
    rootEmail: jspb.Message.getFieldWithDefault(msg, 15, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.LoginRedirectRequest}
 */
proto.com.vectara.admin.LoginRedirectRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.LoginRedirectRequest;
  return proto.com.vectara.admin.LoginRedirectRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.LoginRedirectRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.LoginRedirectRequest}
 */
proto.com.vectara.admin.LoginRedirectRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCustomerId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccountAlias(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setRootEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.LoginRedirectRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.LoginRedirectRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.LoginRedirectRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.LoginRedirectRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getAccountAlias();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getRootEmail();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
};


/**
 * optional uint32 customer_id = 5;
 * @return {number}
 */
proto.com.vectara.admin.LoginRedirectRequest.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.LoginRedirectRequest} returns this
 */
proto.com.vectara.admin.LoginRedirectRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional string account_alias = 10;
 * @return {string}
 */
proto.com.vectara.admin.LoginRedirectRequest.prototype.getAccountAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.LoginRedirectRequest} returns this
 */
proto.com.vectara.admin.LoginRedirectRequest.prototype.setAccountAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string root_email = 15;
 * @return {string}
 */
proto.com.vectara.admin.LoginRedirectRequest.prototype.getRootEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.LoginRedirectRequest} returns this
 */
proto.com.vectara.admin.LoginRedirectRequest.prototype.setRootEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.LoginRedirectResponse.repeatedFields_ = [1000];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.LoginRedirectResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.LoginRedirectResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.LoginRedirectResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.LoginRedirectResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    loginUrl: jspb.Message.getFieldWithDefault(msg, 5, ""),
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    status_pb.Status.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.LoginRedirectResponse}
 */
proto.com.vectara.admin.LoginRedirectResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.LoginRedirectResponse;
  return proto.com.vectara.admin.LoginRedirectResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.LoginRedirectResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.LoginRedirectResponse}
 */
proto.com.vectara.admin.LoginRedirectResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginUrl(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.LoginRedirectResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.LoginRedirectResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.LoginRedirectResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.LoginRedirectResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLoginUrl();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional string login_url = 5;
 * @return {string}
 */
proto.com.vectara.admin.LoginRedirectResponse.prototype.getLoginUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.LoginRedirectResponse} returns this
 */
proto.com.vectara.admin.LoginRedirectResponse.prototype.setLoginUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated com.vectara.Status status = 1000;
 * @return {!Array<!proto.com.vectara.Status>}
 */
proto.com.vectara.admin.LoginRedirectResponse.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.com.vectara.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {!Array<!proto.com.vectara.Status>} value
 * @return {!proto.com.vectara.admin.LoginRedirectResponse} returns this
*/
proto.com.vectara.admin.LoginRedirectResponse.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1000, value);
};


/**
 * @param {!proto.com.vectara.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.Status}
 */
proto.com.vectara.admin.LoginRedirectResponse.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1000, opt_value, proto.com.vectara.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.LoginRedirectResponse} returns this
 */
proto.com.vectara.admin.LoginRedirectResponse.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * @enum {number}
 */
proto.com.vectara.admin.CustomerRole = {
  CUSTOMERROLE_NONE: 0,
  CUSTOMERROLE_OWNER: 1,
  CUSTOMERROLE_ADMIN: 2,
  CUSTOMERROLE_BILLING_ADMIN: 3,
  CUSTOMERROLE_CORPUS_ADMIN: 4
};

/**
 * @enum {number}
 */
proto.com.vectara.admin.CorpusRole = {
  CORPUSROLE_NONE: 0,
  CORPUSROLE_ADMIN: 1,
  CORPUSROLE_QUERY: 2,
  CORPUSROLE_INDEX: 3,
  CORPUSROLE_OWNER: 4,
  CORPUSROLE_CORPUS_ADMIN: 5
};

goog.object.extend(exports, proto.com.vectara.admin);
