import {
  GetCustomerLoginAttribsRequest,
  GetCustomerLoginAttribsResponse,
  InitiatePasswordRecoveryFlowRequest,
  InitiatePasswordRecoveryFlowResponse
} from "../generated_protos/admin/admin_account_pb";
import { CountryCode } from "../generated_protos/admin/admin_country_pb";
import { PublicAdminServicePromiseClient } from "../generated_protos/services_grpc_web_pb";
import {
  ReadCustomerPlanDetailsRequest,
  ReadCustomerPlanDetailsResponse,
  ValidateEmailRequest,
  RegisterCustomerRequest,
  CheckEmailValidationStatusRequest,
  CheckEmailValidationStatusResponse,
  ValidateEmailResponse,
  PlanName,
  ReadCurrentIdForPlanResponse,
  ReadCurrentIdForPlanRequest
} from "../generated_protos/admin/admin_signup_pb";
import { StatusCode } from "../generated_protos/status_pb";
import { datadogRum } from "@datadog/browser-rum";

export const InitiatePasswordRecoveryFlow = (
  publicAdminService: PublicAdminServicePromiseClient,
  customerId: number,
  username: string
): Promise<InitiatePasswordRecoveryFlowResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!publicAdminService) {
      reject("Could not initialize publicAdminService to make gRPC calls");
      return;
    }
    const request = new InitiatePasswordRecoveryFlowRequest();
    request.setCustomerId(customerId);
    request.setUsername(username);
    publicAdminService
      .initiatePasswordRecoveryFlow(request)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: initiatePasswordRecoveryFlow");
          return;
        }
        if (respObj.status?.code !== StatusCode.OK) {
          reject(`Failed with code: ${respObj.status?.code} :${respObj.status?.statusDetail}`);
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const GetCustomerLoginAttribs = (
  publicAdminService: PublicAdminServicePromiseClient,
  customerId = "",
  email = "",
  handle = ""
): Promise<GetCustomerLoginAttribsResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!publicAdminService) {
      reject("Could not initialize publicAdminService to make gRPC calls");
      return;
    }

    const getCustomerLoginRequest = new GetCustomerLoginAttribsRequest();
    let args: { "customer-id": string } | undefined = undefined;
    if (customerId) {
      getCustomerLoginRequest.setCustomerId(Number(customerId));
      args = { "customer-id": customerId };
    }
    if (email && !customerId) {
      getCustomerLoginRequest.setRootEmail(email);
    }
    if (handle) {
      getCustomerLoginRequest.setHandle(handle);
    }

    publicAdminService
      .getCustomerLoginAttribs(getCustomerLoginRequest, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: getCustomerLoginAttribs");
          return;
        }

        datadogRum.setUser({
          id: `${respObj.customerId}`
        });

        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const RequestEmailVerification = ({
  publicAdminService,
  name,
  organization,
  email,
  password,
  planId,
  countryCode,
  paymentId,
  awsToken
}: {
  publicAdminService: PublicAdminServicePromiseClient;
  name: string;
  organization: string;
  email: string;
  password: string;
  planId: string;
  countryCode: CountryCode;
  paymentId?: string;
  awsToken?: string;
}): Promise<ValidateEmailResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!publicAdminService) {
      reject("Could not initialize publicAdminService to make gRPC calls");
      return;
    }

    const validateEmailRequest = new ValidateEmailRequest();

    const registerRequest = new RegisterCustomerRequest();
    registerRequest.setName(name);
    registerRequest.setOrg(organization);
    registerRequest.setEmail(email);
    registerRequest.setPassword(password);
    registerRequest.setPlanId(planId);
    registerRequest.setCountryCode(countryCode);

    if (paymentId) {
      registerRequest.setStripePaymentSource(paymentId);
    }

    if (awsToken) {
      registerRequest.setAwsToken(awsToken);
    }

    validateEmailRequest.setRegistrationRequest(registerRequest);

    publicAdminService
      .validateEmail(validateEmailRequest)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: validateEmail");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const requestToCheckEmailValidationStatus = (
  publicAdminService: PublicAdminServicePromiseClient,
  verificationCode: string
): Promise<CheckEmailValidationStatusResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!publicAdminService) {
      reject("Could not initialize publicAdminService to make gRPC calls");
      return;
    }
    const checkEmailValidationStatus = new CheckEmailValidationStatusRequest();
    checkEmailValidationStatus.setEmailToken(verificationCode);
    publicAdminService
      .checkEmailValidationStatus(checkEmailValidationStatus)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from gRPC call: checkEmailValidationStatus");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const ReadCustomerPlanDetails = (
  publicAdminService: PublicAdminServicePromiseClient,
  customerPlanId: string
): Promise<ReadCustomerPlanDetailsResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!publicAdminService) {
      reject({
        code: 1,
        message: "Could not initialize publicAdminService."
      });
      return;
    }

    const detailsRequest = new ReadCustomerPlanDetailsRequest();
    detailsRequest.setId(customerPlanId);

    publicAdminService
      .readCustomerPlanDetails(detailsRequest)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject({
            code: 1,
            message: "Invalid response from readCustomerPlanDetails"
          });
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const ReadCurrentIdOfPlan = (
  publicAdminService: PublicAdminServicePromiseClient,
  planName: PlanName
): Promise<ReadCurrentIdForPlanResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!publicAdminService) {
      reject({
        code: 1,
        message: "Could not initialize publicAdminService."
      });
      return;
    }

    const detailsRequest = new ReadCurrentIdForPlanRequest();
    detailsRequest.setPlanName(planName);

    publicAdminService
      .readCurrentIdForPlan(detailsRequest)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject({
            code: 1,
            message: "Invalid response from readCurrentIdForPlan"
          });
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};
