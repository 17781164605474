import { Auth } from "aws-amplify";
import { GetUser } from "../../admin/UserEndpoint";
import { ReadCurrentCustomerPlan } from "../../admin/BillingEndpoint";
import { GetCustomerLoginAttribs } from "../../admin/PublicEndpoint";
import { AdminServicePromiseClient } from "../../generated_protos/services_grpc_web_pb";
import { PublicAdminServicePromiseClient } from "../../generated_protos/services_grpc_web_pb";
import { protocol, ports, restServingUrl } from "../../backendConfig";
import { distillRoles } from "./distillRoles";
import { ReadAccountFeatures, ListEncoders, ListRerankers, ReadAwsMpDetails } from "../../admin/UserDetailsApi";
import { ORY_POOL_PREFIX } from "../../backendConfig";
import { removeOryItems } from "../../utils/localStorage/customer";
import { orySdk } from "../../utils/auth/orySdk";
import { sendFetchGenerationPresetsRequest, sendFetchLlmsRequest } from "../../admin/generationApi";
import { datadogRum } from "@datadog/browser-rum";

export const logout = async (isOryAuth: boolean) => {
  // If logged in via Google SSO, this will automatically redirect.
  try {
    if (isOryAuth) {
      const { data: flow } = await orySdk.createBrowserLogoutFlow();

      await orySdk.updateLogoutFlow({
        token: flow.logout_token
      });

      removeOryItems();
      return;
    }

    await Auth.signOut();
  } catch (e) {
    datadogRum.addError(e);
    console.log("logout failed", e);
  }
};

export const fetchCustomerLoginAttribs = async (
  PublicAdminService: PublicAdminServicePromiseClient,
  customerId: string
) => {
  const {
    region,
    userPoolId,
    userPoolWebClientId,
    adminEndpoint,
    servingEndpoint,
    indexingEndpoint,
    isAwsMpCustomer,
    defaultChatHistoryCorpusId,
    expiryTs,
    isExpired
  } = await GetCustomerLoginAttribs(PublicAdminService, customerId);

  const isOryAuth = userPoolId.startsWith(ORY_POOL_PREFIX);

  return {
    isOryAuth,
    defaultChatHistoryCorpusId,
    isAwsMpCustomer,
    awsConfig: { awsRegion: region, userPoolId, userPoolWebClientId },
    urls: {
      adminUrl: `${protocol}://wp.${adminEndpoint}:${ports.adminUrl}`,
      servingUrl: `${protocol}://wp.${servingEndpoint}:${ports.servingUrl}`,
      restServingUrl,
      indexingUrl: `${protocol}://wp.${indexingEndpoint}:${ports.indexingUrl}`,
      uploadUrl: `${protocol}://h.${indexingEndpoint}:${ports.uploadUrl}/upload`
    },
    expiryTs,
    isExpired
  };
};

export const fetchEnabledFeatures = async (
  jwt: string,
  AdminService: AdminServicePromiseClient,
  customerId: string
) => {
  const { accountFeatures } = await ReadAccountFeatures(jwt, AdminService, customerId);
  return accountFeatures?.featureList?.map(({ feature }) => feature) ?? [];
};

export const fetchUserDetails = async (
  jwt: string,
  AdminService: AdminServicePromiseClient,
  customerId: string,
  userHandle: string
) => {
  const { user, analyticsAuthToken } = await GetUser(jwt, AdminService, customerId, userHandle);
  const distilledRoles = distillRoles(user?.roleList);

  const sub = user?.sub;

  if (sub !== undefined) {
    return {
      analyticsAuthToken,
      abilities: distilledRoles.abilities,
      userDetails: {
        sub,
        userCredentials: distilledRoles.userCredentials
      },
      pageAccess: distilledRoles.pageAccess
    };
  }

  throw new Error("Sub is undefined");
};

export const fetchPlan = async (jwt: string, AdminService: AdminServicePromiseClient, customerId: string) => {
  const response = await ReadCurrentCustomerPlan(jwt, AdminService, customerId);
  return {
    customerPlanId: response?.customerPlanId,
    customerPlanName: response?.name,
    customerPlanCode: response?.status?.code
  };
};

export const fetchGenerationPresets = async (jwt: string, customerId: string) => {
  const { data, error } = await sendFetchGenerationPresetsRequest(jwt, customerId);
  return { data, error };
};

export const fetchLlms = async (jwt: string, customerId: string) => {
  const { data, error } = await sendFetchLlmsRequest(jwt, customerId);
  return { data, error };
};

export const fetchEncoders = async (jwt: string, AdminService: AdminServicePromiseClient, customerId: string) => {
  const { encoderList } = await ListEncoders(jwt, AdminService, customerId);
  return encoderList;
};

export const fetchRerankers = async (jwt: string, AdminService: AdminServicePromiseClient, customerId: string) => {
  const { rerankerList } = await ListRerankers(jwt, AdminService, customerId);
  return rerankerList;
};

export const fetchAwsMpDetails = async (
  jwt: string,
  AdminService: AdminServicePromiseClient,
  customerId: string,
  isAwsMpCustomer: boolean
) => {
  if (!isAwsMpCustomer) return undefined;
  return await ReadAwsMpDetails(jwt, AdminService, customerId);
};
