import { isHttpResponseCodeError } from "../utils/isHttpResponseCodeError";
import { dataStoragePrimitiveProper } from "../utils/jargon";

type UploadSuccess = {
  status: "success";
};

type UploadError = {
  status: "error";
  error: any;
  code: any;
};

export type UploadStatus = UploadSuccess | UploadError;

export const generateUploadRequest = (file: File, customerId: string, corpusId: number) => {
  const formData = new FormData();

  formData.append("c", customerId);
  formData.append("o", corpusId.toString());

  // HTML file input, chosen by user
  formData.append("p", file.webkitRelativePath);
  formData.append("file", file);

  const request = new XMLHttpRequest();
  return {
    request,
    formData
  };
};

export const uploadFile = async (
  nextUpload:
    | {
        request: XMLHttpRequest;
        formData: FormData;
      }
    | undefined,
  uploadUrl: string,
  jwt: string,
  onComplete: (uploadStatus: UploadStatus) => void
) => {
  await new Promise((resolve) => {
    if (nextUpload && uploadUrl) {
      const { request } = nextUpload;

      const handleError = () => {
        const { responseText, status: statusCode } = request;
        const { error } = extractError(responseText, statusCode);

        onComplete({
          status: "error",
          error,
          code: error.code
        });
      };

      request.open("POST", uploadUrl);
      request.setRequestHeader("Authorization", `Bearer ${jwt}`);

      request.onload = () => {
        if (request.status !== 200) {
          handleError();
        } else {
          onComplete({ status: "success" });
        }
        resolve(undefined);
      };

      request.onerror = () => {
        handleError();
        resolve(undefined);
      };

      request.send(nextUpload.formData);
    }
  });
};

export const uploadSampleData = async (
  customerId: string,
  corpusId: number,
  uploadUrl: string,
  jwt: string,
  onComplete: (uploadStatus: UploadStatus) => void
) => {
  const response = await fetch("/files/black_hole.html", {
    method: "GET",
    headers: {
      Accept: "application/octet-stream"
    }
  });

  if (isHttpResponseCodeError(response.status)) {
    throw "Could not retrieve sample data.";
  }

  const data = await response.arrayBuffer();
  const blob = new Blob([data]);

  const formData = new FormData();

  formData.append("c", customerId);
  formData.append("o", corpusId.toString());
  formData.append("file", blob);

  uploadFile(
    {
      request: new XMLHttpRequest(),
      formData
    },
    uploadUrl,
    jwt,
    onComplete
  );
};

const extractError = (responseText: string, statusCode: number) => {
  try {
    const { httpCode: status, details } = JSON.parse(responseText);
    return {
      error: {
        msg: details ?? generateErrorMessage(status || statusCode),
        code: status ?? statusCode
      }
    };
  } catch (e) {
    return {
      error: {
        msg:
          generateErrorMessage(statusCode) ??
          responseText?.toString()?.substr(0, 100) + "..." ??
          "Error parsing server response",
        code: statusCode ?? ""
      }
    };
  }
};

export const generateErrorMessage = (code: number) => {
  switch (code) {
    case 0:
      return "Connection to the server was lost.";

    case 400:
      return "Bad request could not be processed.";

    case 401:
      return "Unauthorized for upload.";

    case 403:
      return "Unauthenticated. Please log in again.";

    case 405:
      return `${dataStoragePrimitiveProper} is disabled. Enable it to upload files`;

    case 409:
      return "Conflict: A different document with the same ID already exists.";

    case 415:
      return "Unsupported document type.";

    case 500:
      return "Internal server error.";

    case 504:
      return "Request timed out.";

    case 507:
      return "Insufficient storage to upload.";

    case 429:
      return "Too many requests. Try later.";

    default:
      return "";
  }
};
