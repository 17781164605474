import { RecoveryFlow, UpdateRecoveryFlowBody } from "@ory/client";
import { UserAuthCard } from "@ory/elements";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { orySdk } from "../../../utils/auth/orySdk";
import { VuiSpinner } from "@vectara/vectara-ui";
import logo from "../../../images/logo-vectara-dark.png";

// Ory Elements
// optional fontawesome icons
import "@ory/elements/assets/fa-brands.min.css";
import "@ory/elements/assets/fa-solid.min.css";
import "@ory/elements/assets/fontawesome.min.css";
// optional fonts
import "@ory/elements/assets/inter-font.css";
import "@ory/elements/assets/jetbrains-mono-font.css";
// required styles for Ory Elements
import "@ory/elements/style.css";
import { refreshOryJwt } from "../../../utils/auth/refreshJwt";
import { datadogRum } from "@datadog/browser-rum";

export default function SubmitRecoveryCode() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [flow, setFlow] = useState<RecoveryFlow | null>(null);

  useEffect(() => {
    const { state } = location;
    const flowId = searchParams.get("flow");

    if (!state?.flow && !flowId) {
      navigate("/requestResetPassword", { replace: true });
    }

    if (flowId) {
      getFlow(flowId);
    } else {
      setFlow(state?.flow);
    }
  }, []);

  const getFlow = async (flowId: string) => {
    try {
      const response = await orySdk.getRecoveryFlow({ id: flowId });
      if (response && response.status === 200) {
        setFlow(response.data);
      }
    } catch (e) {
      datadogRum.addError(e);
      console.log("getFlow failure: ", e);
      navigate("/login", { replace: true });
    }
  };

  const submitFlow = async (body: UpdateRecoveryFlowBody) => {
    // something unexpected went wrong and the flow was not set
    if (!flow) return navigate("/login", { replace: true });

    try {
      const response = await orySdk.updateRecoveryFlow({ flow: flow.id, updateRecoveryFlowBody: body });

      if (response && response.status === 200 && response.data.ui.messages) {
        const errorMessages = response.data.ui.messages.filter((msg) => msg.type === "error" && msg.id === 4060006);

        if (errorMessages.length > 0) {
          setFlow(response.data);
          // stay here in case of wrong code;
        } else {
          setFlow(response.data);
          await refreshOryJwt();
          navigate(`/setNewOryPassword?flow=${response.data.id}`);
        }
      }
    } catch (e: any) {
      datadogRum.addError(e);
      if (e?.response?.data?.redirect_browser_to) {
        const urlParams = new URLSearchParams(new URL(e.response.data.redirect_browser_to).search);
        await refreshOryJwt();
        navigate(`/setNewOryPassword?flow=${urlParams.get("flow")}`);
      } else {
        console.log("submit code failure: ", e);
        navigate("/login", { replace: true });
      }
    }
  };

  return flow ? (
    <div style={{ marginLeft: "auto", marginRight: "auto", maxWidth: "fit-content" }}>
      <UserAuthCard
        title="Reset Password"
        flowType={"recovery"}
        flow={flow}
        additionalProps={{
          loginURL: {
            handler: () => {
              navigate(
                {
                  pathname: "/login"
                },
                { replace: true }
              );
            }
          }
        }}
        onSubmit={({ body }) => submitFlow(body as UpdateRecoveryFlowBody)}
        cardImage={logo}
      />
    </div>
  ) : (
    <VuiSpinner />
  );
}
