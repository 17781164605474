import { createContext, useContext, ReactNode, useState } from "react";
import {
  PublicAdminServicePromiseClient,
  AdminServicePromiseClient,
  DocumentServicePromiseClient
} from "../generated_protos/services_grpc_web_pb";
import { ADMIN_BACKEND } from "../backendConfig";
import { ChatServicePromiseClient } from "../generated_protos/services_grpc_web_pb";

interface ApiContextType {
  PublicAdminService: PublicAdminServicePromiseClient;
  AdminService: AdminServicePromiseClient;
  DocumentService?: DocumentServicePromiseClient;
  ChatService?: ChatServicePromiseClient;
  configureServingUrl: (url: string) => void;
}

const ApiContext = createContext<ApiContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

const URL = ADMIN_BACKEND;

const PublicAdminService = new PublicAdminServicePromiseClient(URL);
const AdminService = new AdminServicePromiseClient(URL);

export const ApiContextProvider = ({ children }: Props) => {
  const [DocumentService, setDocumentService] = useState<DocumentServicePromiseClient | undefined>(undefined);
  const [ChatService, setChatService] = useState<ChatServicePromiseClient | undefined>(undefined);

  const configureServingUrl = (url: string) => {
    setDocumentService(new DocumentServicePromiseClient(url));
    setChatService(new ChatServicePromiseClient(url));
  };

  return (
    <ApiContext.Provider
      value={{ PublicAdminService, AdminService, DocumentService, ChatService, configureServingUrl }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export const useApiContext = () => {
  const context = useContext(ApiContext);
  if (context === undefined) {
    throw new Error("useApiContext must be used within a ApiContextProvider");
  }
  return context;
};
