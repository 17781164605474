import { Configuration, FrontendApi } from "@ory/client";
import { ORY_SDK_URL } from "../../backendConfig";

export const orySdk = new FrontendApi(
  new Configuration({
    basePath: ORY_SDK_URL,
    // we always want to include the cookies in each request
    // cookies are used for sessions and CSRF protection
    baseOptions: {
      withCredentials: true
    }
  })
);
