import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { extractAwsSubscriptionToken, extractIsInvitation } from "./utils/queryParams";
import { useUserContext } from "./contexts/UserContext";
import {
  getRedirectToSignupAws,
  removeRedirectToSignupAws,
  setAwsSubscriptionToken,
  setRedirectToSignupAws
} from "./utils/localStorage/awsMarketplace";
import { getAuthMechanism } from "./utils/localStorage/customer";
import { clearIsSideNavPinned } from "./utils/localStorage/layout";

export const useInitializeApp = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    hasInitiallyAuthenticated,
    isAuthenticating,
    isAuthenticated,
    authenticate,
    deauthenticate,
    acceptInvitation
  } = useUserContext();

  // Authenticate the user when the app first loads or when the user
  // deauthenticates.
  useEffect(() => {
    // If the user has been invited to join an account, skip auth so they
    // can log in.
    if (extractIsInvitation(searchParams)) {
      acceptInvitation();
      return;
    }

    if (!hasInitiallyAuthenticated && !isAuthenticating) {
      authenticate();
      return;
    }

    // Reset any local storage state that shouldn't be persisted from one user to the next.
    if (hasInitiallyAuthenticated && !isAuthenticated) {
      clearIsSideNavPinned();
    }

    // This case handles the AWS MP subscription flow:
    //   1. If there's an AWS subscription token in the query params, cache it.
    //   2. If the user is authenticated, deauth them and redirect to /signupAws.
    //   3. If the user was deauthed through Google SSO, their redirect is special.
    if (hasInitiallyAuthenticated) {
      // This is the special redirect for Google SSO users.
      if (!isAuthenticated && getRedirectToSignupAws()) {
        removeRedirectToSignupAws();

        // This is a hack to work around some weird race condition.
        // If we remove this timeout but throttle the network to "Fast 3G",
        // the redirect works fine. Without throttling or this timeout,
        // the app ends up at /login.
        setTimeout(() => {
          navigate("/signupAws", {
            replace: true
          });
        }, 10);
        return;
      }

      // If the user has been redirected here after clicking the "Subscribe"
      // button in AWS Marketplace, deauthenticate them so they can create an
      // account.
      const awsSubscriptionToken = extractAwsSubscriptionToken(window.location.search);

      if (awsSubscriptionToken) {
        setAwsSubscriptionToken(awsSubscriptionToken);

        if (isAuthenticated) {
          // If logged in via Google SSO, this will automatically redirect to /login.
          // So for AWS MP subscriptions, we need to set a flag for redirecting to
          // the AWS MP login page.
          if (getAuthMechanism() === "googleSso") {
            setRedirectToSignupAws();
          }

          deauthenticate("", "/signupAws");
          return;
        }

        // This redirect is for non-Google SSO users.
        // Cache token in local storage and remove from query params.
        navigate("/signupAws", {
          replace: true
        });
      }
    }
  }, [searchParams, acceptInvitation, authenticate, isAuthenticating, hasInitiallyAuthenticated, isAuthenticated]);
};
