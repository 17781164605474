import { QuestionSchema } from "./types";

export const surveySchema: Array<QuestionSchema> = [
  {
    id: "role",
    question: "What's your role at your organization?",
    type: "radioCustom",
    options: [
      {
        value: "software_developer",
        label: "Software developer"
      },
      {
        value: "ml_developer",
        label: "Machine learning engineer"
      },
      {
        value: "product_manager",
        label: "Product manager"
      },
      {
        value: "technical_writer",
        label: "Technical writer"
      },
      {
        value: "analyst_researcher",
        label: "Analyst or researcher"
      },
      {
        value: "sales_marketing",
        label: "Sales or marketing"
      },
      {
        value: "business_operations",
        label: "Business operations"
      },
      {
        value: "student",
        label: "Student"
      },
      {
        value: "other",
        type: "custom",
        label: "Something else"
      }
    ]
  },
  {
    id: "code_level",
    question: "How do you want to build with Vectara?",
    type: "radio",
    options: [
      {
        value: "code",
        label: "With code"
      },
      {
        value: "minimal_code",
        label: "With minimal code"
      },
      {
        value: "no_code",
        label: "With no code at all"
      }
    ]
  },
  {
    id: "org_size",
    question: "How large is your organization?",
    type: "radio",
    options: [
      {
        value: "1",
        label: "Only me"
      },
      {
        value: "2_to_10",
        label: "2 to 10 people"
      },
      {
        value: "11_to_100",
        label: "11 to 100 people"
      },
      {
        value: "101_to_1000",
        label: "101 to 1,000 people"
      },
      {
        value: "over_1000",
        label: "Over 1,000 people"
      }
    ]
  },
  {
    id: "industry",
    question: "Almost done. How would you describe your organization's focus?",
    type: "radioCustom",
    options: [
      {
        value: "personal",
        label: "I'm building something for my personal use or for school, not as part of a business"
      },
      {
        value: "high_tech",
        label: "High tech"
      },
      {
        value: "retail_ecommerce",
        label: "Retail or e-commerce"
      },
      {
        value: "health_care",
        label: "Health care"
      },
      {
        value: "legal",
        label: "Legal"
      },
      {
        value: "financial",
        label: "Financial services"
      },
      {
        value: "other",
        type: "custom",
        label: "Something else"
      }
    ]
  },
  {
    id: "use_case",
    question: "Last question! What are you building with Vectara?",
    type: "radioCustom",
    options: [
      {
        value: "chat",
        label: "Chat bot"
      },
      {
        value: "question_and_answer",
        label: "Question and answer system"
      },
      {
        value: "semantic_search",
        label: "Semantic application search"
      },
      {
        value: "research_analysis",
        label: "Research and analysis tool"
      },
      {
        value: "other",
        type: "custom",
        label: "Something else"
      }
    ]
  }
];
