import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import duration from "dayjs/plugin/duration";

import { useEffect, useState } from "react";

dayjs.extend(relativeTime);
dayjs.extend(duration);

const ONE_DAY_IN_MS = 86400000;
const ONE_HOUR_IN_S = 3600;

type CountdownParts = {
  hours: number;
  minutes: number;
  seconds: number;
};

/**
 * Generates the expiration copy for the trial plan.
 * If the plan ends in more than a day, shows the text e.g. "Trial ends in 2 days"
 * If the plan ends in less than a day, returns a countdown.
 */
export const useTrialPlanReminder = (isExpired: boolean, expiration: number) => {
  const [trialTimeRemaining, setTrialTimeRemaining] = useState<number>(expiration - Date.now());
  const planDidExpire = trialTimeRemaining <= 0;

  // Activate countdown if the trial ends in less than one day.
  const shouldCountdown = trialTimeRemaining < ONE_DAY_IN_MS;
  const [countdownParts, setCountdownParts] = useState<CountdownParts>(computeCountdownParts(trialTimeRemaining));

  // Refresh the time remaining when the user focuses back on the window.
  // This ensures they'll have the most up-to-date time remaining after possibly
  // having been away from the page for a while.
  useEffect(() => {
    const refreshTrialTimeRemaining = () => setTrialTimeRemaining(expiration - Date.now());
    window.addEventListener("focus", refreshTrialTimeRemaining);

    return () => window.removeEventListener("focus", refreshTrialTimeRemaining);
  }, []);

  // Start the countdown interval if we've determine the countdown should begin.
  // i.e. the remaining trial time is less than one day.
  useEffect(() => {
    if (!shouldCountdown) return;

    setCountdownParts(computeCountdownParts(trialTimeRemaining));

    const interval = setInterval(() => {
      const updatedTrialTimeRemaining = expiration - Date.now();

      if (updatedTrialTimeRemaining <= 0) {
        clearInterval(interval);
      }

      setCountdownParts(computeCountdownParts(updatedTrialTimeRemaining));
      setTrialTimeRemaining(updatedTrialTimeRemaining);
    }, 1000);
    return () => clearInterval(interval);
  }, [shouldCountdown]);

  if (isExpired || planDidExpire) {
    return "It’s time to purchase a plan.";
  }

  if (shouldCountdown) {
    const { hours, minutes, seconds } = countdownParts;
    const paddedHours = String(hours).padStart(2, "0");
    const paddingMinutes = String(minutes).padStart(2, "0");
    const paddedSeconds = String(seconds).padStart(2, "0");

    return `Trial ends in ${paddedHours}:${paddingMinutes}:${paddedSeconds}`;
  }

  return `Trial ends in ${dayjs(expiration).fromNow(true)}`;
};

const computeCountdownParts = (time: number): CountdownParts => {
  const seconds = dayjs.duration(time).asSeconds();
  return {
    hours: Math.floor(seconds / ONE_HOUR_IN_S),
    minutes: Math.floor((seconds % ONE_HOUR_IN_S) / 60),
    seconds: Math.floor((seconds % ONE_HOUR_IN_S) % 60)
  };
};
