import { get } from "lodash";
import { ReadUsageStatsResponse } from "../../generated_protos/admin/admin_stats_pb";
import { PricingPlan } from "../../types/billing";
import { dataStoragePrimitives } from "../../utils/jargon";

// Casting to number and then casting to string will trim off
// trailing zeros after the decimal point.
export const bytesToMb = (bytes: number) => humanizeNumber(Number((bytes / 1048576).toFixed(2)));
export const humanizeNumber = (value: number) => value.toLocaleString("en-US");

const resourceTypes = [
  "dataStorage",
  "dataIngest",
  "queries",
  "generativeRequests",
  "generativeModelStatsList",
  "dataStoragePrimitives",
  "users"
] as const;

export type ResourceType = (typeof resourceTypes)[number];

type ResourceConfig = {
  usageStatsAmountPath?: string;
  usageStatsAmountProvider?: (usageStats: ReadUsageStatsResponse.AsObject) => number;
  usageStatsBaseLimitPath?: string;
  planBaseLimitPath?: string;
  planBundleIncrementPath?: string;
  humanize: (value: number) => string;
  unit: string;
};

export type ResourceAnalysis = {
  bundleIncrement?: string;
  amount?: string;
  baseLimit?: string;
  percentage: number;
  unit: string;
  isOverLimit: boolean;
  extra?: {
    limitIncrease: string;
    totalLimit: string;
  };
};

const resourceToConfigMap: Record<ResourceType, ResourceConfig> = {
  dataStorage: {
    usageStatsAmountPath: "storageStats.actualCount",
    usageStatsBaseLimitPath: "storageStats.allowedCount",
    planBundleIncrementPath: "queryStorageIngestBundle.storageBytes",
    humanize: bytesToMb,
    unit: "MB"
  },
  dataIngest: {
    usageStatsAmountProvider: ({ indexStatsList }: ReadUsageStatsResponse.AsObject) => {
      return indexStatsList?.map((x) => x.actualCount).reduce((prev, next) => prev + next, 0);
    },
    planBaseLimitPath: "includedIndexing.amount",
    planBundleIncrementPath: "queryStorageIngestBundle.ingestBytes",
    humanize: bytesToMb,
    unit: "MB"
  },
  queries: {
    usageStatsAmountProvider: ({ queryStatsList }: ReadUsageStatsResponse.AsObject) => {
      return queryStatsList?.map((x) => x.actualCount).reduce((prev, next) => prev + next, 0);
    },
    planBaseLimitPath: "includedQueries.amount",
    planBundleIncrementPath: "queryStorageIngestBundle.queries",
    humanize: humanizeNumber,
    unit: "queries"
  },

  // This is now deprecated in favor of generativeModelStatsList
  generativeRequests: {
    usageStatsAmountPath: "generativeStats.actualCount",
    planBaseLimitPath: "includedSummarizations.amount",
    planBundleIncrementPath: "queryStorageIngestBundle.summarizations",
    humanize: humanizeNumber,
    unit: "requests"
  },
  generativeModelStatsList: {
    usageStatsAmountProvider: ({ queryStatsList }: ReadUsageStatsResponse.AsObject) => {
      return queryStatsList?.map((x) => x.actualCount).reduce((prev, next) => prev + next, 0);
    },
    planBaseLimitPath: "includedSummarizations.amount",
    planBundleIncrementPath: "queryStorageIngestBundle.summarizations",
    humanize: humanizeNumber,
    unit: "requests"
  },
  dataStoragePrimitives: {
    usageStatsAmountPath: "corporaStats.actualCount",
    usageStatsBaseLimitPath: "corporaStats.allowedCount",
    humanize: humanizeNumber,
    unit: dataStoragePrimitives
  },
  users: {
    usageStatsAmountPath: "usersStats.actualCount",
    usageStatsBaseLimitPath: "usersStats.allowedCount",
    humanize: humanizeNumber,
    unit: "users"
  }
};

export const analyzeUsage = (
  bundlesQuantity: number,
  currentPlan?: PricingPlan,
  usageStats?: ReadUsageStatsResponse.AsObject
) => {
  if (!currentPlan || !usageStats) return undefined;

  return resourceTypes.reduce((acc, resourceType) => {
    // For the new generativeModelStatsList data returned by the API,
    // we use custom parsing logic since usage stats are nested can be separated by model
    if (resourceType === "generativeModelStatsList") {
      acc["generativeRequestsByModel"] = {};
      // Get GPT-4 stats.
      // Once we 1-2 more of these, we'll work on DRYing up the code.
      const gpt4StatsList = usageStats.generativeModelStatsList.find((stats) => stats.modelName === "gpt-4");

      // Only proceed with computations and add to usage stats if GPT4 stats are returned by the API.
      if (gpt4StatsList) {
        const gpt4Amount = `${gpt4StatsList ? gpt4StatsList.usageStats?.actualCount : 0}`;
        const gpt4Limit = gpt4StatsList?.usageStats?.allowedCount ?? 0;
        const gpt4Percentage = gpt4Limit ? parseInt(gpt4Amount) / gpt4Limit : 0;

        acc["generativeRequestsByModel"]["gpt4"] = {
          bundleIncrement: undefined,
          amount: gpt4Amount,
          baseLimit: humanizeNumber(gpt4Limit),
          percentage: gpt4Percentage * 100,
          isOverLimit: parseInt(gpt4Amount) > gpt4Limit,
          unit: "requests"
        };
      }

      // Get stats for everything else
      const etcStatsList = usageStats.generativeModelStatsList.find((stats) => stats.modelName === "*");

      // Only proceed with computations and add to usage stats if these stats were returned by the API.
      if (etcStatsList) {
        const etcBundleIncrement = get(currentPlan, "queryStorageIngestBundle.summarizations") as number;
        const etcAmount = etcStatsList?.usageStats?.actualCount ?? 0;
        const etcLimit = etcStatsList?.usageStats?.allowedCount ?? 0;
        let etcIsOverLimit;
        let etcPercentage;
        let etcExtra;

        if (etcBundleIncrement !== undefined) {
          const limitIncrease = bundlesQuantity * etcBundleIncrement;
          const totalLimit = etcLimit + limitIncrease;

          etcIsOverLimit = etcAmount > totalLimit;
          etcPercentage = etcAmount / totalLimit;

          etcExtra = {
            limitIncrease: humanizeNumber(limitIncrease),
            totalLimit: humanizeNumber(totalLimit)
          };
        } else {
          etcIsOverLimit = etcAmount > etcLimit;
          etcPercentage = etcAmount / etcLimit;
        }

        acc["generativeRequestsByModel"]["etc"] = {
          bundleIncrement: etcBundleIncrement !== undefined ? humanizeNumber(etcBundleIncrement) : undefined,
          amount: humanizeNumber(etcAmount),
          baseLimit: humanizeNumber(etcLimit),
          percentage: etcPercentage * 100,
          isOverLimit: etcIsOverLimit,
          unit: "requests",
          extra: etcExtra
        };
      }

      return acc;
    }

    const {
      usageStatsAmountPath,
      usageStatsAmountProvider,
      usageStatsBaseLimitPath,
      planBaseLimitPath,
      planBundleIncrementPath,
      humanize,
      unit
    } = resourceToConfigMap[resourceType];

    // Sometimes the back-end takes awhile to initialize these values, so we can safely default to 0 if they're undefined.
    const amount =
      (usageStatsAmountPath ? get(usageStats, usageStatsAmountPath) : usageStatsAmountProvider!(usageStats)) ?? 0;

    const baseLimit = (
      usageStatsBaseLimitPath ? get(usageStats, usageStatsBaseLimitPath) : get(currentPlan, planBaseLimitPath!)
    ) as number;

    const bundleIncrement = planBundleIncrementPath ? (get(currentPlan, planBundleIncrementPath) as number) : undefined;

    let isOverLimit;
    let percentage;
    let extra;

    if (bundleIncrement !== undefined) {
      const limitIncrease = bundlesQuantity * bundleIncrement;
      const totalLimit = baseLimit + limitIncrease;

      isOverLimit = amount > totalLimit;
      percentage = amount / totalLimit;

      extra = {
        limitIncrease: humanize(limitIncrease),
        totalLimit: humanize(totalLimit)
      };
    } else {
      isOverLimit = amount > baseLimit;
      percentage = amount / baseLimit;
    }

    // A resource analysis is only useful if there's a limit.
    if (baseLimit) {
      acc[resourceType] = {
        bundleIncrement: bundleIncrement !== undefined ? humanize(bundleIncrement) : undefined,
        amount: amount !== undefined ? humanize(amount) : undefined,
        baseLimit: humanize(baseLimit),
        // Guarding against NaN just in case.
        percentage: isNaN(percentage) ? 0 : percentage * 100,
        isOverLimit,
        unit,
        extra
      };
    }

    return acc;
  }, {} as UsageAnalysis);
};

export type UsageAnalysis = {
  dataStorage: ResourceAnalysis;
  dataIngest: ResourceAnalysis;
  queries: ResourceAnalysis;
  generativeRequests: ResourceAnalysis;
  generativeRequestsByModel: {
    gpt4?: ResourceAnalysis;
    etc?: ResourceAnalysis;
  };
  dataStoragePrimitives: ResourceAnalysis;
  users: ResourceAnalysis;
};
