import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { VuiCard, VuiFlexContainer, VuiFlexItem, VuiLinkInternal, VuiText, VuiTitle } from "@vectara/vectara-ui";
import { extractCustomerId, extractUserName } from "../../../utils/queryParams";
import logo from "../../../images/logo-vectara-dark.png";

export const CheckEmailToResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const [customerId, setCustomerId] = useState<string>();
  const [userName, setUserName] = useState<string>();

  useEffect(() => {
    setCustomerId(extractCustomerId(searchParams) ?? undefined);
    setUserName(extractUserName(searchParams) ?? undefined);
  }, [searchParams]);

  return (
    <div>
      <VuiCard
        padding="m"
        className="loggedOutPageForm"
        header={
          <VuiFlexContainer
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing="s"
            className="loggedOutFormHeader"
          >
            <VuiFlexItem grow={false} shrink={false}>
              <VuiLinkInternal href="/">
                <img src={logo} alt="Vectara logo" className="loggedOutFormLogo" />
              </VuiLinkInternal>
            </VuiFlexItem>

            <VuiFlexItem grow={1} shrink={1}>
              <VuiTitle size="s" align="center" data-testid="setNewPasswordTitle">
                <h1>Check your email to change your password</h1>
              </VuiTitle>
            </VuiFlexItem>
          </VuiFlexContainer>
        }
        body={
          <>
            <VuiText>
              <p>
                We sent you an email with instructions on how to change your password, for user{" "}
                <strong>{userName}</strong> and customer ID <strong>{customerId}</strong>.
              </p>

              <p>
                When you're done changing your password,{" "}
                <VuiLinkInternal href="/login" data-testid="logInFormLink">
                  log in
                </VuiLinkInternal>
                .
              </p>
            </VuiText>
          </>
        }
      />
    </div>
  );
};
