import {
  ComputeAccountSizeRequest,
  ComputeAccountSizeResponse,
  ReadAccountSizeRequest,
  ReadAccountSizeResponse
} from "../generated_protos/admin/admin_account_pb";
import { AdminServicePromiseClient } from "../generated_protos/services_grpc_web_pb";

/**
 * ComputeAccountSize computes the total size of the account.
 * @return {object} returns object which consist of list of account sizes across clusters.
 */
export const ComputeAccountSize = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string
): Promise<ComputeAccountSizeResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("JWT token is invalid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ComputeAccountSizeRequest();
    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };
    adminService
      .computeAccountSize(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from: computeAccountSize");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

/**
 * ReadAccountSize reads the total size of the account without actually computing it.
 * @return {object} returns object which consist of list of account sizes across clusters.
 */
export const ReadAccountSize = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: number
): Promise<ReadAccountSizeResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("JWT token is invalid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ReadAccountSizeRequest();
    const args = {
      "customer-id": String(customerId),
      authorization: "Bearer " + jwt
    };
    adminService
      .readAccountSize(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from: readAccountSize");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};
