/* This code always gets called from highlightText function. 
so this function is grouping all the occurences and returning the first one. */

import { isNullOrWhitespace } from "./isNullOrWhitespace";

export const escapeRegExpChars = (text: string) => {
  return text.replace(/([.*+?^=!:${}()|[\]/\\])/g, "\\$1");
};

// validates password

function hasNumber(myString: string) {
  return /\d/.test(myString);
}

export function validatePassword(password: string) {
  return !isNullOrWhitespace(password) && hasNumber(password) && password.length >= 8;
}

/**
 * Converts extra whitespace to one in both passed strings and does a case-insensitive comparison.
 * @param {first} arg First string.
 * @param {second} arg Second string.
 */
export const equalForDelete = (first: string, second?: string) => {
  if (first !== undefined && second === undefined) return false;

  return first?.replace(/\s\s+/g, " ").trim().toUpperCase() === second?.replace(/\s\s+/g, " ").trim().toUpperCase();
};
