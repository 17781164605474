type GenerationPreset = { llm_name?: string };

export const extractLlms = (presets: GenerationPreset[] = []) => {
  const llmNames = presets.reduce((acc, preset) => {
    if (preset.llm_name) {
      acc[preset.llm_name] = true;
    }
    return acc;
  }, {} as Record<string, boolean>);

  return Object.keys(llmNames);
};
