import { useState } from "react";
import { QuestionType } from "./types";
import {
  VuiSpacer,
  VuiTitle,
  RadioButtonConfig,
  VuiSuperRadioGroup,
  VuiButtonPrimary,
  VuiFlexContainer,
  VuiButtonTertiary,
  VuiFlexItem,
  VuiTextInput
} from "@vectara/vectara-ui";

export const Question = ({
  schema,
  answer,
  onSelectAnswer,
  onChangeCustomerAnswer,
  onGoToNextQuestion,
  onGoToPreviousQuestion,
  onComplete
}: QuestionType) => {
  const { question, options } = schema;

  const [optionsState, setOptionsState] = useState<RadioButtonConfig<string>[]>(
    options.map(({ value, label }) => ({
      label,
      value,
      checked: answer.selection === value,
      "data-testid": value
    }))
  );

  const onSelectOption = (value: string) => {
    setOptionsState(
      optionsState.map((option) => ({
        ...option,
        checked: option.value === value
      }))
    );

    const selectedOption = options.find((option) => option.value === value);

    onSelectAnswer(selectedOption);
  };

  const selectedOption = options.find(
    (option) => option.value === optionsState.find((option) => option.checked)?.value
  );

  const isAnswerComplete = selectedOption && (selectedOption.type !== "custom" || answer.customAnswer);

  return (
    <>
      <VuiTitle size="m">
        <h2>{question}</h2>
      </VuiTitle>

      <VuiSpacer size="l" />

      <VuiSuperRadioGroup group={optionsState} onChange={onSelectOption} />

      {selectedOption?.type === "custom" && (
        <>
          <VuiSpacer size="s" />

          <VuiTextInput
            size="l"
            placeholder="Please do tell"
            fullWidth
            value={answer.customAnswer}
            onChange={(e) => onChangeCustomerAnswer?.(e.target.value)}
            onSubmit={onGoToNextQuestion}
          />
        </>
      )}

      <VuiSpacer size="m" />

      <VuiFlexContainer justifyContent="spaceBetween">
        <VuiFlexItem>
          {onGoToPreviousQuestion && (
            <VuiButtonTertiary color="primary" onClick={onGoToPreviousQuestion}>
              Back
            </VuiButtonTertiary>
          )}
        </VuiFlexItem>

        <VuiFlexItem>
          {onGoToNextQuestion && (
            <VuiButtonPrimary color="primary" isDisabled={!isAnswerComplete} onClick={onGoToNextQuestion}>
              Next
            </VuiButtonPrimary>
          )}

          {onComplete && (
            <VuiButtonPrimary color="primary" isDisabled={!isAnswerComplete} onClick={onComplete}>
              Submit
            </VuiButtonPrimary>
          )}
        </VuiFlexItem>
      </VuiFlexContainer>
    </>
  );
};
