// source: currency.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.com.vectara.currency.CurrencyAmount', null, global);
goog.exportSymbol('proto.com.vectara.currency.CurrencyCode', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.currency.CurrencyAmount = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.currency.CurrencyAmount, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.currency.CurrencyAmount.displayName = 'proto.com.vectara.currency.CurrencyAmount';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.currency.CurrencyAmount.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.currency.CurrencyAmount.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.currency.CurrencyAmount} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.currency.CurrencyAmount.toObject = function(includeInstance, msg) {
  var f, obj = {
    amount: jspb.Message.getFieldWithDefault(msg, 1, 0),
    currency: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.currency.CurrencyAmount}
 */
proto.com.vectara.currency.CurrencyAmount.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.currency.CurrencyAmount;
  return proto.com.vectara.currency.CurrencyAmount.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.currency.CurrencyAmount} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.currency.CurrencyAmount}
 */
proto.com.vectara.currency.CurrencyAmount.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setAmount(value);
      break;
    case 2:
      var value = /** @type {!proto.com.vectara.currency.CurrencyCode} */ (reader.readEnum());
      msg.setCurrency(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.currency.CurrencyAmount.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.currency.CurrencyAmount.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.currency.CurrencyAmount} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.currency.CurrencyAmount.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAmount();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getCurrency();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional int64 amount = 1;
 * @return {number}
 */
proto.com.vectara.currency.CurrencyAmount.prototype.getAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.currency.CurrencyAmount} returns this
 */
proto.com.vectara.currency.CurrencyAmount.prototype.setAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional CurrencyCode currency = 2;
 * @return {!proto.com.vectara.currency.CurrencyCode}
 */
proto.com.vectara.currency.CurrencyAmount.prototype.getCurrency = function() {
  return /** @type {!proto.com.vectara.currency.CurrencyCode} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.vectara.currency.CurrencyCode} value
 * @return {!proto.com.vectara.currency.CurrencyAmount} returns this
 */
proto.com.vectara.currency.CurrencyAmount.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.com.vectara.currency.CurrencyCode = {
  UNK: 0,
  AED: 784,
  AFN: 971,
  ALL: 8,
  AMD: 51,
  ANG: 532,
  AOA: 973,
  ARS: 26,
  AUD: 30,
  AWG: 533,
  AZN: 944,
  BAM: 977,
  BBD: 52,
  BDT: 50,
  BGN: 975,
  BHD: 48,
  BIF: 108,
  BMD: 60,
  BND: 96,
  BOB: 68,
  BOV: 984,
  BRL: 986,
  BSD: 44,
  BTN: 64,
  BWP: 72,
  BYN: 933,
  BYR: 974,
  BZD: 84,
  CAD: 124,
  CDF: 976,
  CHE: 947,
  CHF: 756,
  CHW: 948,
  CLF: 990,
  CLP: 152,
  CNY: 156,
  COP: 170,
  COU: 970,
  CRC: 188,
  CUC: 931,
  CUP: 192,
  CVE: 132,
  CZK: 203,
  DJF: 262,
  DKK: 208,
  DOP: 214,
  DZD: 12,
  EGP: 818,
  ERN: 232,
  ETB: 230,
  EUR: 978,
  FJD: 242,
  FKP: 238,
  GBP: 826,
  GEL: 981,
  GHS: 936,
  GIP: 292,
  GMD: 270,
  GNF: 324,
  GTQ: 320,
  GYD: 328,
  HKD: 344,
  HNL: 340,
  HRK: 191,
  HTG: 332,
  HUF: 348,
  IDR: 360,
  ILS: 376,
  INR: 356,
  IQD: 368,
  IRR: 364,
  ISK: 352,
  JMD: 388,
  JOD: 400,
  JPY: 392,
  KES: 404,
  KGS: 417,
  KHR: 116,
  KMF: 174,
  KPW: 408,
  KRW: 410,
  KWD: 414,
  KYD: 136,
  KZT: 398,
  LAK: 418,
  LBP: 422,
  LKR: 144,
  LRD: 430,
  LSL: 426,
  LYD: 434,
  MAD: 504,
  MDL: 498,
  MGA: 969,
  MKD: 807,
  MMK: 104,
  MNT: 496,
  MOP: 446,
  MRO: 478,
  MUR: 480,
  MVR: 462,
  MWK: 454,
  MXN: 484,
  MXV: 979,
  MYR: 458,
  MZN: 943,
  NAD: 516,
  NGN: 566,
  NIO: 558,
  NOK: 578,
  NPR: 524,
  NZD: 554,
  OMR: 512,
  PAB: 590,
  PEN: 604,
  PGK: 598,
  PHP: 608,
  PKR: 586,
  PLN: 985,
  PYG: 600,
  QAR: 634,
  RON: 946,
  RSD: 941,
  RUB: 643,
  RWF: 646,
  SAR: 682,
  SBD: 90,
  SCR: 690,
  SDG: 938,
  SEK: 752,
  SGD: 702,
  SHP: 654,
  SLL: 694,
  SOS: 706,
  SRD: 968,
  SSP: 728,
  STD: 678,
  SVC: 222,
  SYP: 760,
  SZL: 748,
  THB: 764,
  TJS: 972,
  TMT: 934,
  TND: 788,
  TOP: 776,
  TRY: 949,
  TTD: 780,
  TWD: 901,
  TZS: 834,
  UAH: 980,
  UGX: 800,
  USD: 840,
  USN: 997,
  UYI: 940,
  UYU: 858,
  UZS: 860,
  VEF: 937,
  VND: 704,
  VUV: 548,
  WST: 882,
  XAF: 950,
  XAG: 961,
  XAU: 959,
  XBA: 955,
  XBB: 956,
  XBC: 957,
  XBD: 958,
  XCD: 951,
  XDR: 960,
  XOF: 952,
  XPD: 964,
  XPF: 953,
  XPT: 962,
  XSU: 994,
  XTS: 963,
  XUA: 965,
  XXX: 999,
  YER: 886,
  ZAR: 710,
  ZMW: 967,
  ZWL: 932
};

goog.object.extend(exports, proto.com.vectara.currency);
