import { createContext, useContext, ReactNode } from "react";
import { StatusCode } from "../generated_protos/status_pb";
import { usePlanContext } from "./PlanContext";

const INSUFFICIENT_RIGHTS = "Unauthorized: Please contact your account owner or billing administrator to upgrade.";
const UPGRADED_TO_MAX = "Contact sales@vectara.com to upgrade to a custom plan.";

interface UpgradeContextType {
  upgradeDisabled: boolean;
  upgradeDisabledReason: UpgradeDisabledReason;
}

type UpgradeDisabledReason = typeof INSUFFICIENT_RIGHTS | typeof UPGRADED_TO_MAX | undefined;

const UpgradeContext = createContext<UpgradeContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const UpgradeContextProvider = ({ children }: Props) => {
  const { scalePlanId, currentPlanId, currentPlanCode } = usePlanContext();

  let upgradeDisabled = false;
  let upgradeDisabledReason: UpgradeDisabledReason = undefined;

  if (currentPlanCode !== StatusCode.OK) {
    upgradeDisabled = true;
    upgradeDisabledReason = INSUFFICIENT_RIGHTS;
  } else if (currentPlanId === scalePlanId) {
    upgradeDisabled = true;
    upgradeDisabledReason = UPGRADED_TO_MAX;
  }

  return (
    <UpgradeContext.Provider value={{ upgradeDisabled, upgradeDisabledReason }}>{children}</UpgradeContext.Provider>
  );
};

export const useUpgradeContext = () => {
  const context = useContext(UpgradeContext);
  if (context === undefined) {
    throw new Error("useUpgradeContext must be used within a UpgradeContextProvider");
  }
  return context;
};
