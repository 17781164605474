import { loadStripe } from "@stripe/stripe-js";

const env = process.env.REACT_APP_ENV;
const envNames = {
  dev: "dev",
  staging: "staging",
  prod: "prod",
  sandbox: "sandbox"
};

// If the REST serving URL has been defined in env vars, use that.
// Otherwise, default to legacy behavior.
const getRestServingUrl = () => {
  if (process.env.REACT_APP_REST_SERVING_URL) {
    return process.env.REACT_APP_REST_SERVING_URL;
  }

  return IS_PROD ? "https://api.vectara.io:443" : " https://api.vectara.dev:443";
};

export const BILLING_PLAN_ID = `${process.env.REACT_APP_BILLING_PLAN_ID}`;
export const ADMIN_BACKEND = `${process.env.REACT_APP_API_ENDPOINT}`;
export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY!);
export const IS_PROD = env === envNames.prod;
export const protocol = `${process.env.REACT_APP_PROTOCOL}`;
export const ports = process.env.REACT_APP_SERVICES_PORT ? JSON.parse(process.env.REACT_APP_SERVICES_PORT) : {};
export const restServingUrl = getRestServingUrl();

// Auth
type AuthProvider = "ory" | "cognito";
export const AUTH_PROVIDER = process.env.REACT_APP_AUTH_PROVIDER as AuthProvider;
export const ORY_SDK_URL = process.env.REACT_APP_ORY_SDK_URL;
export const ORY_POOL_PREFIX = "ory:";
export const snowUrl = process.env.REACT_APP_SNOW_URL;

// Block operation of the app if auth has been misconfigured.
// This is mostly to prevent us from messing it when developing locally.
export const IS_AUTH_CONFIGURED =
  AUTH_PROVIDER === "cognito" ||
  (AUTH_PROVIDER === "ory" && ORY_SDK_URL && process.env.REACT_APP_ORY_JWT_TEMPLATE_NAME);
