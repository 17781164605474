import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";
import { App } from "./App";

const rootEl = document.getElementById("root");

// A shim to override Google SSO referrer.
// If the referrer is Google SSO, we override it with our current location. This ensures that the original GTM session is preserved.
// For more info on how we are overriding document.referrer, see the Mozilla Docs:
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/defineProperty
// For more info on how social login breaks analytics sessions, see:
// https://luigi7up.medium.com/your-social-login-is-f-up-your-conversions-in-google-ads-and-google-analytics-12165ae2f708
const originalReferrer = document.referrer;
Object.defineProperty(document, "referrer", {
  get: function () {
    const ssoReferrers = ["https://accounts.google.com", "https://accounts.youtube.com"];
    const isSsoReferrer = ssoReferrers.findIndex((referrer) => originalReferrer.match(referrer)) !== -1;
    return isSsoReferrer ? document.location.origin : originalReferrer;
  }
});

if (rootEl) {
  const root = createRoot(rootEl);

  root.render(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>
  );
}
