import {
  SetClientDataRequest,
  GetClientDataRequest,
  KeyValue,
  ClientDataKey
} from "../generated_protos/admin/admin_account_pb";
import { AdminServicePromiseClient } from "../generated_protos/services_grpc_web_pb";
import { Status } from "../generated_protos/status_pb";

export const SetClientData = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string,
  key: ClientDataKey,
  data: string
) => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }

    const setClientDataRequest = new SetClientDataRequest();

    const keyValue = new KeyValue();
    keyValue.setKey(key);
    keyValue.setData(data);
    setClientDataRequest.getRequestList().push(keyValue);

    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };

    adminService
      .setClientData(setClientDataRequest, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("invalid response from gRPC call: setClientData");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};

export const GetClientData = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string,
  key: ClientDataKey
): Promise<{ data: string | undefined; status: Status.AsObject | undefined }> => {
  const getClientDataRequest = new GetClientDataRequest();
  getClientDataRequest.getKeyList().push(key);

  const args = {
    "customer-id": customerId,
    authorization: "Bearer " + jwt
  };

  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("Invalid parameters. JWTToken must be valid");
      return;
    }

    adminService
      .getClientData(getClientDataRequest, args)
      .then((resp) => {
        if (!resp) {
          reject("Invalid response from gRPC call: getClientData");
          return;
        }

        const respObj = resp.toObject();
        if (respObj?.statusordataList === undefined) {
          reject("Invalid response from gRPC call: getClientData");
          return;
        }

        if (respObj.statusordataList[0]) {
          resolve({
            data: respObj.statusordataList[0].data,
            status: respObj.statusordataList[0].status
          });
        } else {
          resolve({ data: undefined, status: undefined });
        }
      })
      .catch((e) => reject(e));
  });
};

export const DeleteClientData = () => {
  return [];
};
