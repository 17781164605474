export const formattedDate = () => {
  const d = new Date();
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
  };
  return Intl.DateTimeFormat(navigator.language, options).format(d);
};
