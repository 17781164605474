import { CustomerRole, CorpusRole } from "../../generated_protos/admin/admin_security_pb";
import { CURRENCIES } from "../../utils/currencies";
import { PricingPlanPeriod } from "../../types/billing";
import { dataStoragePrimitiveProper } from "../../utils/jargon";

export const dataStoragePrimitiveAdmin = `${dataStoragePrimitiveProper} Admin`;
const CUSTOMER_ROLE_LABELS = ["Account Admin", dataStoragePrimitiveAdmin, "Billing Admin", "Account Owner"] as const;
export type CustomerRoleLabel = (typeof CUSTOMER_ROLE_LABELS)[number];

export const getCustomerRoleFromRole = (role: CustomerRoleLabel): CustomerRole | undefined => {
  if (role === "Account Admin") {
    return CustomerRole.CUSTOMERROLE_ADMIN;
  } else if (role === dataStoragePrimitiveAdmin) {
    return CustomerRole.CUSTOMERROLE_CORPUS_ADMIN;
  } else if (role === "Billing Admin") {
    return CustomerRole.CUSTOMERROLE_BILLING_ADMIN;
  } else if (role === "Account Owner") {
    return CustomerRole.CUSTOMERROLE_OWNER;
  }
};

export const getRoleFromCustomerRole = (customerRole: CustomerRole): CustomerRoleLabel | undefined => {
  if (customerRole === CustomerRole.CUSTOMERROLE_ADMIN) {
    return "Account Admin";
  } else if (customerRole === CustomerRole.CUSTOMERROLE_CORPUS_ADMIN) {
    return dataStoragePrimitiveAdmin;
  } else if (customerRole === CustomerRole.CUSTOMERROLE_BILLING_ADMIN) {
    return "Billing Admin";
  } else if (customerRole === CustomerRole.CUSTOMERROLE_OWNER) {
    return "Account Owner";
  }
};

export const getRoleFromCorpusRole = (corpusRole: CorpusRole) => {
  if (corpusRole === CorpusRole.CORPUSROLE_QUERY) {
    return "Query";
  } else if (corpusRole === CorpusRole.CORPUSROLE_INDEX) {
    return "Index";
  } else if (corpusRole === CorpusRole.CORPUSROLE_ADMIN) {
    return dataStoragePrimitiveAdmin;
  }
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

//This is the username validation Regex from Cognito
export const validateUsername = (name: string) => {
  const re = /^[\p{L}\p{M}\p{S}\p{N}\p{P}]+$/u;
  return re.test(name);
};

export const getCurrencyStringFromCode = (code: number) => {
  const idx = CURRENCIES.findIndex((currency) => currency.code === code);
  return idx !== -1 ? CURRENCIES[idx].name : "USD";
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 * @see https://stackoverflow.com/questions/10420352
 */
export function formatBytes(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }
  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = Math.pow(10, dp);
  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
  return bytes.toFixed(dp) + " " + units[u];
}

/**
 * Based on the period definition, returns day, month or year.
 * @param {period} p
 * @returns
 */
export function displayPeriod(p: PricingPlanPeriod) {
  if (p.days === 1) {
    return "day";
  } else if (p.months === 1) {
    return "month";
  } else if (p.years === 1) {
    return "year";
  } else {
    return "[complex]";
  }
}
