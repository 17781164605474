import { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { useUserContext } from "./UserContext";
import { GetClientData, SetClientData } from "../admin/ClientKeysEndpoint";
import { ClientDataKey } from "../generated_protos/admin/admin_account_pb";
import { useApiContext } from "./ApiContext";
import { SummaryLanguage } from "../types/search";
import { CustomDimension } from "../generated_protos/serving_pb";
import { LLM, MMR_RERANKER_ID, UDF_RERANKER_ID } from "../constants";
import { selectReranker } from "./utils/selectReranker";
import { selectGenerationPreset } from "./utils/selectGenerationPreset";
import { Config, CorpusSettings, migrateConfig } from "./utils/migrateConfig";
import { datadogRum } from "@datadog/browser-rum";

export type QueryMode = "search" | "questionAndAnswer" | "chat";

export const DEFAULT_LAMBDA = 0.005;
const DEFAULT_DIVERSITY_AMOUNT = 0.3;
const DEFAULT_RERANK_AMOUNT = 25;
const DEFAULT_SUMMARY_LANGUAGE = "eng";
const DEFAULT_MAX_SUMMARIZED_RESULTS = 5;

interface ConfigContextType {
  config: Config | undefined;
  isLoadingConfig: boolean;
  isConfigError: boolean;
  getIsSurveyComplete: () => boolean | undefined;
  setIsSurveyComplete: (isSurveyComplete: boolean) => void;
  getIsSearchSummaryEnabled: () => boolean;
  setIsSearchSummaryEnabled: (isEnabled: boolean) => void;
  getQueryMode: (corpusId: number) => QueryMode;
  setQueryMode: (corpusId: number, uxMode: QueryMode) => void;
  getLambda: (corpusId: number) => { lambda: number; isCustom: boolean };
  setLambda: (corpusId: number, lambda: number) => void;
  resetLambda: (corpusId: number, isEnabled: boolean) => void;
  getIsSummarizationEnabled: (corpusId: number) => boolean;
  setIsSummarizationEnabled: (corpusId: number, isSummarizationEnabled: boolean) => void;
  getIsChatEnabled: (corpusId: number) => boolean;
  setIsChatEnabled: (corpusId: number, isChatEnabled: boolean) => void;
  getIsRerankingEnabled: (corpusId: number) => boolean;
  setIsRerankingEnabled: (corpusId: number, isReRankingEnabled: boolean) => void;
  getRerankerId: (corpusId: number) => number | undefined;
  setRerankerId: (corpusId: number, rerankerId: number) => void;
  getRerankerFunction: (corpusId: number) => string | undefined;
  setRerankerFunction: (corpusId: number, rerankerFunction: string) => void;
  getDiversityAmount: (corpusId: number) => number | undefined;
  setDiversityAmount: (corpusId: number, diversityAmount: number) => void;
  getRerankAmount: (corpusId: number) => number;
  setRerankAmount: (corpusId: number, rerankAmount: number) => void;
  getCustomDimensions: (corpusId: number) => CustomDimension.AsObject[];
  setCustomDimensions: (corpusId: number, customDimensions: CustomDimension.AsObject[]) => void;
  resetCustomDimensions: (corpusId: number) => void;
  getLlmName: (corpusId: number) => string;
  setLlmName: (corpusId: number, llmId: string) => void;
  getGenerationPresetName: (corpusId: number) => string | undefined;
  setGenerationPresetName: (corpusId: number, generationPresetId?: string) => void;
  getSummaryLanguage: (corpusId: number) => SummaryLanguage;
  setSummaryLanguage: (corpusId: number, summaryLanguage: SummaryLanguage) => void;
  getMaxSummarizedResults: (corpusId: number) => number;
  setMaxSummarizedResults: (corpusId: number, maxSummarizedResults: number) => void;
  getIsFactualConsistencyScoreEnabled: (corpusId: number) => boolean;
  setIsFactualConsistencyScoreEnabled: (corpusId: number, isFactualConsistencyScoreEnabled: boolean) => void;
  setConfigValue: (key: keyof Config, value: unknown) => void;
  setCustomPrompt: (corpusId: number, customPrompt: string | null) => void;
  getCustomPrompt: (corpusId: number) => string | undefined;
  getSentencesBefore: (corpusId: number) => number | undefined;
  setSentencesBefore: (corpusId: number, number?: number) => void;
  getSentencesAfter: (corpusId: number) => number | undefined;
  setSentencesAfter: (corpusId: number, number?: number) => void;
  getCharsBefore: (corpusId: number) => number | undefined;
  setCharsBefore: (corpusId: number, number?: number) => void;
  getCharsAfter: (corpusId: number) => number | undefined;
  setCharsAfter: (corpusId: number, number?: number) => void;
  getIsStreamingEnabledInSnippet: () => boolean;
  setIsStreamingEnabledInSnippet: (isEnabled: boolean) => void;
}

const ConfigContext = createContext<ConfigContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const ConfigContextProvider = ({ children }: Props) => {
  const { AdminService } = useApiContext();
  const { hasInitiallyAuthenticated, customer, getJwt, rerankers, generationPresets, availableLlms } = useUserContext();
  const [config, setConfig] = useState<Config>();
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [isConfigError, setIsConfigError] = useState(false);
  /**
   * This will only get called when userContext changes which normally
   * happens when userSession is refreshed through cognito refresh token.
   */
  useEffect(() => {
    const getConfig = async (customerId: string) => {
      try {
        const jwt = await getJwt();
        const { data } = await GetClientData(jwt, AdminService, customerId, ClientDataKey.CLIENT_DATA_KEY__CONFIG);

        setConfig(
          migrateConfig((data ? JSON.parse(data) : []) as Config | undefined, {
            generationPresets
          })
        );
        setIsLoadingConfig(false);
      } catch (err) {
        datadogRum.addError(err);
        console.log("error", err);
        setIsConfigError(true);
        setIsLoadingConfig(false);
      }
    };

    // Wait for the authentication logic to run before trying to fetch the config.
    if (!hasInitiallyAuthenticated) {
      setConfig(undefined);
      setIsLoadingConfig(true);
      return;
    }

    if (customer?.customerId) {
      // If the user is authenticated, fetch the config.
      getConfig(customer.customerId);
    } else {
      // If they're not authenticated, mark loading as complete so the UI isn't
      // stuck in a loading state.
      setIsLoadingConfig(false);
    }
  }, [hasInitiallyAuthenticated, customer, AdminService, getJwt, setConfig]);

  const persistUpdatedConfig = async (updatedConfig: Config) => {
    const jwt = await getJwt();
    if (customer && jwt) {
      try {
        await SetClientData(
          jwt,
          AdminService,
          customer.customerId,
          ClientDataKey.CLIENT_DATA_KEY__CONFIG,
          JSON.stringify(updatedConfig)
        );
      } catch (e) {
        datadogRum.addError(e);
        console.log(e);
      }
    }
  };

  const getIsSurveyComplete = () => {
    return config?.isSurveyComplete;
  };

  const setIsSurveyComplete = (isSurveyComplete: boolean) => {
    setConfig((prevConfig) => {
      const updatedConfig = {
        ...prevConfig,
        isSurveyComplete
      };

      persistUpdatedConfig(updatedConfig);
      return updatedConfig;
    });
  };

  const getIsSearchSummaryEnabled = () => {
    return config?.isSearchSummaryEnabled ?? true;
  };

  const setIsSearchSummaryEnabled = (isEnabled: boolean) => {
    setConfig((prevConfig) => {
      const updatedConfig = {
        ...prevConfig,
        isSearchSummaryEnabled: isEnabled
      };

      persistUpdatedConfig(updatedConfig);
      return updatedConfig;
    });
  };

  const getSettings = (corpusId: number, customConfig?: Config) => {
    return (customConfig ?? config)?.corporaSettings?.[corpusId] ?? {};
  };

  const updateCorpusSetting = async (corpusId: number, change: Partial<CorpusSettings>) => {
    // Update local state synchronously so consumers can render the new value.
    // This is important when rendering values in controlled inputs. If we were
    // to update this value after an async operation then the cursor would jump to
    // the end of the input.
    // https://github.com/facebook/react/issues/5386
    setConfig((prevConfig) => {
      const updatedCorpusSetting = { ...getSettings(corpusId, prevConfig), ...change };
      const updatedConfig = {
        ...prevConfig,
        corporaSettings: {
          ...prevConfig?.corporaSettings,
          [corpusId]: updatedCorpusSetting
        }
      };

      persistUpdatedConfig(updatedConfig);
      return updatedConfig;
    });
  };

  const getQueryMode = (corpusId: number) => {
    // Derive query mode from summarization to avoid duplicate sources of truth.
    const isSummarizationEnabled = getIsSummarizationEnabled(corpusId);
    const isChatEnabled = getIsChatEnabled(corpusId);
    return isChatEnabled ? "chat" : isSummarizationEnabled ? "questionAndAnswer" : "search";
  };

  const setQueryMode = (corpusId: number, queryMode: QueryMode) => {
    switch (queryMode) {
      case "search":
        setIsSummarizationEnabled(corpusId, false);
        setIsChatEnabled(corpusId, false);
        break;

      case "questionAndAnswer":
        setIsSummarizationEnabled(corpusId, true);
        setIsChatEnabled(corpusId, false);
        break;

      case "chat":
        setIsSummarizationEnabled(corpusId, true);
        setIsChatEnabled(corpusId, true);
        break;
    }
  };

  const getLambda = (corpusId: number) => {
    const lambda = getSettings(corpusId).lambda ?? DEFAULT_LAMBDA;
    return { lambda, isCustom: lambda !== 0 };
  };

  const setLambda = (corpusId: number, lambda: number) => {
    updateCorpusSetting(corpusId, { lambda });
  };

  const resetLambda = (corpusId: number, isEnabled: boolean) => {
    if (isEnabled) {
      setLambda(corpusId, DEFAULT_LAMBDA);
    } else {
      setLambda(corpusId, 0);
    }
  };

  const getIsSummarizationEnabled = (corpusId: number) => {
    return getSettings(corpusId).isSummarizationEnabled ?? true;
  };

  const setIsSummarizationEnabled = (corpusId: number, isSummarizationEnabled: boolean) => {
    updateCorpusSetting(corpusId, { isSummarizationEnabled });
  };

  const getIsChatEnabled = (corpusId: number) => {
    // Default to false to avoid changing existing corpora configurations.
    return getSettings(corpusId).isChatEnabled ?? false;
  };

  const setIsChatEnabled = (corpusId: number, isChatEnabled: boolean) => {
    updateCorpusSetting(corpusId, { isChatEnabled });
  };

  const getIsFactualConsistencyScoreEnabled = (corpusId: number) => {
    return getSettings(corpusId).isFactualConsistencyScoreEnabled ?? true;
  };

  const setIsFactualConsistencyScoreEnabled = (corpusId: number, isFactualConsistencyScoreEnabled: boolean) => {
    updateCorpusSetting(corpusId, { isFactualConsistencyScoreEnabled });
  };

  const getIsRerankingEnabled = (corpusId: number) => {
    // The API uses the MMR reranker by default.
    return getSettings(corpusId).isRerankingEnabled ?? true;
  };

  const setIsRerankingEnabled = (corpusId: number, isRerankingEnabled: boolean) => {
    updateCorpusSetting(corpusId, { isRerankingEnabled });

    if (!isRerankingEnabled) {
      setRerankerId(corpusId, undefined);
      setDiversityAmount(corpusId, undefined);
      setRerankAmount(corpusId, undefined);
    }
  };

  const getIsStreamingEnabledInSnippet = () => {
    return config?.isStreamingEnabledInSnippet ?? true;
  };

  const setIsStreamingEnabledInSnippet = (isStreamingEnabledInSnippet: boolean) => {
    setConfig((prevConfig) => {
      const updatedConfig = {
        ...prevConfig,
        isStreamingEnabledInSnippet
      };

      persistUpdatedConfig(updatedConfig);
      return updatedConfig;
    });
  };

  const getRerankerId = (corpusId: number) => {
    const defaultReranker = selectReranker(rerankers);
    return getSettings(corpusId).rerankerId ?? defaultReranker;
  };

  const setRerankerId = (corpusId: number, rerankerId?: number) => {
    updateCorpusSetting(corpusId, { rerankerId });

    // If the reranker is set to something other than the UDF reranker,
    // clear out the reranker function
    if (rerankerId !== UDF_RERANKER_ID) {
      setRerankerFunction(corpusId, undefined);
    }

    if (rerankerId === MMR_RERANKER_ID) {
      setDiversityAmount(corpusId, DEFAULT_DIVERSITY_AMOUNT);
    } else {
      setDiversityAmount(corpusId, undefined);
    }
  };

  const getRerankerFunction = (corpusId: number) => {
    return getSettings(corpusId).rerankerFunction;
  };

  const setRerankerFunction = (corpusId: number, rerankerFunction?: string) => {
    updateCorpusSetting(corpusId, { rerankerFunction });
  };

  const getDiversityAmount = (corpusId: number) => {
    return getSettings(corpusId).diversityAmount;
  };

  const setDiversityAmount = (corpusId: number, diversityAmount?: number) => {
    updateCorpusSetting(corpusId, { diversityAmount });
  };

  const getRerankAmount = (corpusId: number) => {
    return getSettings(corpusId).rerankAmount ?? DEFAULT_RERANK_AMOUNT;
  };

  const setRerankAmount = (corpusId: number, rerankAmount?: number) => {
    updateCorpusSetting(corpusId, { rerankAmount });
  };

  const getCustomDimensions = (corpusId: number) => {
    return getSettings(corpusId).customDimensions ?? [];
  };

  const setCustomDimensions = (corpusId: number, customDimensions: CustomDimension.AsObject[]) => {
    updateCorpusSetting(corpusId, { customDimensions });
  };

  const resetCustomDimensions = (corpusId: number) => {
    updateCorpusSetting(corpusId, { customDimensions: [] });
  };

  const getLlmName = (corpusId: number) => {
    const persistedLlmName = getSettings(corpusId).llmName;

    // If an LLM name was previously set:
    // Return it if is still available (i.e. has generation presets)
    // Otherwise, default to the first available LLM.
    if (persistedLlmName) {
      if (availableLlms.includes(persistedLlmName)) {
        return persistedLlmName;
      } else {
        const fallbackLlmName = availableLlms[0];
        setLlmName(corpusId, fallbackLlmName);
        return fallbackLlmName;
      }
    }

    // Try to use an LLM that works with the persisted generation preset, if there is one.
    const generationPresetName = getSettings(corpusId).generationPresetName;
    if (generationPresetName) {
      const generationPreset = (generationPresets ?? []).find(({ name }) => name === generationPresetName);
      if (generationPreset?.llm_name) return generationPreset.llm_name;
    }

    // Default to Mockingbird if possible.
    if (availableLlms.includes(LLM.mockingbird)) return LLM.mockingbird;

    // Default to the first available LLM.
    return availableLlms[0];
  };

  const setLlmName = (corpusId: number, llmName: string) => {
    updateCorpusSetting(corpusId, { llmName });

    // Setting an LLM also resets the generation preset to the default.
    const defaultGenerationPreset = selectGenerationPreset(generationPresets ?? [], llmName);
    setGenerationPresetName(corpusId, defaultGenerationPreset?.name ?? "");

    if (llmName === LLM.mockingbird) {
      setSummaryLanguage(corpusId, "auto");
    }
  };

  const getGenerationPresetName = (corpusId: number) => {
    const persistedGenerationPresetName = getSettings(corpusId).generationPresetName;

    // If there isn't a persisted generation preset name,
    // use the util function to find a backup.
    return persistedGenerationPresetName ?? selectGenerationPreset(generationPresets ?? [], getLlmName(corpusId))?.name;
  };

  const setGenerationPresetName = (corpusId: number, generationPresetName?: string) => {
    // When the user selects a new generation preset, we can remove the deprecated summarizer.
    updateCorpusSetting(corpusId, { generationPresetName, summarizerName: undefined });
  };

  const getSummaryLanguage = (corpusId: number): SummaryLanguage => {
    return (getSettings(corpusId).summaryLanguage as SummaryLanguage | undefined) ?? DEFAULT_SUMMARY_LANGUAGE;
  };

  const setSummaryLanguage = (corpusId: number, summaryLanguage: SummaryLanguage) => {
    updateCorpusSetting(corpusId, { summaryLanguage });
  };

  const getMaxSummarizedResults = (corpusId: number) => {
    return getSettings(corpusId).maxSummarizedResults ?? DEFAULT_MAX_SUMMARIZED_RESULTS;
  };

  const setMaxSummarizedResults = (corpusId: number, maxSummarizedResults: number) => {
    updateCorpusSetting(corpusId, { maxSummarizedResults });
  };

  const getCustomPrompt = (corpusId: number) => {
    // Interpret null as no custom prompt.
    return getSettings(corpusId).customPrompt ?? undefined;
  };

  const setCustomPrompt = (corpusId: number, customPrompt: string | null) => {
    // Assign null to delete the custom prompt.
    updateCorpusSetting(corpusId, { customPrompt });
  };

  const setConfigValue = (key: keyof Config, value: unknown) => {
    setConfig((prevConfig) => {
      const updatedConfig = {
        ...prevConfig,
        [key]: value
      };

      persistUpdatedConfig(updatedConfig);
      return updatedConfig;
    });
  };

  const getSentencesBefore = (corpusId: number) => {
    return getSettings(corpusId).sentencesBefore ?? 2;
  };

  const setSentencesBefore = (corpusId: number, number: number | undefined) => {
    // Always default to zero, which is what we want undefined to resolve to anyway.
    // Saving undefined results in the key being removed from the JSON stringified configuration.
    updateCorpusSetting(corpusId, { sentencesBefore: number ?? 0 });
  };

  const getSentencesAfter = (corpusId: number) => {
    return getSettings(corpusId).sentencesAfter ?? 2;
  };

  const setSentencesAfter = (corpusId: number, number: number | undefined) => {
    // Always default to zero, which is what we want undefined to resolve to anyway.
    // Saving undefined results in the key being removed from the JSON stringified configuration.
    updateCorpusSetting(corpusId, { sentencesAfter: number ?? 0 });
  };

  const getCharsBefore = (corpusId: number) => {
    return getSettings(corpusId).charsBefore;
  };

  const setCharsBefore = (corpusId: number, number: number | undefined) => {
    updateCorpusSetting(corpusId, { charsBefore: number });
  };

  const getCharsAfter = (corpusId: number) => {
    return getSettings(corpusId).charsAfter;
  };

  const setCharsAfter = (corpusId: number, number: number | undefined) => {
    updateCorpusSetting(corpusId, { charsAfter: number });
  };

  return (
    <ConfigContext.Provider
      value={{
        config,
        isLoadingConfig,
        isConfigError,
        getIsSurveyComplete,
        setIsSurveyComplete,
        getIsSearchSummaryEnabled,
        setIsSearchSummaryEnabled,
        getQueryMode,
        setQueryMode,
        getLambda,
        setLambda,
        resetLambda,
        getIsSummarizationEnabled,
        setIsSummarizationEnabled,
        getIsChatEnabled,
        setIsChatEnabled,
        getIsFactualConsistencyScoreEnabled,
        setIsFactualConsistencyScoreEnabled,
        getIsRerankingEnabled,
        setIsRerankingEnabled,
        getRerankerId,
        setRerankerId,
        getDiversityAmount,
        setDiversityAmount,
        getRerankAmount,
        setRerankAmount,
        getCustomDimensions,
        setCustomDimensions,
        resetCustomDimensions,
        getLlmName,
        setLlmName,
        getGenerationPresetName,
        setGenerationPresetName,
        getSummaryLanguage,
        setSummaryLanguage,
        getMaxSummarizedResults,
        setMaxSummarizedResults,
        setCustomPrompt,
        getCustomPrompt,
        getSentencesBefore,
        setSentencesBefore,
        getSentencesAfter,
        setSentencesAfter,
        getCharsBefore,
        setCharsBefore,
        getCharsAfter,
        setCharsAfter,
        getIsStreamingEnabledInSnippet,
        setIsStreamingEnabledInSnippet,
        getRerankerFunction,
        setRerankerFunction,
        setConfigValue
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfigContext = () => {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error("useConfigContext must be used within a ConfigContextProvider");
  }
  return context;
};
