// source: admin/admin_appclient.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var admin_admin_security_pb = require('../admin/admin_security_pb.js');
goog.object.extend(proto, admin_admin_security_pb);
var status_pb = require('../status_pb.js');
goog.object.extend(proto, status_pb);
goog.exportSymbol('proto.com.vectara.admin.AppClient', null, global);
goog.exportSymbol('proto.com.vectara.admin.AppClientActionType', null, global);
goog.exportSymbol('proto.com.vectara.admin.AppClientType', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListAppClientsRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListAppClientsResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ManageAppClientRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ManageAppClientRequest.AppClientAction', null, global);
goog.exportSymbol('proto.com.vectara.admin.ManageAppClientResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.AppClient = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.AppClient.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.AppClient, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.AppClient.displayName = 'proto.com.vectara.admin.AppClient';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ManageAppClientRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ManageAppClientRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ManageAppClientRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ManageAppClientRequest.displayName = 'proto.com.vectara.admin.ManageAppClientRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ManageAppClientRequest.AppClientAction = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.ManageAppClientRequest.AppClientAction, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.displayName = 'proto.com.vectara.admin.ManageAppClientRequest.AppClientAction';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ManageAppClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ManageAppClientResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ManageAppClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ManageAppClientResponse.displayName = 'proto.com.vectara.admin.ManageAppClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, null, null);
};
goog.inherits(proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.displayName = 'proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListAppClientsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ListAppClientsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ListAppClientsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListAppClientsRequest.displayName = 'proto.com.vectara.admin.ListAppClientsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListAppClientsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ListAppClientsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ListAppClientsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListAppClientsResponse.displayName = 'proto.com.vectara.admin.ListAppClientsResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.AppClient.repeatedFields_ = [9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.AppClient.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.AppClient.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.AppClient} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.AppClient.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, 0),
    handle: jspb.Message.getFieldWithDefault(msg, 2, ""),
    comment: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sub: jspb.Message.getFieldWithDefault(msg, 4, ""),
    secret: jspb.Message.getFieldWithDefault(msg, 5, ""),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    callbackUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    signoutUrl: jspb.Message.getFieldWithDefault(msg, 8, ""),
    roleList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.AppClient}
 */
proto.com.vectara.admin.AppClient.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.AppClient;
  return proto.com.vectara.admin.AppClient.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.AppClient} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.AppClient}
 */
proto.com.vectara.admin.AppClient.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setHandle(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setComment(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setSub(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecret(value);
      break;
    case 6:
      var value = /** @type {!proto.com.vectara.admin.AppClientType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setCallbackUrl(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setSignoutUrl(value);
      break;
    case 9:
      var values = /** @type {!Array<!proto.com.vectara.admin.CustomerRole>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addRole(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.AppClient.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.AppClient.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.AppClient} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.AppClient.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getHandle();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getComment();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSub();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSecret();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getCallbackUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getSignoutUrl();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getRoleList();
  if (f.length > 0) {
    writer.writePackedEnum(
      9,
      f
    );
  }
};


/**
 * optional uint32 id = 1;
 * @return {number}
 */
proto.com.vectara.admin.AppClient.prototype.getId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.AppClient} returns this
 */
proto.com.vectara.admin.AppClient.prototype.setId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string handle = 2;
 * @return {string}
 */
proto.com.vectara.admin.AppClient.prototype.getHandle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.AppClient} returns this
 */
proto.com.vectara.admin.AppClient.prototype.setHandle = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string comment = 3;
 * @return {string}
 */
proto.com.vectara.admin.AppClient.prototype.getComment = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.AppClient} returns this
 */
proto.com.vectara.admin.AppClient.prototype.setComment = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string sub = 4;
 * @return {string}
 */
proto.com.vectara.admin.AppClient.prototype.getSub = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.AppClient} returns this
 */
proto.com.vectara.admin.AppClient.prototype.setSub = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string secret = 5;
 * @return {string}
 */
proto.com.vectara.admin.AppClient.prototype.getSecret = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.AppClient} returns this
 */
proto.com.vectara.admin.AppClient.prototype.setSecret = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional AppClientType type = 6;
 * @return {!proto.com.vectara.admin.AppClientType}
 */
proto.com.vectara.admin.AppClient.prototype.getType = function() {
  return /** @type {!proto.com.vectara.admin.AppClientType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.com.vectara.admin.AppClientType} value
 * @return {!proto.com.vectara.admin.AppClient} returns this
 */
proto.com.vectara.admin.AppClient.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional string callback_url = 7;
 * @return {string}
 */
proto.com.vectara.admin.AppClient.prototype.getCallbackUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.AppClient} returns this
 */
proto.com.vectara.admin.AppClient.prototype.setCallbackUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string signout_url = 8;
 * @return {string}
 */
proto.com.vectara.admin.AppClient.prototype.getSignoutUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.AppClient} returns this
 */
proto.com.vectara.admin.AppClient.prototype.setSignoutUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * repeated CustomerRole role = 9;
 * @return {!Array<!proto.com.vectara.admin.CustomerRole>}
 */
proto.com.vectara.admin.AppClient.prototype.getRoleList = function() {
  return /** @type {!Array<!proto.com.vectara.admin.CustomerRole>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<!proto.com.vectara.admin.CustomerRole>} value
 * @return {!proto.com.vectara.admin.AppClient} returns this
 */
proto.com.vectara.admin.AppClient.prototype.setRoleList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {!proto.com.vectara.admin.CustomerRole} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.AppClient} returns this
 */
proto.com.vectara.admin.AppClient.prototype.addRole = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.AppClient} returns this
 */
proto.com.vectara.admin.AppClient.prototype.clearRoleList = function() {
  return this.setRoleList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ManageAppClientRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ManageAppClientRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ManageAppClientRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ManageAppClientRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ManageAppClientRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    appClientActionsList: jspb.Message.toObjectList(msg.getAppClientActionsList(),
    proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ManageAppClientRequest}
 */
proto.com.vectara.admin.ManageAppClientRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ManageAppClientRequest;
  return proto.com.vectara.admin.ManageAppClientRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ManageAppClientRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ManageAppClientRequest}
 */
proto.com.vectara.admin.ManageAppClientRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.ManageAppClientRequest.AppClientAction;
      reader.readMessage(value,proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.deserializeBinaryFromReader);
      msg.addAppClientActions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ManageAppClientRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ManageAppClientRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ManageAppClientRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ManageAppClientRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppClientActionsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ManageAppClientRequest.AppClientAction} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.toObject = function(includeInstance, msg) {
  var f, obj = {
    appClient: (f = msg.getAppClient()) && proto.com.vectara.admin.AppClient.toObject(includeInstance, f),
    appClientActionType: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ManageAppClientRequest.AppClientAction}
 */
proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ManageAppClientRequest.AppClientAction;
  return proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ManageAppClientRequest.AppClientAction} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ManageAppClientRequest.AppClientAction}
 */
proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.AppClient;
      reader.readMessage(value,proto.com.vectara.admin.AppClient.deserializeBinaryFromReader);
      msg.setAppClient(value);
      break;
    case 2:
      var value = /** @type {!proto.com.vectara.admin.AppClientActionType} */ (reader.readEnum());
      msg.setAppClientActionType(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ManageAppClientRequest.AppClientAction} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppClient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.vectara.admin.AppClient.serializeBinaryToWriter
    );
  }
  f = message.getAppClientActionType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional AppClient app_client = 1;
 * @return {?proto.com.vectara.admin.AppClient}
 */
proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.prototype.getAppClient = function() {
  return /** @type{?proto.com.vectara.admin.AppClient} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.AppClient, 1));
};


/**
 * @param {?proto.com.vectara.admin.AppClient|undefined} value
 * @return {!proto.com.vectara.admin.ManageAppClientRequest.AppClientAction} returns this
*/
proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.prototype.setAppClient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ManageAppClientRequest.AppClientAction} returns this
 */
proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.prototype.clearAppClient = function() {
  return this.setAppClient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.prototype.hasAppClient = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional AppClientActionType app_client_action_type = 2;
 * @return {!proto.com.vectara.admin.AppClientActionType}
 */
proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.prototype.getAppClientActionType = function() {
  return /** @type {!proto.com.vectara.admin.AppClientActionType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.vectara.admin.AppClientActionType} value
 * @return {!proto.com.vectara.admin.ManageAppClientRequest.AppClientAction} returns this
 */
proto.com.vectara.admin.ManageAppClientRequest.AppClientAction.prototype.setAppClientActionType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated AppClientAction app_client_actions = 1;
 * @return {!Array<!proto.com.vectara.admin.ManageAppClientRequest.AppClientAction>}
 */
proto.com.vectara.admin.ManageAppClientRequest.prototype.getAppClientActionsList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.ManageAppClientRequest.AppClientAction>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.ManageAppClientRequest.AppClientAction, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.ManageAppClientRequest.AppClientAction>} value
 * @return {!proto.com.vectara.admin.ManageAppClientRequest} returns this
*/
proto.com.vectara.admin.ManageAppClientRequest.prototype.setAppClientActionsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.ManageAppClientRequest.AppClientAction=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ManageAppClientRequest.AppClientAction}
 */
proto.com.vectara.admin.ManageAppClientRequest.prototype.addAppClientActions = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.ManageAppClientRequest.AppClientAction, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ManageAppClientRequest} returns this
 */
proto.com.vectara.admin.ManageAppClientRequest.prototype.clearAppClientActionsList = function() {
  return this.setAppClientActionsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ManageAppClientResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ManageAppClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ManageAppClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ManageAppClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ManageAppClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    responseList: jspb.Message.toObjectList(msg.getResponseList(),
    proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ManageAppClientResponse}
 */
proto.com.vectara.admin.ManageAppClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ManageAppClientResponse;
  return proto.com.vectara.admin.ManageAppClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ManageAppClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ManageAppClientResponse}
 */
proto.com.vectara.admin.ManageAppClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse;
      reader.readMessage(value,proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.deserializeBinaryFromReader);
      msg.addResponse(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ManageAppClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ManageAppClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ManageAppClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ManageAppClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getResponseList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appclient: (f = msg.getAppclient()) && proto.com.vectara.admin.AppClient.toObject(includeInstance, f),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse}
 */
proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse;
  return proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse}
 */
proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.AppClient;
      reader.readMessage(value,proto.com.vectara.admin.AppClient.deserializeBinaryFromReader);
      msg.setAppclient(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppclient();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.com.vectara.admin.AppClient.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional AppClient appClient = 1;
 * @return {?proto.com.vectara.admin.AppClient}
 */
proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.prototype.getAppclient = function() {
  return /** @type{?proto.com.vectara.admin.AppClient} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.AppClient, 1));
};


/**
 * @param {?proto.com.vectara.admin.AppClient|undefined} value
 * @return {!proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse} returns this
*/
proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.prototype.setAppclient = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse} returns this
 */
proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.prototype.clearAppclient = function() {
  return this.setAppclient(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.prototype.hasAppclient = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse} returns this
*/
proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse} returns this
 */
proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};


/**
 * repeated AppClientResponse response = 1;
 * @return {!Array<!proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse>}
 */
proto.com.vectara.admin.ManageAppClientResponse.prototype.getResponseList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse>} value
 * @return {!proto.com.vectara.admin.ManageAppClientResponse} returns this
*/
proto.com.vectara.admin.ManageAppClientResponse.prototype.setResponseList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse}
 */
proto.com.vectara.admin.ManageAppClientResponse.prototype.addResponse = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.ManageAppClientResponse.AppClientResponse, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ManageAppClientResponse} returns this
 */
proto.com.vectara.admin.ManageAppClientResponse.prototype.clearResponseList = function() {
  return this.setResponseList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ListAppClientsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListAppClientsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListAppClientsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListAppClientsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListAppClientsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    filter: jspb.Message.getFieldWithDefault(msg, 1, ""),
    pageKey: msg.getPageKey_asB64(),
    numResults: jspb.Message.getFieldWithDefault(msg, 3, 0),
    appClientIdList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListAppClientsRequest}
 */
proto.com.vectara.admin.ListAppClientsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListAppClientsRequest;
  return proto.com.vectara.admin.ListAppClientsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListAppClientsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListAppClientsRequest}
 */
proto.com.vectara.admin.ListAppClientsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setFilter(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumResults(value);
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addAppClientId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListAppClientsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListAppClientsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListAppClientsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListAppClientsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFilter();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getAppClientIdList();
  if (f.length > 0) {
    writer.writePackedUint32(
      4,
      f
    );
  }
};


/**
 * optional string filter = 1;
 * @return {string}
 */
proto.com.vectara.admin.ListAppClientsRequest.prototype.getFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ListAppClientsRequest} returns this
 */
proto.com.vectara.admin.ListAppClientsRequest.prototype.setFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes page_key = 2;
 * @return {string}
 */
proto.com.vectara.admin.ListAppClientsRequest.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes page_key = 2;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.admin.ListAppClientsRequest.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListAppClientsRequest.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.admin.ListAppClientsRequest} returns this
 */
proto.com.vectara.admin.ListAppClientsRequest.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional uint32 num_results = 3;
 * @return {number}
 */
proto.com.vectara.admin.ListAppClientsRequest.prototype.getNumResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ListAppClientsRequest} returns this
 */
proto.com.vectara.admin.ListAppClientsRequest.prototype.setNumResults = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.admin.ListAppClientsRequest} returns this
 */
proto.com.vectara.admin.ListAppClientsRequest.prototype.clearNumResults = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ListAppClientsRequest.prototype.hasNumResults = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated uint32 app_client_id = 4;
 * @return {!Array<number>}
 */
proto.com.vectara.admin.ListAppClientsRequest.prototype.getAppClientIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.com.vectara.admin.ListAppClientsRequest} returns this
 */
proto.com.vectara.admin.ListAppClientsRequest.prototype.setAppClientIdList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ListAppClientsRequest} returns this
 */
proto.com.vectara.admin.ListAppClientsRequest.prototype.addAppClientId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ListAppClientsRequest} returns this
 */
proto.com.vectara.admin.ListAppClientsRequest.prototype.clearAppClientIdList = function() {
  return this.setAppClientIdList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ListAppClientsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListAppClientsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListAppClientsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListAppClientsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListAppClientsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    appclientsList: jspb.Message.toObjectList(msg.getAppclientsList(),
    proto.com.vectara.admin.AppClient.toObject, includeInstance),
    authUrl: jspb.Message.getFieldWithDefault(msg, 2, ""),
    pageKey: msg.getPageKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListAppClientsResponse}
 */
proto.com.vectara.admin.ListAppClientsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListAppClientsResponse;
  return proto.com.vectara.admin.ListAppClientsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListAppClientsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListAppClientsResponse}
 */
proto.com.vectara.admin.ListAppClientsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.admin.AppClient;
      reader.readMessage(value,proto.com.vectara.admin.AppClient.deserializeBinaryFromReader);
      msg.addAppclients(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthUrl(value);
      break;
    case 3:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListAppClientsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListAppClientsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListAppClientsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListAppClientsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAppclientsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.admin.AppClient.serializeBinaryToWriter
    );
  }
  f = message.getAuthUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      3,
      f
    );
  }
};


/**
 * repeated AppClient appClients = 1;
 * @return {!Array<!proto.com.vectara.admin.AppClient>}
 */
proto.com.vectara.admin.ListAppClientsResponse.prototype.getAppclientsList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.AppClient>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.AppClient, 1));
};


/**
 * @param {!Array<!proto.com.vectara.admin.AppClient>} value
 * @return {!proto.com.vectara.admin.ListAppClientsResponse} returns this
*/
proto.com.vectara.admin.ListAppClientsResponse.prototype.setAppclientsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.admin.AppClient=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.AppClient}
 */
proto.com.vectara.admin.ListAppClientsResponse.prototype.addAppclients = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.admin.AppClient, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ListAppClientsResponse} returns this
 */
proto.com.vectara.admin.ListAppClientsResponse.prototype.clearAppclientsList = function() {
  return this.setAppclientsList([]);
};


/**
 * optional string auth_url = 2;
 * @return {string}
 */
proto.com.vectara.admin.ListAppClientsResponse.prototype.getAuthUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ListAppClientsResponse} returns this
 */
proto.com.vectara.admin.ListAppClientsResponse.prototype.setAuthUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bytes page_key = 3;
 * @return {string}
 */
proto.com.vectara.admin.ListAppClientsResponse.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * optional bytes page_key = 3;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.admin.ListAppClientsResponse.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 3;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListAppClientsResponse.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.admin.ListAppClientsResponse} returns this
 */
proto.com.vectara.admin.ListAppClientsResponse.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 3, value);
};


/**
 * @enum {number}
 */
proto.com.vectara.admin.AppClientType = {
  APP_CLIENT_TYPE__NONE: 0,
  APP_CLIENT_TYPE__CLIENT_CREDENTIALS: 1,
  APP_CLIENT_TYPE__AUTH_CODE_GRANT: 2
};

/**
 * @enum {number}
 */
proto.com.vectara.admin.AppClientActionType = {
  APP_CLIENT_ACTION_TYPE__NONE: 0,
  APP_CLIENT_ACTION_TYPE__ADD: 1,
  APP_CLIENT_ACTION_TYPE__DELETE: 2,
  APP_CLIENT_ACTION_TYPE__EDIT: 3
};

goog.object.extend(exports, proto.com.vectara.admin);
