// source: list_documents.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var attribute_pb = require('./attribute_pb.js');
goog.object.extend(proto, attribute_pb);
var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.com.vectara.lists.ListDocumentsPageKey', null, global);
goog.exportSymbol('proto.com.vectara.lists.ListDocumentsRequest', null, global);
goog.exportSymbol('proto.com.vectara.lists.ListDocumentsResponse', null, global);
goog.exportSymbol('proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.lists.ListDocumentsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.lists.ListDocumentsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.lists.ListDocumentsRequest.displayName = 'proto.com.vectara.lists.ListDocumentsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.lists.ListDocumentsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.lists.ListDocumentsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.lists.ListDocumentsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.lists.ListDocumentsResponse.displayName = 'proto.com.vectara.lists.ListDocumentsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.displayName = 'proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.lists.ListDocumentsPageKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.lists.ListDocumentsPageKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.lists.ListDocumentsPageKey.displayName = 'proto.com.vectara.lists.ListDocumentsPageKey';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.lists.ListDocumentsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.lists.ListDocumentsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.lists.ListDocumentsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.lists.ListDocumentsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    numResults: jspb.Message.getFieldWithDefault(msg, 10, 0),
    pageKey: msg.getPageKey_asB64(),
    metadataFilter: jspb.Message.getFieldWithDefault(msg, 20, ""),
    orderBy: (f = msg.getOrderBy()) && common_pb.OrderBy.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.lists.ListDocumentsRequest}
 */
proto.com.vectara.lists.ListDocumentsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.lists.ListDocumentsRequest;
  return proto.com.vectara.lists.ListDocumentsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.lists.ListDocumentsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.lists.ListDocumentsRequest}
 */
proto.com.vectara.lists.ListDocumentsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumResults(value);
      break;
    case 15:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    case 20:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetadataFilter(value);
      break;
    case 25:
      var value = new common_pb.OrderBy;
      reader.readMessage(value,common_pb.OrderBy.deserializeBinaryFromReader);
      msg.setOrderBy(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.lists.ListDocumentsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.lists.ListDocumentsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.lists.ListDocumentsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.lists.ListDocumentsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getNumResults();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      15,
      f
    );
  }
  f = message.getMetadataFilter();
  if (f.length > 0) {
    writer.writeString(
      20,
      f
    );
  }
  f = message.getOrderBy();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      common_pb.OrderBy.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 corpus_id = 5;
 * @return {number}
 */
proto.com.vectara.lists.ListDocumentsRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.lists.ListDocumentsRequest} returns this
 */
proto.com.vectara.lists.ListDocumentsRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 num_results = 10;
 * @return {number}
 */
proto.com.vectara.lists.ListDocumentsRequest.prototype.getNumResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.lists.ListDocumentsRequest} returns this
 */
proto.com.vectara.lists.ListDocumentsRequest.prototype.setNumResults = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional bytes page_key = 15;
 * @return {string}
 */
proto.com.vectara.lists.ListDocumentsRequest.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * optional bytes page_key = 15;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.lists.ListDocumentsRequest.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 15;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.lists.ListDocumentsRequest.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.lists.ListDocumentsRequest} returns this
 */
proto.com.vectara.lists.ListDocumentsRequest.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 15, value);
};


/**
 * optional string metadata_filter = 20;
 * @return {string}
 */
proto.com.vectara.lists.ListDocumentsRequest.prototype.getMetadataFilter = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.lists.ListDocumentsRequest} returns this
 */
proto.com.vectara.lists.ListDocumentsRequest.prototype.setMetadataFilter = function(value) {
  return jspb.Message.setProto3StringField(this, 20, value);
};


/**
 * optional com.vectara.OrderBy order_by = 25;
 * @return {?proto.com.vectara.OrderBy}
 */
proto.com.vectara.lists.ListDocumentsRequest.prototype.getOrderBy = function() {
  return /** @type{?proto.com.vectara.OrderBy} */ (
    jspb.Message.getWrapperField(this, common_pb.OrderBy, 25));
};


/**
 * @param {?proto.com.vectara.OrderBy|undefined} value
 * @return {!proto.com.vectara.lists.ListDocumentsRequest} returns this
*/
proto.com.vectara.lists.ListDocumentsRequest.prototype.setOrderBy = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.lists.ListDocumentsRequest} returns this
 */
proto.com.vectara.lists.ListDocumentsRequest.prototype.clearOrderBy = function() {
  return this.setOrderBy(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.lists.ListDocumentsRequest.prototype.hasOrderBy = function() {
  return jspb.Message.getField(this, 25) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.lists.ListDocumentsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.lists.ListDocumentsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.lists.ListDocumentsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.lists.ListDocumentsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.lists.ListDocumentsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    documentList: jspb.Message.toObjectList(msg.getDocumentList(),
    proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.toObject, includeInstance),
    nextPageKey: msg.getNextPageKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.lists.ListDocumentsResponse}
 */
proto.com.vectara.lists.ListDocumentsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.lists.ListDocumentsResponse;
  return proto.com.vectara.lists.ListDocumentsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.lists.ListDocumentsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.lists.ListDocumentsResponse}
 */
proto.com.vectara.lists.ListDocumentsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo;
      reader.readMessage(value,proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.deserializeBinaryFromReader);
      msg.addDocument(value);
      break;
    case 5:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNextPageKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.lists.ListDocumentsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.lists.ListDocumentsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.lists.ListDocumentsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.lists.ListDocumentsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDocumentList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.serializeBinaryToWriter
    );
  }
  f = message.getNextPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      5,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    metadataList: jspb.Message.toObjectList(msg.getMetadataList(),
    attribute_pb.Attribute.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo}
 */
proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo;
  return proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo}
 */
proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = new attribute_pb.Attribute;
      reader.readMessage(value,attribute_pb.Attribute.deserializeBinaryFromReader);
      msg.addMetadata(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMetadataList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      attribute_pb.Attribute.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo} returns this
 */
proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated com.vectara.Attribute metadata = 5;
 * @return {!Array<!proto.com.vectara.Attribute>}
 */
proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.prototype.getMetadataList = function() {
  return /** @type{!Array<!proto.com.vectara.Attribute>} */ (
    jspb.Message.getRepeatedWrapperField(this, attribute_pb.Attribute, 5));
};


/**
 * @param {!Array<!proto.com.vectara.Attribute>} value
 * @return {!proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo} returns this
*/
proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.prototype.setMetadataList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.vectara.Attribute=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.Attribute}
 */
proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.prototype.addMetadata = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.vectara.Attribute, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo} returns this
 */
proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo.prototype.clearMetadataList = function() {
  return this.setMetadataList([]);
};


/**
 * repeated DocumentInfo document = 1;
 * @return {!Array<!proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo>}
 */
proto.com.vectara.lists.ListDocumentsResponse.prototype.getDocumentList = function() {
  return /** @type{!Array<!proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo, 1));
};


/**
 * @param {!Array<!proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo>} value
 * @return {!proto.com.vectara.lists.ListDocumentsResponse} returns this
*/
proto.com.vectara.lists.ListDocumentsResponse.prototype.setDocumentList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo}
 */
proto.com.vectara.lists.ListDocumentsResponse.prototype.addDocument = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.lists.ListDocumentsResponse.DocumentInfo, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.lists.ListDocumentsResponse} returns this
 */
proto.com.vectara.lists.ListDocumentsResponse.prototype.clearDocumentList = function() {
  return this.setDocumentList([]);
};


/**
 * optional bytes next_page_key = 5;
 * @return {string}
 */
proto.com.vectara.lists.ListDocumentsResponse.prototype.getNextPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * optional bytes next_page_key = 5;
 * This is a type-conversion wrapper around `getNextPageKey()`
 * @return {string}
 */
proto.com.vectara.lists.ListDocumentsResponse.prototype.getNextPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNextPageKey()));
};


/**
 * optional bytes next_page_key = 5;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNextPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.lists.ListDocumentsResponse.prototype.getNextPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNextPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.lists.ListDocumentsResponse} returns this
 */
proto.com.vectara.lists.ListDocumentsResponse.prototype.setNextPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.lists.ListDocumentsPageKey.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.lists.ListDocumentsPageKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.lists.ListDocumentsPageKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.lists.ListDocumentsPageKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    corpusId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    request: (f = msg.getRequest()) && proto.com.vectara.lists.ListDocumentsRequest.toObject(includeInstance, f),
    cerberusPageKey: msg.getCerberusPageKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.lists.ListDocumentsPageKey}
 */
proto.com.vectara.lists.ListDocumentsPageKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.lists.ListDocumentsPageKey;
  return proto.com.vectara.lists.ListDocumentsPageKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.lists.ListDocumentsPageKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.lists.ListDocumentsPageKey}
 */
proto.com.vectara.lists.ListDocumentsPageKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCustomerId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 15:
      var value = new proto.com.vectara.lists.ListDocumentsRequest;
      reader.readMessage(value,proto.com.vectara.lists.ListDocumentsRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 20:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setCerberusPageKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.lists.ListDocumentsPageKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.lists.ListDocumentsPageKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.lists.ListDocumentsPageKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.lists.ListDocumentsPageKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.com.vectara.lists.ListDocumentsRequest.serializeBinaryToWriter
    );
  }
  f = message.getCerberusPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      20,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.com.vectara.lists.ListDocumentsPageKey.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.lists.ListDocumentsPageKey} returns this
 */
proto.com.vectara.lists.ListDocumentsPageKey.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 customer_id = 5;
 * @return {number}
 */
proto.com.vectara.lists.ListDocumentsPageKey.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.lists.ListDocumentsPageKey} returns this
 */
proto.com.vectara.lists.ListDocumentsPageKey.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 corpus_id = 10;
 * @return {number}
 */
proto.com.vectara.lists.ListDocumentsPageKey.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.lists.ListDocumentsPageKey} returns this
 */
proto.com.vectara.lists.ListDocumentsPageKey.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional ListDocumentsRequest request = 15;
 * @return {?proto.com.vectara.lists.ListDocumentsRequest}
 */
proto.com.vectara.lists.ListDocumentsPageKey.prototype.getRequest = function() {
  return /** @type{?proto.com.vectara.lists.ListDocumentsRequest} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.lists.ListDocumentsRequest, 15));
};


/**
 * @param {?proto.com.vectara.lists.ListDocumentsRequest|undefined} value
 * @return {!proto.com.vectara.lists.ListDocumentsPageKey} returns this
*/
proto.com.vectara.lists.ListDocumentsPageKey.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.lists.ListDocumentsPageKey} returns this
 */
proto.com.vectara.lists.ListDocumentsPageKey.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.lists.ListDocumentsPageKey.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bytes cerberus_page_key = 20;
 * @return {string}
 */
proto.com.vectara.lists.ListDocumentsPageKey.prototype.getCerberusPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * optional bytes cerberus_page_key = 20;
 * This is a type-conversion wrapper around `getCerberusPageKey()`
 * @return {string}
 */
proto.com.vectara.lists.ListDocumentsPageKey.prototype.getCerberusPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getCerberusPageKey()));
};


/**
 * optional bytes cerberus_page_key = 20;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getCerberusPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.lists.ListDocumentsPageKey.prototype.getCerberusPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getCerberusPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.lists.ListDocumentsPageKey} returns this
 */
proto.com.vectara.lists.ListDocumentsPageKey.prototype.setCerberusPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 20, value);
};


goog.object.extend(exports, proto.com.vectara.lists);
