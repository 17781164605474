import { ReadUsageStatsResponse, UsageStats } from "../../generated_protos/admin/admin_stats_pb";
import { StatusCode } from "../../generated_protos/status_pb";
import { PricingPlanPrice } from "../../types/billing";

/**
 * Format a prescaled currency amount as a localized string.
 */
export const formatPrescaledAmount = ({ amount, currency }: PricingPlanPrice) => {
  // Amounts are sent across as strings to avoid losing precision.
  const numericalAmount = parseFloat(amount);

  const formatter = new Intl.NumberFormat(navigator.language, {
    style: "currency",
    currency
  });
  return formatter.format(numericalAmount);
};

const isOverQuota = (stats: UsageStats.AsObject | undefined) => {
  if (!stats) return false;
  return stats.actualCount > stats.allowedCount;
};

export const isFreeAndOverQuota = (isOnFreePlan: boolean, usageStatsData?: ReadUsageStatsResponse.AsObject) => {
  if (!usageStatsData) return false;

  const { status, storageStats, queryStatsList, indexStatsList } = usageStatsData;

  if (status?.code === StatusCode.NOT_FOUND) return false;

  if (isOverQuota(storageStats) && isOnFreePlan) return true;

  if (queryStatsList?.length) {
    const totalActual = queryStatsList.map((x) => x.actualCount).reduce((prev, next) => prev + next);
    const allowed = queryStatsList.at(0)?.allowedCount || 0;
    if (totalActual > allowed) return true;
  }

  if (indexStatsList?.length) {
    const totalActual = indexStatsList.map((x) => x.actualCount).reduce((prev, next) => prev + next);
    const allowed = indexStatsList.at(0)?.allowedCount || 0;
    if (totalActual > allowed) return true;
  }

  return false;
};
