const PREFIX = "vectara:";

// TODO: Migrate these to use the prefix.
export const CUSTOMER_ID = "customerId";

export const SESSION = "session";

export type AuthMechanism = null | "password" | "googleSso";
export const AUTH_MECHANISM = `${PREFIX}authMechanism`;

export type GoogleSsoAction = null | "registering" | "loggingIn";
export const GOOGLE_SSO_ACTION = `${PREFIX}googleSsoAction`;

export type InstructionState = null | "dismissed";
export const API_KEYS_INSTRUCTION = `${PREFIX}apiKeysInstruction`;

export const AWS_TOKEN = `${PREFIX}awsSubscriptionToken`;
export const REDIRECT_TO_SIGNUP_AWS = `${PREFIX}redirectToSignupAws`;

export const IS_SIDE_NAV_PINNED = `${PREFIX}isSideNavPinned`;
