import { Auth } from "aws-amplify";
import { Amplify } from "@aws-amplify/core";
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { setGoogleSsoAction, setAuthMechanism } from "../localStorage/customer";
import { AUTH_PROVIDER, IS_PROD } from "../../backendConfig";
import { refreshOryJwt } from "./refreshJwt";

const configureAmplify = () => {
  // This region is static.
  const config = {
    aws_project_region: "us-west-2",
    aws_cognito_region: "us-west-2",
    aws_user_pools_id: IS_PROD ? "us-west-2_J0VjBwzoW" : "us-west-2_3RnDEcVmM",
    aws_user_pools_web_client_id: IS_PROD ? "5ukf6v9n9b7mopremhp29c2sto" : "5gvelk8uqprivs48hokfolm7v3",
    oauth: {
      domain: IS_PROD ? "auth-sso.vectara.com" : "auth-sso.vectara.dev",
      scope: ["email", "openid", "profile"],
      redirectSignIn: `${window.location.origin}`,
      redirectSignOut: `${window.location.origin}/`,
      responseType: "code"
    },
    // If the user logs in using password auth then a scoped configuration
    // will be set. If they log out and log back in using Google SSO,
    // then the scoped configuration will cause login to fail. Resetting
    // the scoped configuration here solves the problem.
    // https://docs.amplify.aws/lib/client-configuration/configuring-amplify-categories/q/platform/js/#scoped-configuration
    Auth: {}
  };
  Amplify.configure(config);
};

// Assumes auth mechanism is googleSso.
export const authenticateWithGoogle = async () => {
  if (AUTH_PROVIDER === "ory") {
    await refreshOryJwt();
  } else {
    configureAmplify();
    // Returns a CognitoUser object.
    return await Auth.currentAuthenticatedUser();
  }
};

export const registerWithGoogle = async () => {
  setGoogleSsoAction("registering");
  await initAuthWithGoogle();
};

export const loginWithGoogle = async () => {
  setGoogleSsoAction("loggingIn");
  await initAuthWithGoogle();
};

const initAuthWithGoogle = async () => {
  setAuthMechanism("googleSso");
  if (AUTH_PROVIDER === "cognito") {
    configureAmplify();
    // Sends user to accounts.google.com, which authenticates them and then
    // sends them back to the redirectSignIn URL.
    await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
  }
};
