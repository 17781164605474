import { createContext, useState, useContext, ReactNode, useEffect } from "react";
import { useUserContext } from "./UserContext";
import { GetClientData, SetClientData } from "../admin/ClientKeysEndpoint";
import { ClientDataKey } from "../generated_protos/admin/admin_account_pb";
import { useApiContext } from "./ApiContext";
import { datadogRum } from "@datadog/browser-rum";

export type HistoryCorpus = {
  key: string;
  name: string;
};

interface CorpusHistoryContextType {
  corpusHistory: Array<HistoryCorpus>;
  addCorpusToHistory: (name: string, key: string) => void;
  removeCorpusFromHistory: (key: string) => void;
}

const CorpusHistoryContext = createContext<CorpusHistoryContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

const MAX_RECENTLY_VISITED_CORPORA = 10;

export const CorpusHistoryContextProvider = ({ children }: Props) => {
  const { AdminService } = useApiContext();
  const { customer, getJwt } = useUserContext();
  const [corpusHistory, setCorpusHistory] = useState<Array<HistoryCorpus>>([]);

  /**
   * This will only get called when userContext changes which normally
   * happens when userSession is refreshed through cognito refresh token.
   */
  useEffect(() => {
    const getRecentlyVisitedCorpora = async (customerId: string) => {
      try {
        const jwt = await getJwt();
        const { data } = await GetClientData(jwt, AdminService, customerId, ClientDataKey.CLIENT_DATA_KEY__CORPORA_MRU);
        // Exclude any corpus history entries that don't have a key. This is part of the migration
        // from v1 to v2, because under v1 we stored them with corpus IDs instead of keys.
        const corpora = (((data ? JSON.parse(data) : []) as HistoryCorpus[] | undefined) ?? []).filter(
          ({ key }) => key !== undefined
        );
        setCorpusHistory(corpora);
      } catch (err) {
        datadogRum.addError(err);
        console.log(err);
      }
    };

    setCorpusHistory([]);
    if (customer?.customerId) getRecentlyVisitedCorpora(customer.customerId);
  }, [customer, AdminService, getJwt, setCorpusHistory]);

  const persistRecentlyVisitedCorpora = async (corpora: HistoryCorpus[]) => {
    const jwt = await getJwt();
    if (customer && jwt) {
      try {
        await SetClientData(
          jwt,
          AdminService,
          customer.customerId,
          ClientDataKey.CLIENT_DATA_KEY__CORPORA_MRU,
          JSON.stringify(corpora)
        );
      } catch (e) {
        datadogRum.addError(e);
        console.log(e);
      }
    }
  };

  const addCorpusToHistory = (name: string, key: string) => {
    setCorpusHistory((prev) => {
      // Remove the newly-visited corpus from history and move it to the beginning of the list.
      const updatedCorpusHistory = prev.filter((corpus) => corpus.key !== key);
      updatedCorpusHistory.unshift({ name, key });

      // If the corpus history happens to exceed the limit, remove the one visited the earliest.
      if (updatedCorpusHistory.length > MAX_RECENTLY_VISITED_CORPORA) {
        updatedCorpusHistory.pop();
      }

      persistRecentlyVisitedCorpora(updatedCorpusHistory);
      return updatedCorpusHistory;
    });
  };

  const removeCorpusFromHistory = (key: string) => {
    setCorpusHistory((prev) => {
      const newCorpora = prev.filter((corpus) => corpus.key !== key);
      persistRecentlyVisitedCorpora(newCorpora);
      return newCorpora;
    });
  };

  return (
    <CorpusHistoryContext.Provider value={{ corpusHistory, addCorpusToHistory, removeCorpusFromHistory }}>
      {children}
    </CorpusHistoryContext.Provider>
  );
};

export const useCorpusHistoryContext = () => {
  const context = useContext(CorpusHistoryContext);
  if (context === undefined) {
    throw new Error("useCorpusHistoryContext must be used within a CorpusHistoryContextProvider");
  }
  return context;
};
