import { createContext, useContext, useState, ReactNode, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { analytics } from "./utils/analytics";
import { UserTrackingData, VectaraEventType } from "./utils/snowTypes";
import { FunnelStage, incrementTrackedUserCounts } from "./utils/analyticsStore";

type AnalyticsContextType = {
  userTrackingData?: UserTrackingData;
  setUserTrackingData: (userTrackingData?: UserTrackingData) => void;
  trackGtmEvent: (eventType: VectaraEventType, payload: any) => void;
};

const AnalyticsContext = createContext<AnalyticsContextType | undefined>(undefined);

type Props = {
  children: ReactNode;
};

export const AnalyticsContextProvider = ({ children }: Props) => {
  const [userTrackingData, setUserTrackingData] = useState<UserTrackingData>();
  const location = useLocation();

  useEffect(() => {
    if (userTrackingData) {
      analytics.page({
        email: userTrackingData.email,
        customerId: userTrackingData.customerId,
        userId: userTrackingData.userSub,
        referrer: document.referrer
      });
    } else {
      analytics.page({
        referrer: document.referrer
      });
    }
  }, [location.pathname]);

  const trackGtmEvent = (eventType: VectaraEventType, payload: any) => {
    // GTM wants us to track all the events such as create_corpus, login, signup,
    // search_corpus etc.
    analytics.track(eventType.toString(), payload);

    // Compute if we should send become_ready or become_evaluator.
    let funnelStage: FunnelStage = "none";
    switch (eventType) {
      case "create_corpus":
        funnelStage = incrementTrackedUserCounts(payload.properties.customerId, {
          type: eventType,
          corpusCount: 1,
          queryCount: 0,
          documentCount: 0,
          byteCount: 0
        });
        break;
      case "search_corpus":
        funnelStage = incrementTrackedUserCounts(payload.properties.customerId, {
          type: eventType,
          corpusCount: 0,
          queryCount: 1,
          documentCount: 0,
          byteCount: 0
        });
        break;
      case "data_upload":
        funnelStage = incrementTrackedUserCounts(payload.properties.customerId, {
          type: eventType,
          corpusCount: 0,
          queryCount: 0,
          documentCount: payload.properties.documentCount,
          byteCount: payload.properties.sizeBytes
        });
        break;
      default:
        break;
    }

    switch (funnelStage) {
      case "become_ready":
        analytics.track(funnelStage, {
          event: funnelStage,
          properties: { customerId: payload.properties.customerId }
        });
        break;
      case "become_evaluator":
        analytics.track(funnelStage, {
          event: funnelStage,
          properties: { customerId: payload.properties.customerId }
        });
        break;
      case "none":
      default:
        break;
    }
  };

  return (
    <AnalyticsContext.Provider
      value={{
        userTrackingData,
        setUserTrackingData,
        trackGtmEvent
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);
  if (context === undefined) {
    throw new Error("useAnalyticsContext must be used within a AnalyticsContextProvider");
  }
  return context;
};
