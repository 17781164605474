// source: table.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var common_pb = require('./common_pb.js');
goog.object.extend(proto, common_pb);
goog.exportSymbol('proto.com.vectara.indexing.Table', null, global);
goog.exportSymbol('proto.com.vectara.indexing.Table.Data', null, global);
goog.exportSymbol('proto.com.vectara.indexing.Table.Data.Cell', null, global);
goog.exportSymbol('proto.com.vectara.indexing.Table.Data.Cell.ValueCase', null, global);
goog.exportSymbol('proto.com.vectara.indexing.Table.Data.Header', null, global);
goog.exportSymbol('proto.com.vectara.indexing.Table.Data.Row', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.indexing.Table = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.indexing.Table, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.indexing.Table.displayName = 'proto.com.vectara.indexing.Table';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.indexing.Table.Data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.indexing.Table.Data.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.indexing.Table.Data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.indexing.Table.Data.displayName = 'proto.com.vectara.indexing.Table.Data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.indexing.Table.Data.Header = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.indexing.Table.Data.Header.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.indexing.Table.Data.Header, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.indexing.Table.Data.Header.displayName = 'proto.com.vectara.indexing.Table.Data.Header';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.indexing.Table.Data.Row = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.indexing.Table.Data.Row.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.indexing.Table.Data.Row, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.indexing.Table.Data.Row.displayName = 'proto.com.vectara.indexing.Table.Data.Row';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.indexing.Table.Data.Cell = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.com.vectara.indexing.Table.Data.Cell.oneofGroups_);
};
goog.inherits(proto.com.vectara.indexing.Table.Data.Cell, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.indexing.Table.Data.Cell.displayName = 'proto.com.vectara.indexing.Table.Data.Cell';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.indexing.Table.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.indexing.Table.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.indexing.Table} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.Table.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    title: jspb.Message.getFieldWithDefault(msg, 2, ""),
    data: (f = msg.getData()) && proto.com.vectara.indexing.Table.Data.toObject(includeInstance, f),
    image: (f = msg.getImage()) && common_pb.Image.toObject(includeInstance, f),
    description: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.indexing.Table}
 */
proto.com.vectara.indexing.Table.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.indexing.Table;
  return proto.com.vectara.indexing.Table.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.indexing.Table} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.indexing.Table}
 */
proto.com.vectara.indexing.Table.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 3:
      var value = new proto.com.vectara.indexing.Table.Data;
      reader.readMessage(value,proto.com.vectara.indexing.Table.Data.deserializeBinaryFromReader);
      msg.setData(value);
      break;
    case 4:
      var value = new common_pb.Image;
      reader.readMessage(value,common_pb.Image.deserializeBinaryFromReader);
      msg.setImage(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.indexing.Table.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.indexing.Table.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.indexing.Table} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.Table.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getData();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.com.vectara.indexing.Table.Data.serializeBinaryToWriter
    );
  }
  f = message.getImage();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      common_pb.Image.serializeBinaryToWriter
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.indexing.Table.Data.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.indexing.Table.Data.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.indexing.Table.Data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.indexing.Table.Data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.Table.Data.toObject = function(includeInstance, msg) {
  var f, obj = {
    headerList: jspb.Message.toObjectList(msg.getHeaderList(),
    proto.com.vectara.indexing.Table.Data.Header.toObject, includeInstance),
    rowList: jspb.Message.toObjectList(msg.getRowList(),
    proto.com.vectara.indexing.Table.Data.Row.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.indexing.Table.Data}
 */
proto.com.vectara.indexing.Table.Data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.indexing.Table.Data;
  return proto.com.vectara.indexing.Table.Data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.indexing.Table.Data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.indexing.Table.Data}
 */
proto.com.vectara.indexing.Table.Data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.indexing.Table.Data.Header;
      reader.readMessage(value,proto.com.vectara.indexing.Table.Data.Header.deserializeBinaryFromReader);
      msg.addHeader(value);
      break;
    case 2:
      var value = new proto.com.vectara.indexing.Table.Data.Row;
      reader.readMessage(value,proto.com.vectara.indexing.Table.Data.Row.deserializeBinaryFromReader);
      msg.addRow(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.indexing.Table.Data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.indexing.Table.Data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.indexing.Table.Data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.Table.Data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeaderList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.indexing.Table.Data.Header.serializeBinaryToWriter
    );
  }
  f = message.getRowList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.com.vectara.indexing.Table.Data.Row.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.indexing.Table.Data.Header.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.indexing.Table.Data.Header.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.indexing.Table.Data.Header.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.indexing.Table.Data.Header} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.Table.Data.Header.toObject = function(includeInstance, msg) {
  var f, obj = {
    cellList: jspb.Message.toObjectList(msg.getCellList(),
    proto.com.vectara.indexing.Table.Data.Cell.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.indexing.Table.Data.Header}
 */
proto.com.vectara.indexing.Table.Data.Header.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.indexing.Table.Data.Header;
  return proto.com.vectara.indexing.Table.Data.Header.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.indexing.Table.Data.Header} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.indexing.Table.Data.Header}
 */
proto.com.vectara.indexing.Table.Data.Header.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.indexing.Table.Data.Cell;
      reader.readMessage(value,proto.com.vectara.indexing.Table.Data.Cell.deserializeBinaryFromReader);
      msg.addCell(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.indexing.Table.Data.Header.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.indexing.Table.Data.Header.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.indexing.Table.Data.Header} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.Table.Data.Header.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCellList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.indexing.Table.Data.Cell.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Cell cell = 1;
 * @return {!Array<!proto.com.vectara.indexing.Table.Data.Cell>}
 */
proto.com.vectara.indexing.Table.Data.Header.prototype.getCellList = function() {
  return /** @type{!Array<!proto.com.vectara.indexing.Table.Data.Cell>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.indexing.Table.Data.Cell, 1));
};


/**
 * @param {!Array<!proto.com.vectara.indexing.Table.Data.Cell>} value
 * @return {!proto.com.vectara.indexing.Table.Data.Header} returns this
*/
proto.com.vectara.indexing.Table.Data.Header.prototype.setCellList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.indexing.Table.Data.Cell=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.indexing.Table.Data.Cell}
 */
proto.com.vectara.indexing.Table.Data.Header.prototype.addCell = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.indexing.Table.Data.Cell, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.indexing.Table.Data.Header} returns this
 */
proto.com.vectara.indexing.Table.Data.Header.prototype.clearCellList = function() {
  return this.setCellList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.indexing.Table.Data.Row.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.indexing.Table.Data.Row.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.indexing.Table.Data.Row.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.indexing.Table.Data.Row} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.Table.Data.Row.toObject = function(includeInstance, msg) {
  var f, obj = {
    cellList: jspb.Message.toObjectList(msg.getCellList(),
    proto.com.vectara.indexing.Table.Data.Cell.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.indexing.Table.Data.Row}
 */
proto.com.vectara.indexing.Table.Data.Row.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.indexing.Table.Data.Row;
  return proto.com.vectara.indexing.Table.Data.Row.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.indexing.Table.Data.Row} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.indexing.Table.Data.Row}
 */
proto.com.vectara.indexing.Table.Data.Row.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.indexing.Table.Data.Cell;
      reader.readMessage(value,proto.com.vectara.indexing.Table.Data.Cell.deserializeBinaryFromReader);
      msg.addCell(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.indexing.Table.Data.Row.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.indexing.Table.Data.Row.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.indexing.Table.Data.Row} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.Table.Data.Row.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCellList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.indexing.Table.Data.Cell.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Cell cell = 1;
 * @return {!Array<!proto.com.vectara.indexing.Table.Data.Cell>}
 */
proto.com.vectara.indexing.Table.Data.Row.prototype.getCellList = function() {
  return /** @type{!Array<!proto.com.vectara.indexing.Table.Data.Cell>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.indexing.Table.Data.Cell, 1));
};


/**
 * @param {!Array<!proto.com.vectara.indexing.Table.Data.Cell>} value
 * @return {!proto.com.vectara.indexing.Table.Data.Row} returns this
*/
proto.com.vectara.indexing.Table.Data.Row.prototype.setCellList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.indexing.Table.Data.Cell=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.indexing.Table.Data.Cell}
 */
proto.com.vectara.indexing.Table.Data.Row.prototype.addCell = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.indexing.Table.Data.Cell, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.indexing.Table.Data.Row} returns this
 */
proto.com.vectara.indexing.Table.Data.Row.prototype.clearCellList = function() {
  return this.setCellList([]);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.com.vectara.indexing.Table.Data.Cell.oneofGroups_ = [[1,2,3,4]];

/**
 * @enum {number}
 */
proto.com.vectara.indexing.Table.Data.Cell.ValueCase = {
  VALUE_NOT_SET: 0,
  TEXT_VALUE: 1,
  INT_VALUE: 2,
  FLOAT_VALUE: 3,
  BOOL_VALUE: 4
};

/**
 * @return {proto.com.vectara.indexing.Table.Data.Cell.ValueCase}
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.getValueCase = function() {
  return /** @type {proto.com.vectara.indexing.Table.Data.Cell.ValueCase} */(jspb.Message.computeOneofCase(this, proto.com.vectara.indexing.Table.Data.Cell.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.indexing.Table.Data.Cell.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.indexing.Table.Data.Cell} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.Table.Data.Cell.toObject = function(includeInstance, msg) {
  var f, obj = {
    textValue: jspb.Message.getFieldWithDefault(msg, 1, ""),
    intValue: jspb.Message.getFieldWithDefault(msg, 2, 0),
    floatValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    boolValue: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    colspan: jspb.Message.getFieldWithDefault(msg, 10, 0),
    rowspan: jspb.Message.getFieldWithDefault(msg, 11, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.indexing.Table.Data.Cell}
 */
proto.com.vectara.indexing.Table.Data.Cell.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.indexing.Table.Data.Cell;
  return proto.com.vectara.indexing.Table.Data.Cell.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.indexing.Table.Data.Cell} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.indexing.Table.Data.Cell}
 */
proto.com.vectara.indexing.Table.Data.Cell.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTextValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setIntValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFloatValue(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolValue(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setColspan(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setRowspan(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.indexing.Table.Data.Cell.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.indexing.Table.Data.Cell} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.indexing.Table.Data.Cell.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getColspan();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
  f = message.getRowspan();
  if (f !== 0) {
    writer.writeInt32(
      11,
      f
    );
  }
};


/**
 * optional string text_value = 1;
 * @return {string}
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.getTextValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.indexing.Table.Data.Cell} returns this
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.setTextValue = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.com.vectara.indexing.Table.Data.Cell.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.indexing.Table.Data.Cell} returns this
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.clearTextValue = function() {
  return jspb.Message.setOneofField(this, 1, proto.com.vectara.indexing.Table.Data.Cell.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.hasTextValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int64 int_value = 2;
 * @return {number}
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.getIntValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.indexing.Table.Data.Cell} returns this
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.setIntValue = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.com.vectara.indexing.Table.Data.Cell.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.indexing.Table.Data.Cell} returns this
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.clearIntValue = function() {
  return jspb.Message.setOneofField(this, 2, proto.com.vectara.indexing.Table.Data.Cell.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.hasIntValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double float_value = 3;
 * @return {number}
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.getFloatValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.indexing.Table.Data.Cell} returns this
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.setFloatValue = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.com.vectara.indexing.Table.Data.Cell.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.indexing.Table.Data.Cell} returns this
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.clearFloatValue = function() {
  return jspb.Message.setOneofField(this, 3, proto.com.vectara.indexing.Table.Data.Cell.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.hasFloatValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool bool_value = 4;
 * @return {boolean}
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.getBoolValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.indexing.Table.Data.Cell} returns this
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.setBoolValue = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.com.vectara.indexing.Table.Data.Cell.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.indexing.Table.Data.Cell} returns this
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.clearBoolValue = function() {
  return jspb.Message.setOneofField(this, 4, proto.com.vectara.indexing.Table.Data.Cell.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.hasBoolValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional int32 colspan = 10;
 * @return {number}
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.getColspan = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.indexing.Table.Data.Cell} returns this
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.setColspan = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int32 rowspan = 11;
 * @return {number}
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.getRowspan = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 11, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.indexing.Table.Data.Cell} returns this
 */
proto.com.vectara.indexing.Table.Data.Cell.prototype.setRowspan = function(value) {
  return jspb.Message.setProto3IntField(this, 11, value);
};


/**
 * repeated Header header = 1;
 * @return {!Array<!proto.com.vectara.indexing.Table.Data.Header>}
 */
proto.com.vectara.indexing.Table.Data.prototype.getHeaderList = function() {
  return /** @type{!Array<!proto.com.vectara.indexing.Table.Data.Header>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.indexing.Table.Data.Header, 1));
};


/**
 * @param {!Array<!proto.com.vectara.indexing.Table.Data.Header>} value
 * @return {!proto.com.vectara.indexing.Table.Data} returns this
*/
proto.com.vectara.indexing.Table.Data.prototype.setHeaderList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.indexing.Table.Data.Header=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.indexing.Table.Data.Header}
 */
proto.com.vectara.indexing.Table.Data.prototype.addHeader = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.indexing.Table.Data.Header, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.indexing.Table.Data} returns this
 */
proto.com.vectara.indexing.Table.Data.prototype.clearHeaderList = function() {
  return this.setHeaderList([]);
};


/**
 * repeated Row row = 2;
 * @return {!Array<!proto.com.vectara.indexing.Table.Data.Row>}
 */
proto.com.vectara.indexing.Table.Data.prototype.getRowList = function() {
  return /** @type{!Array<!proto.com.vectara.indexing.Table.Data.Row>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.indexing.Table.Data.Row, 2));
};


/**
 * @param {!Array<!proto.com.vectara.indexing.Table.Data.Row>} value
 * @return {!proto.com.vectara.indexing.Table.Data} returns this
*/
proto.com.vectara.indexing.Table.Data.prototype.setRowList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.com.vectara.indexing.Table.Data.Row=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.indexing.Table.Data.Row}
 */
proto.com.vectara.indexing.Table.Data.prototype.addRow = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.com.vectara.indexing.Table.Data.Row, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.indexing.Table.Data} returns this
 */
proto.com.vectara.indexing.Table.Data.prototype.clearRowList = function() {
  return this.setRowList([]);
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.vectara.indexing.Table.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.indexing.Table} returns this
 */
proto.com.vectara.indexing.Table.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string title = 2;
 * @return {string}
 */
proto.com.vectara.indexing.Table.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.indexing.Table} returns this
 */
proto.com.vectara.indexing.Table.prototype.setTitle = function(value) {
  return jspb.Message.setField(this, 2, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.com.vectara.indexing.Table} returns this
 */
proto.com.vectara.indexing.Table.prototype.clearTitle = function() {
  return jspb.Message.setField(this, 2, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.indexing.Table.prototype.hasTitle = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Data data = 3;
 * @return {?proto.com.vectara.indexing.Table.Data}
 */
proto.com.vectara.indexing.Table.prototype.getData = function() {
  return /** @type{?proto.com.vectara.indexing.Table.Data} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.indexing.Table.Data, 3));
};


/**
 * @param {?proto.com.vectara.indexing.Table.Data|undefined} value
 * @return {!proto.com.vectara.indexing.Table} returns this
*/
proto.com.vectara.indexing.Table.prototype.setData = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.indexing.Table} returns this
 */
proto.com.vectara.indexing.Table.prototype.clearData = function() {
  return this.setData(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.indexing.Table.prototype.hasData = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional com.vectara.Image image = 4;
 * @return {?proto.com.vectara.Image}
 */
proto.com.vectara.indexing.Table.prototype.getImage = function() {
  return /** @type{?proto.com.vectara.Image} */ (
    jspb.Message.getWrapperField(this, common_pb.Image, 4));
};


/**
 * @param {?proto.com.vectara.Image|undefined} value
 * @return {!proto.com.vectara.indexing.Table} returns this
*/
proto.com.vectara.indexing.Table.prototype.setImage = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.indexing.Table} returns this
 */
proto.com.vectara.indexing.Table.prototype.clearImage = function() {
  return this.setImage(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.indexing.Table.prototype.hasImage = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.com.vectara.indexing.Table.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.indexing.Table} returns this
 */
proto.com.vectara.indexing.Table.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


goog.object.extend(exports, proto.com.vectara.indexing);
