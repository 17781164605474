import { VectaraEventType } from "./snowTypes";

const STORE_KEY = "vectara-analytics";

export type FunnelStage = "none" | "become_ready" | "become_evaluator";

export type AnalyticsChangeEvent = {
  type: VectaraEventType;
  corpusCount: number;
  queryCount: number;
  documentCount: number;
  byteCount: number;
};

// An array of TrackedUser is stored in localStorage
type TrackedUser = {
  customerId: number;
  corpusCreated: number;
  queries: number;
  documents: number;
  bytes: number;
  funnelStage: FunnelStage;
};

// Reads the entire array of TrackedUsers from localstorage.
const readTrackedUsers = (): Array<TrackedUser> => {
  const data = localStorage.getItem(STORE_KEY);
  if (data) {
    return JSON.parse(data);
  }
  return [];
};

// This function updates the specific instance of TrackedUser in the TrackedUsers array and pushes
// it to localStorage
const updateTrackedUser = (trackedUser: TrackedUser) => {
  const trackedUsers = readTrackedUsers();
  const index = trackedUsers.findIndex((d: TrackedUser) => d.customerId === trackedUser.customerId);
  if (index > -1) {
    trackedUsers[index] = trackedUser;
  } else {
    trackedUsers.push(trackedUser);
  }
  localStorage.setItem(STORE_KEY, JSON.stringify(trackedUsers));
};

// This function retrieves the specific instance of TrackedUser from the TrackedUsers array
const retrieveTrackedUser = (customerId: number, trackedUsers: Array<TrackedUser>): TrackedUser => {
  if (trackedUsers.length > 0) {
    const trackedUser = trackedUsers.find((d: TrackedUser) => d.customerId === customerId);
    if (trackedUser) {
      return trackedUser;
    }
  }
  return {
    customerId,
    corpusCreated: 0,
    queries: 0,
    documents: 0,
    bytes: 0,
    funnelStage: "none"
  };
};

// This function computes the funnel stage of the user based on the current data.
const computeFunnelStage = (trackedUser: TrackedUser): FunnelStage => {
  if (trackedUser.queries >= 15 && trackedUser.bytes / 1_000_000 >= 0.2) {
    return "become_evaluator";
  } else if (trackedUser.corpusCreated >= 1 && trackedUser.documents >= 1 && trackedUser.queries >= 1) {
    return "become_ready";
  }
  return "none";
};

// Updates the TrackedUsers in localStorage based on the event data and returns the
// current status of customer (become_ready, become_evaluator etc).
export const incrementTrackedUserCounts = (customerId: number, eventData: AnalyticsChangeEvent): FunnelStage => {
  const customerData = retrieveTrackedUser(customerId, readTrackedUsers());
  const prevFunnelStage = customerData.funnelStage;
  if (prevFunnelStage === "become_evaluator") {
    // This account is already an Evaluator. No need to track further.
    return "none";
  }
  switch (eventData.type) {
    case "create_corpus":
      customerData.corpusCreated += eventData.corpusCount;
      break;
    case "search_corpus":
      customerData.queries += eventData.queryCount;
      break;
    case "data_upload":
      customerData.documents += eventData.documentCount;
      customerData.bytes += eventData.byteCount;
      break;
    default:
      break;
  }
  customerData.funnelStage = computeFunnelStage(customerData);
  updateTrackedUser(customerData);
  if (prevFunnelStage === customerData.funnelStage) {
    // The funnel stage has not changed. This is not a new event.
    return "none";
  }
  return customerData.funnelStage;
};
