import { ReadUsageStatsRequest, ReadUsageStatsResponse } from "../generated_protos/admin/admin_stats_pb";
import { AdminServicePromiseClient } from "../generated_protos/services_grpc_web_pb";

/**
 * ReadUsageStats reads the usage statistics for this account.
 * @param {!customerId} string auth cutomer id
 * @return {object} returns object which consist of list of account sizes across clusters.
 */
export const ReadUsageStats = async (
  jwt: string,
  adminService: AdminServicePromiseClient,
  customerId: string
): Promise<ReadUsageStatsResponse.AsObject> => {
  return new Promise((resolve, reject) => {
    if (!jwt) {
      reject("JWT token is invalid");
      return;
    }
    if (!adminService) {
      reject("Could not initialize adminService to make gRPC calls");
      return;
    }

    const request = new ReadUsageStatsRequest();
    const args = {
      "customer-id": customerId,
      authorization: "Bearer " + jwt
    };
    adminService
      .readCurrentPeriodUsageStats(request, args)
      .then((resp) => {
        const respObj = resp.toObject();
        if (!respObj) {
          reject("Invalid response from: readUsageStats");
          return;
        }
        resolve(respObj);
      })
      .catch((e) => reject(e));
  });
};
