// source: admin/admin_feature.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var status_pb = require('../status_pb.js');
goog.object.extend(proto, status_pb);
goog.exportSymbol('proto.com.vectara.admin.Feature', null, global);
goog.exportSymbol('proto.com.vectara.admin.FeatureType', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadFeaturesRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadFeaturesResponse', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures', null, global);
goog.exportSymbol('proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadFeaturesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ReadFeaturesRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ReadFeaturesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadFeaturesRequest.displayName = 'proto.com.vectara.admin.ReadFeaturesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.Feature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.admin.Feature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.Feature.displayName = 'proto.com.vectara.admin.Feature';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadFeaturesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ReadFeaturesResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ReadFeaturesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadFeaturesResponse.displayName = 'proto.com.vectara.admin.ReadFeaturesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.displayName = 'proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.displayName = 'proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ReadFeaturesRequest.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadFeaturesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadFeaturesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadFeaturesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadFeaturesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    readAccountFeatures: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    corpusIdList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadFeaturesRequest}
 */
proto.com.vectara.admin.ReadFeaturesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadFeaturesRequest;
  return proto.com.vectara.admin.ReadFeaturesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadFeaturesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadFeaturesRequest}
 */
proto.com.vectara.admin.ReadFeaturesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadAccountFeatures(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCorpusId(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadFeaturesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadFeaturesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadFeaturesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadFeaturesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getReadAccountFeatures();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getCorpusIdList();
  if (f.length > 0) {
    writer.writePackedUint32(
      10,
      f
    );
  }
};


/**
 * optional bool read_account_features = 5;
 * @return {boolean}
 */
proto.com.vectara.admin.ReadFeaturesRequest.prototype.getReadAccountFeatures = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.admin.ReadFeaturesRequest} returns this
 */
proto.com.vectara.admin.ReadFeaturesRequest.prototype.setReadAccountFeatures = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * repeated uint32 corpus_id = 10;
 * @return {!Array<number>}
 */
proto.com.vectara.admin.ReadFeaturesRequest.prototype.getCorpusIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.com.vectara.admin.ReadFeaturesRequest} returns this
 */
proto.com.vectara.admin.ReadFeaturesRequest.prototype.setCorpusIdList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ReadFeaturesRequest} returns this
 */
proto.com.vectara.admin.ReadFeaturesRequest.prototype.addCorpusId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadFeaturesRequest} returns this
 */
proto.com.vectara.admin.ReadFeaturesRequest.prototype.clearCorpusIdList = function() {
  return this.setCorpusIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.Feature.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.Feature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.Feature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.Feature.toObject = function(includeInstance, msg) {
  var f, obj = {
    feature: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.Feature}
 */
proto.com.vectara.admin.Feature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.Feature;
  return proto.com.vectara.admin.Feature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.Feature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.Feature}
 */
proto.com.vectara.admin.Feature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = /** @type {!proto.com.vectara.admin.FeatureType} */ (reader.readEnum());
      msg.setFeature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.Feature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.Feature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.Feature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.Feature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeature();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional FeatureType feature = 5;
 * @return {!proto.com.vectara.admin.FeatureType}
 */
proto.com.vectara.admin.Feature.prototype.getFeature = function() {
  return /** @type {!proto.com.vectara.admin.FeatureType} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.com.vectara.admin.FeatureType} value
 * @return {!proto.com.vectara.admin.Feature} returns this
 */
proto.com.vectara.admin.Feature.prototype.setFeature = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ReadFeaturesResponse.repeatedFields_ = [10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadFeaturesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadFeaturesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadFeaturesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadFeaturesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    accountFeatures: (f = msg.getAccountFeatures()) && proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.toObject(includeInstance, f),
    corpusFeaturesList: jspb.Message.toObjectList(msg.getCorpusFeaturesList(),
    proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse}
 */
proto.com.vectara.admin.ReadFeaturesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadFeaturesResponse;
  return proto.com.vectara.admin.ReadFeaturesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadFeaturesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse}
 */
proto.com.vectara.admin.ReadFeaturesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = new proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures;
      reader.readMessage(value,proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.deserializeBinaryFromReader);
      msg.setAccountFeatures(value);
      break;
    case 10:
      var value = new proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures;
      reader.readMessage(value,proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.deserializeBinaryFromReader);
      msg.addCorpusFeatures(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadFeaturesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadFeaturesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadFeaturesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadFeaturesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccountFeatures();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.serializeBinaryToWriter
    );
  }
  f = message.getCorpusFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.serializeBinaryToWriter
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.toObject = function(includeInstance, msg) {
  var f, obj = {
    featureList: jspb.Message.toObjectList(msg.getFeatureList(),
    proto.com.vectara.admin.Feature.toObject, includeInstance),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures}
 */
proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures;
  return proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures}
 */
proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 5:
      var value = new proto.com.vectara.admin.Feature;
      reader.readMessage(value,proto.com.vectara.admin.Feature.deserializeBinaryFromReader);
      msg.addFeature(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeatureList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.com.vectara.admin.Feature.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Feature feature = 5;
 * @return {!Array<!proto.com.vectara.admin.Feature>}
 */
proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.prototype.getFeatureList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.Feature>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.Feature, 5));
};


/**
 * @param {!Array<!proto.com.vectara.admin.Feature>} value
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures} returns this
*/
proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.prototype.setFeatureList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.vectara.admin.Feature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.Feature}
 */
proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.prototype.addFeature = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.vectara.admin.Feature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures} returns this
 */
proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.prototype.clearFeatureList = function() {
  return this.setFeatureList([]);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures} returns this
*/
proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures} returns this
 */
proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    featureList: jspb.Message.toObjectList(msg.getFeatureList(),
    proto.com.vectara.admin.Feature.toObject, includeInstance),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures}
 */
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures;
  return proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures}
 */
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 5:
      var value = new proto.com.vectara.admin.Feature;
      reader.readMessage(value,proto.com.vectara.admin.Feature.deserializeBinaryFromReader);
      msg.addFeature(value);
      break;
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getFeatureList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.com.vectara.admin.Feature.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures} returns this
 */
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated Feature feature = 5;
 * @return {!Array<!proto.com.vectara.admin.Feature>}
 */
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.prototype.getFeatureList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.Feature>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.Feature, 5));
};


/**
 * @param {!Array<!proto.com.vectara.admin.Feature>} value
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures} returns this
*/
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.prototype.setFeatureList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.vectara.admin.Feature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.Feature}
 */
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.prototype.addFeature = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.vectara.admin.Feature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures} returns this
 */
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.prototype.clearFeatureList = function() {
  return this.setFeatureList([]);
};


/**
 * optional com.vectara.Status status = 1000;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures} returns this
*/
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1000, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures} returns this
 */
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1000) != null;
};


/**
 * optional AccountFeatures account_features = 5;
 * @return {?proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures}
 */
proto.com.vectara.admin.ReadFeaturesResponse.prototype.getAccountFeatures = function() {
  return /** @type{?proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures, 5));
};


/**
 * @param {?proto.com.vectara.admin.ReadFeaturesResponse.AccountFeatures|undefined} value
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse} returns this
*/
proto.com.vectara.admin.ReadFeaturesResponse.prototype.setAccountFeatures = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse} returns this
 */
proto.com.vectara.admin.ReadFeaturesResponse.prototype.clearAccountFeatures = function() {
  return this.setAccountFeatures(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.admin.ReadFeaturesResponse.prototype.hasAccountFeatures = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated CorpusFeatures corpus_features = 10;
 * @return {!Array<!proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures>}
 */
proto.com.vectara.admin.ReadFeaturesResponse.prototype.getCorpusFeaturesList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures, 10));
};


/**
 * @param {!Array<!proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures>} value
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse} returns this
*/
proto.com.vectara.admin.ReadFeaturesResponse.prototype.setCorpusFeaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures}
 */
proto.com.vectara.admin.ReadFeaturesResponse.prototype.addCorpusFeatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.com.vectara.admin.ReadFeaturesResponse.CorpusFeatures, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ReadFeaturesResponse} returns this
 */
proto.com.vectara.admin.ReadFeaturesResponse.prototype.clearCorpusFeaturesList = function() {
  return this.setCorpusFeaturesList([]);
};


/**
 * @enum {number}
 */
proto.com.vectara.admin.FeatureType = {
  FEATURE__UNKNOWN: 0,
  FEATURE__RECEIVE_SCORE: 1,
  FEATURE__QUERY_BG_VEC: 2,
  FEATURE__CUSTOM_DIMENSIONS: 3,
  FEATURE__ENCODER_SWAPPING: 4,
  FEATURE__TEXTLESS: 5,
  FEATURE__USER_RATE_LIMIT: 6,
  FEATURE__CORPUS_RATE_LIMIT: 7,
  FEATURE__CORPUS_ENCRYPTION_KEY: 8,
  FEATURE__CUSTOMER_MANAGED_ENCRYPTION_KEY: 9,
  FEATURE__DOCUMENT_METADATA: 10,
  FEATURE__DOCUMENT_PART_METADATA: 11,
  FEATURE__PREDICATE_FILTERING: 12,
  FEATURE__RERANKER: 13,
  FEATURE__HIGH_QUALITY_PROMPT: 14
};

goog.object.extend(exports, proto.com.vectara.admin);
