import { BiError, BiSolidRocket } from "react-icons/bi";
import { VuiButtonPrimary, VuiButtonTertiary, VuiIcon } from "@vectara/vectara-ui";
import { useUsageContext } from "../../../contexts/UsageContext";

export const UsageMeter = () => {
  const { isLimited } = useUsageContext();

  if (isLimited) {
    return (
      <VuiButtonPrimary
        data-testid="chromeUsageMeterButton"
        size="m"
        color="danger"
        href="/console/billing/upgrade"
        icon={
          <VuiIcon>
            <BiError />
          </VuiIcon>
        }
      >
        Resource quota exceeded — upgrade now
      </VuiButtonPrimary>
    );
  }

  return (
    <VuiButtonTertiary
      data-testid="chromeUsageMeterButton"
      size="m"
      color="primary"
      href="/console/billing/upgrade"
      icon={
        <VuiIcon>
          <BiSolidRocket />
        </VuiIcon>
      }
    >
      Upgrade
    </VuiButtonTertiary>
  );
};
