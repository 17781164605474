import { useState } from "react";
import { BiBell, BiLogOut, BiSolidUser, BiUser } from "react-icons/bi";
import { useUserContext } from "../../../../contexts/UserContext";
import {
  OptionListItem,
  VuiInfoMenu,
  VuiButtonSecondary,
  VuiCopyButton,
  VuiFlexContainer,
  VuiFlexItem,
  VuiIcon,
  VuiOptionsList
} from "@vectara/vectara-ui";
import { getAuthMechanism } from "../../../../utils/localStorage/customer";
import { useNotificationsContext } from "../../../../contexts/NotificationsContext";
import { AccountSize } from "./AccountSize";
// Contains Tailwind

export const AccountMenu = () => {
  const { customer, deauthenticate, abilities } = useUserContext();
  const { unreadCount } = useNotificationsContext();
  const [isOpen, setIsOpen] = useState(false);
  const authMechanism = getAuthMechanism();

  // TODO: Ensure this branch is never hit.
  if (!customer) {
    return <div>…</div>;
  }

  const { customerId } = customer;

  const userDetail = customer.userName ?? customer.userEmail;

  const obfuscatedUserDetail = userDetail.split("@")[0];
  const TRUNCATION_LENGTH = 24;
  const truncatedUserDetail =
    obfuscatedUserDetail.length > TRUNCATION_LENGTH
      ? `${obfuscatedUserDetail.slice(0, TRUNCATION_LENGTH)}...`
      : obfuscatedUserDetail;

  const info: { title: string; value: React.ReactNode }[] = [
    {
      title: "Customer ID",
      value: (
        <VuiFlexContainer spacing="xs" alignItems="center">
          <VuiFlexItem grow={false}>{customerId}</VuiFlexItem>

          <VuiFlexItem>
            <VuiCopyButton size="xs" value={customerId} />
          </VuiFlexItem>
        </VuiFlexContainer>
      )
    },
    { title: "Email", value: userDetail }
  ];

  if (abilities?.canReadAccountSize) {
    info.push({ title: "Account size", value: <AccountSize /> });
  }

  if (authMechanism === "googleSso") {
    info.unshift({ title: "Single sign-on", value: "Google" });
  }

  const options: OptionListItem<string>[] = [
    {
      value: "notifications",
      label: `Notifications (${unreadCount})`,
      href: "/console/notifications",
      color: "primary",
      testId: "notificationsOption",
      icon: (
        <VuiIcon>
          <BiBell />
        </VuiIcon>
      )
    },
    {
      value: "edit",
      label: "Manage account",
      href: "/console/profile",
      color: "primary",
      testId: "profileOption",
      icon: (
        <VuiIcon>
          <BiUser />
        </VuiIcon>
      )
    },
    {
      value: "signOut",
      label: "Sign out",
      onClick: () => deauthenticate(),
      color: "primary",
      testId: "signOutOption",
      icon: (
        <VuiIcon>
          <BiLogOut />
        </VuiIcon>
      )
    }
  ];

  return (
    <>
      {/* A hidden element, containing complete customer ID - used by UI tests */}
      <div data-testid="headerCustomerId" className="hidden">
        {customerId}
      </div>

      <VuiInfoMenu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        button={
          <VuiButtonSecondary
            data-testid="accountMenuButton"
            // Protect users' privacy in FullStory.
            className="fs-mask"
            color="primary"
            size="m"
            icon={
              <VuiIcon size="m">
                <BiSolidUser />
              </VuiIcon>
            }
          >
            {truncatedUserDetail}
          </VuiButtonSecondary>
        }
        info={info}
      >
        <VuiOptionsList
          size="l"
          // Protect users' privacy in FullStory.
          className="fs-mask"
          onSelectOption={() => {
            setIsOpen(false);
          }}
          options={options}
        />
      </VuiInfoMenu>
    </>
  );
};
