// source: protoc-gen-openapiv2/options/openapiv2.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var google_protobuf_struct_pb = require('google-protobuf/google/protobuf/struct_pb.js');
goog.object.extend(proto, google_protobuf_struct_pb);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.Contact', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.Header', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.Type', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.Info', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.JSONSchemaSimpleTypes', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.License', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.Operation', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.Response', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.Schema', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.Scheme', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.Flow', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.In', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.Type', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger', null, global);
goog.exportSymbol('proto.grpc.gateway.protoc_gen_openapiv2.options.Tag', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.repeatedFields_, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.repeatedFields_, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.Operation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.Operation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.repeatedFields_, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.Header, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.Header.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.Header';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.Response, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.Response.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.Response';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.Info, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.Info.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.Info';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Contact = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.Contact, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.Contact';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.License = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.License, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.License.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.License';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.Schema, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.Schema';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.repeatedFields_, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.Tag, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.Tag';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.repeatedFields_, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes.displayName = 'proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.repeatedFields_ = [5,6,7,12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.toObject = function(includeInstance, msg) {
  var f, obj = {
    swagger: jspb.Message.getFieldWithDefault(msg, 1, ""),
    info: (f = msg.getInfo()) && proto.grpc.gateway.protoc_gen_openapiv2.options.Info.toObject(includeInstance, f),
    host: jspb.Message.getFieldWithDefault(msg, 3, ""),
    basePath: jspb.Message.getFieldWithDefault(msg, 4, ""),
    schemesList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f,
    consumesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    producesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    responsesMap: (f = msg.getResponsesMap()) ? f.toObject(includeInstance, proto.grpc.gateway.protoc_gen_openapiv2.options.Response.toObject) : [],
    securityDefinitions: (f = msg.getSecurityDefinitions()) && proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions.toObject(includeInstance, f),
    securityList: jspb.Message.toObjectList(msg.getSecurityList(),
    proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.toObject, includeInstance),
    tagsList: jspb.Message.toObjectList(msg.getTagsList(),
    proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.toObject, includeInstance),
    externalDocs: (f = msg.getExternalDocs()) && proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.toObject(includeInstance, f),
    extensionsMap: (f = msg.getExtensionsMap()) ? f.toObject(includeInstance, proto.google.protobuf.Value.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSwagger(value);
      break;
    case 2:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.Info;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.Info.deserializeBinaryFromReader);
      msg.setInfo(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setHost(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBasePath(value);
      break;
    case 5:
      var values = /** @type {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.Scheme>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSchemes(values[i]);
      }
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addConsumes(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addProduces(value);
      break;
    case 10:
      var value = msg.getResponsesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.grpc.gateway.protoc_gen_openapiv2.options.Response.deserializeBinaryFromReader, "", new proto.grpc.gateway.protoc_gen_openapiv2.options.Response());
         });
      break;
    case 11:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions.deserializeBinaryFromReader);
      msg.setSecurityDefinitions(value);
      break;
    case 12:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.deserializeBinaryFromReader);
      msg.addSecurity(value);
      break;
    case 13:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.Tag;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.deserializeBinaryFromReader);
      msg.addTags(value);
      break;
    case 14:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.deserializeBinaryFromReader);
      msg.setExternalDocs(value);
      break;
    case 15:
      var value = msg.getExtensionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.google.protobuf.Value.deserializeBinaryFromReader, "", new proto.google.protobuf.Value());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSwagger();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInfo();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.Info.serializeBinaryToWriter
    );
  }
  f = message.getHost();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBasePath();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getSchemesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      5,
      f
    );
  }
  f = message.getConsumesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getProducesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getResponsesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(10, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.grpc.gateway.protoc_gen_openapiv2.options.Response.serializeBinaryToWriter);
  }
  f = message.getSecurityDefinitions();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions.serializeBinaryToWriter
    );
  }
  f = message.getSecurityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.serializeBinaryToWriter
    );
  }
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.serializeBinaryToWriter
    );
  }
  f = message.getExternalDocs();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.serializeBinaryToWriter
    );
  }
  f = message.getExtensionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(15, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.google.protobuf.Value.serializeBinaryToWriter);
  }
};


/**
 * optional string swagger = 1;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.getSwagger = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.setSwagger = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Info info = 2;
 * @return {?proto.grpc.gateway.protoc_gen_openapiv2.options.Info}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.getInfo = function() {
  return /** @type{?proto.grpc.gateway.protoc_gen_openapiv2.options.Info} */ (
    jspb.Message.getWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.Info, 2));
};


/**
 * @param {?proto.grpc.gateway.protoc_gen_openapiv2.options.Info|undefined} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.setInfo = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.clearInfo = function() {
  return this.setInfo(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.hasInfo = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string host = 3;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.getHost = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.setHost = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string base_path = 4;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.getBasePath = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.setBasePath = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * repeated Scheme schemes = 5;
 * @return {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.Scheme>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.getSchemesList = function() {
  return /** @type {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.Scheme>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.Scheme>} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.setSchemesList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Scheme} value
 * @param {number=} opt_index
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.addSchemes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.clearSchemesList = function() {
  return this.setSchemesList([]);
};


/**
 * repeated string consumes = 6;
 * @return {!Array<string>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.getConsumesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.setConsumesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.addConsumes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.clearConsumesList = function() {
  return this.setConsumesList([]);
};


/**
 * repeated string produces = 7;
 * @return {!Array<string>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.getProducesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.setProducesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.addProduces = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.clearProducesList = function() {
  return this.setProducesList([]);
};


/**
 * map<string, Response> responses = 10;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.grpc.gateway.protoc_gen_openapiv2.options.Response>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.getResponsesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.grpc.gateway.protoc_gen_openapiv2.options.Response>} */ (
      jspb.Message.getMapField(this, 10, opt_noLazyCreate,
      proto.grpc.gateway.protoc_gen_openapiv2.options.Response));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.clearResponsesMap = function() {
  this.getResponsesMap().clear();
  return this;};


/**
 * optional SecurityDefinitions security_definitions = 11;
 * @return {?proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.getSecurityDefinitions = function() {
  return /** @type{?proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions} */ (
    jspb.Message.getWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions, 11));
};


/**
 * @param {?proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions|undefined} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.setSecurityDefinitions = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.clearSecurityDefinitions = function() {
  return this.setSecurityDefinitions(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.hasSecurityDefinitions = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated SecurityRequirement security = 12;
 * @return {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.getSecurityList = function() {
  return /** @type{!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement, 12));
};


/**
 * @param {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement>} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.setSecurityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.addSecurity = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.clearSecurityList = function() {
  return this.setSecurityList([]);
};


/**
 * repeated Tag tags = 13;
 * @return {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.Tag>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.getTagsList = function() {
  return /** @type{!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.Tag>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.Tag, 13));
};


/**
 * @param {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.Tag>} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.setTagsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Tag=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Tag}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.addTags = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.grpc.gateway.protoc_gen_openapiv2.options.Tag, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional ExternalDocumentation external_docs = 14;
 * @return {?proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.getExternalDocs = function() {
  return /** @type{?proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation} */ (
    jspb.Message.getWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation, 14));
};


/**
 * @param {?proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation|undefined} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.setExternalDocs = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.clearExternalDocs = function() {
  return this.setExternalDocs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.hasExternalDocs = function() {
  return jspb.Message.getField(this, 14) != null;
};


/**
 * map<string, google.protobuf.Value> extensions = 15;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.google.protobuf.Value>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.getExtensionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.google.protobuf.Value>} */ (
      jspb.Message.getMapField(this, 15, opt_noLazyCreate,
      proto.google.protobuf.Value));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Swagger.prototype.clearExtensionsMap = function() {
  this.getExtensionsMap().clear();
  return this;};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.repeatedFields_ = [1,6,7,10,12];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.toObject = function(includeInstance, msg) {
  var f, obj = {
    tagsList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    summary: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    externalDocs: (f = msg.getExternalDocs()) && proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.toObject(includeInstance, f),
    operationId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    consumesList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    producesList: (f = jspb.Message.getRepeatedField(msg, 7)) == null ? undefined : f,
    responsesMap: (f = msg.getResponsesMap()) ? f.toObject(includeInstance, proto.grpc.gateway.protoc_gen_openapiv2.options.Response.toObject) : [],
    schemesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    deprecated: jspb.Message.getBooleanFieldWithDefault(msg, 11, false),
    securityList: jspb.Message.toObjectList(msg.getSecurityList(),
    proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.toObject, includeInstance),
    extensionsMap: (f = msg.getExtensionsMap()) ? f.toObject(includeInstance, proto.google.protobuf.Value.toObject) : [],
    parameters: (f = msg.getParameters()) && proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.Operation;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addTags(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSummary(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.deserializeBinaryFromReader);
      msg.setExternalDocs(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setOperationId(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.addConsumes(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.addProduces(value);
      break;
    case 9:
      var value = msg.getResponsesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.grpc.gateway.protoc_gen_openapiv2.options.Response.deserializeBinaryFromReader, "", new proto.grpc.gateway.protoc_gen_openapiv2.options.Response());
         });
      break;
    case 10:
      var values = /** @type {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.Scheme>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSchemes(values[i]);
      }
      break;
    case 11:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDeprecated(value);
      break;
    case 12:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.deserializeBinaryFromReader);
      msg.addSecurity(value);
      break;
    case 13:
      var value = msg.getExtensionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.google.protobuf.Value.deserializeBinaryFromReader, "", new proto.google.protobuf.Value());
         });
      break;
    case 14:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.deserializeBinaryFromReader);
      msg.setParameters(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTagsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getSummary();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getExternalDocs();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.serializeBinaryToWriter
    );
  }
  f = message.getOperationId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getConsumesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      6,
      f
    );
  }
  f = message.getProducesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      7,
      f
    );
  }
  f = message.getResponsesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.grpc.gateway.protoc_gen_openapiv2.options.Response.serializeBinaryToWriter);
  }
  f = message.getSchemesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
  f = message.getDeprecated();
  if (f) {
    writer.writeBool(
      11,
      f
    );
  }
  f = message.getSecurityList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.serializeBinaryToWriter
    );
  }
  f = message.getExtensionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(13, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.google.protobuf.Value.serializeBinaryToWriter);
  }
  f = message.getParameters();
  if (f != null) {
    writer.writeMessage(
      14,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.serializeBinaryToWriter
    );
  }
};


/**
 * repeated string tags = 1;
 * @return {!Array<string>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.getTagsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.setTagsList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.addTags = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.clearTagsList = function() {
  return this.setTagsList([]);
};


/**
 * optional string summary = 2;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.getSummary = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.setSummary = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional ExternalDocumentation external_docs = 4;
 * @return {?proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.getExternalDocs = function() {
  return /** @type{?proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation} */ (
    jspb.Message.getWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation, 4));
};


/**
 * @param {?proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation|undefined} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.setExternalDocs = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.clearExternalDocs = function() {
  return this.setExternalDocs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.hasExternalDocs = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string operation_id = 5;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.getOperationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.setOperationId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * repeated string consumes = 6;
 * @return {!Array<string>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.getConsumesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.setConsumesList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.addConsumes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.clearConsumesList = function() {
  return this.setConsumesList([]);
};


/**
 * repeated string produces = 7;
 * @return {!Array<string>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.getProducesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 7));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.setProducesList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.addProduces = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.clearProducesList = function() {
  return this.setProducesList([]);
};


/**
 * map<string, Response> responses = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.grpc.gateway.protoc_gen_openapiv2.options.Response>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.getResponsesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.grpc.gateway.protoc_gen_openapiv2.options.Response>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      proto.grpc.gateway.protoc_gen_openapiv2.options.Response));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.clearResponsesMap = function() {
  this.getResponsesMap().clear();
  return this;};


/**
 * repeated Scheme schemes = 10;
 * @return {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.Scheme>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.getSchemesList = function() {
  return /** @type {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.Scheme>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.Scheme>} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.setSchemesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Scheme} value
 * @param {number=} opt_index
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.addSchemes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.clearSchemesList = function() {
  return this.setSchemesList([]);
};


/**
 * optional bool deprecated = 11;
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.getDeprecated = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 11, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.setDeprecated = function(value) {
  return jspb.Message.setProto3BooleanField(this, 11, value);
};


/**
 * repeated SecurityRequirement security = 12;
 * @return {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.getSecurityList = function() {
  return /** @type{!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement, 12));
};


/**
 * @param {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement>} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.setSecurityList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.addSecurity = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.clearSecurityList = function() {
  return this.setSecurityList([]);
};


/**
 * map<string, google.protobuf.Value> extensions = 13;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.google.protobuf.Value>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.getExtensionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.google.protobuf.Value>} */ (
      jspb.Message.getMapField(this, 13, opt_noLazyCreate,
      proto.google.protobuf.Value));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.clearExtensionsMap = function() {
  this.getExtensionsMap().clear();
  return this;};


/**
 * optional Parameters parameters = 14;
 * @return {?proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.getParameters = function() {
  return /** @type{?proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters} */ (
    jspb.Message.getWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters, 14));
};


/**
 * @param {?proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters|undefined} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.setParameters = function(value) {
  return jspb.Message.setWrapperField(this, 14, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Operation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.clearParameters = function() {
  return this.setParameters(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Operation.prototype.hasParameters = function() {
  return jspb.Message.getField(this, 14) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.toObject = function(includeInstance, msg) {
  var f, obj = {
    headersList: jspb.Message.toObjectList(msg.getHeadersList(),
    proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.deserializeBinaryFromReader);
      msg.addHeaders(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHeadersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.serializeBinaryToWriter
    );
  }
};


/**
 * repeated HeaderParameter headers = 1;
 * @return {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.prototype.getHeadersList = function() {
  return /** @type{!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter, 1));
};


/**
 * @param {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter>} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.prototype.setHeadersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.prototype.addHeaders = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Parameters.prototype.clearHeadersList = function() {
  return this.setHeadersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    type: jspb.Message.getFieldWithDefault(msg, 3, 0),
    format: jspb.Message.getFieldWithDefault(msg, 4, ""),
    required: jspb.Message.getBooleanFieldWithDefault(msg, 5, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRequired(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getRequired();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.Type = {
  UNKNOWN: 0,
  STRING: 1,
  NUMBER: 2,
  INTEGER: 3,
  BOOLEAN: 4
};

/**
 * optional string name = 1;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Type type = 3;
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.Type}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.prototype.getType = function() {
  return /** @type {!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.Type} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.Type} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string format = 4;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional bool required = 5;
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.prototype.getRequired = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.HeaderParameter.prototype.setRequired = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Header.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Header} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, ""),
    format: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pb_default: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pattern: jspb.Message.getFieldWithDefault(msg, 13, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Header}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.Header;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Header.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Header} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Header}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefault(value);
      break;
    case 13:
      var value = /** @type {string} */ (reader.readString());
      msg.setPattern(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.Header.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Header} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDefault();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPattern();
  if (f.length > 0) {
    writer.writeString(
      13,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Header} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string type = 2;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header.prototype.getType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Header} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header.prototype.setType = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string format = 3;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Header} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string default = 6;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header.prototype.getDefault = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Header} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header.prototype.setDefault = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string pattern = 13;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header.prototype.getPattern = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 13, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Header} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Header.prototype.setPattern = function(value) {
  return jspb.Message.setProto3StringField(this, 13, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Response.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Response} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    schema: (f = msg.getSchema()) && proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.toObject(includeInstance, f),
    headersMap: (f = msg.getHeadersMap()) ? f.toObject(includeInstance, proto.grpc.gateway.protoc_gen_openapiv2.options.Header.toObject) : [],
    examplesMap: (f = msg.getExamplesMap()) ? f.toObject(includeInstance, undefined) : [],
    extensionsMap: (f = msg.getExtensionsMap()) ? f.toObject(includeInstance, proto.google.protobuf.Value.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Response}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.Response;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Response.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Response} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Response}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.Schema;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.deserializeBinaryFromReader);
      msg.setSchema(value);
      break;
    case 3:
      var value = msg.getHeadersMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.grpc.gateway.protoc_gen_openapiv2.options.Header.deserializeBinaryFromReader, "", new proto.grpc.gateway.protoc_gen_openapiv2.options.Header());
         });
      break;
    case 4:
      var value = msg.getExamplesMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    case 5:
      var value = msg.getExtensionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.google.protobuf.Value.deserializeBinaryFromReader, "", new proto.google.protobuf.Value());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.Response.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Response} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSchema();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.serializeBinaryToWriter
    );
  }
  f = message.getHeadersMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(3, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.grpc.gateway.protoc_gen_openapiv2.options.Header.serializeBinaryToWriter);
  }
  f = message.getExamplesMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
  f = message.getExtensionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(5, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.google.protobuf.Value.serializeBinaryToWriter);
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Response} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Schema schema = 2;
 * @return {?proto.grpc.gateway.protoc_gen_openapiv2.options.Schema}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.prototype.getSchema = function() {
  return /** @type{?proto.grpc.gateway.protoc_gen_openapiv2.options.Schema} */ (
    jspb.Message.getWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.Schema, 2));
};


/**
 * @param {?proto.grpc.gateway.protoc_gen_openapiv2.options.Schema|undefined} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Response} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.prototype.setSchema = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Response} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.prototype.clearSchema = function() {
  return this.setSchema(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.prototype.hasSchema = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * map<string, Header> headers = 3;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.grpc.gateway.protoc_gen_openapiv2.options.Header>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.prototype.getHeadersMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.grpc.gateway.protoc_gen_openapiv2.options.Header>} */ (
      jspb.Message.getMapField(this, 3, opt_noLazyCreate,
      proto.grpc.gateway.protoc_gen_openapiv2.options.Header));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Response} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.prototype.clearHeadersMap = function() {
  this.getHeadersMap().clear();
  return this;};


/**
 * map<string, string> examples = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.prototype.getExamplesMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Response} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.prototype.clearExamplesMap = function() {
  this.getExamplesMap().clear();
  return this;};


/**
 * map<string, google.protobuf.Value> extensions = 5;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.google.protobuf.Value>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.prototype.getExtensionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.google.protobuf.Value>} */ (
      jspb.Message.getMapField(this, 5, opt_noLazyCreate,
      proto.google.protobuf.Value));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Response} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Response.prototype.clearExtensionsMap = function() {
  this.getExtensionsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Info.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Info} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    termsOfService: jspb.Message.getFieldWithDefault(msg, 3, ""),
    contact: (f = msg.getContact()) && proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.toObject(includeInstance, f),
    license: (f = msg.getLicense()) && proto.grpc.gateway.protoc_gen_openapiv2.options.License.toObject(includeInstance, f),
    version: jspb.Message.getFieldWithDefault(msg, 6, ""),
    extensionsMap: (f = msg.getExtensionsMap()) ? f.toObject(includeInstance, proto.google.protobuf.Value.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Info}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.Info;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Info.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Info} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Info}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTermsOfService(value);
      break;
    case 4:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.Contact;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.deserializeBinaryFromReader);
      msg.setContact(value);
      break;
    case 5:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.License;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.License.deserializeBinaryFromReader);
      msg.setLicense(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setVersion(value);
      break;
    case 7:
      var value = msg.getExtensionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.google.protobuf.Value.deserializeBinaryFromReader, "", new proto.google.protobuf.Value());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.Info.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Info} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTermsOfService();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getContact();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.serializeBinaryToWriter
    );
  }
  f = message.getLicense();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.License.serializeBinaryToWriter
    );
  }
  f = message.getVersion();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getExtensionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(7, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.google.protobuf.Value.serializeBinaryToWriter);
  }
};


/**
 * optional string title = 1;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Info} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Info} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string terms_of_service = 3;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.getTermsOfService = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Info} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.setTermsOfService = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional Contact contact = 4;
 * @return {?proto.grpc.gateway.protoc_gen_openapiv2.options.Contact}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.getContact = function() {
  return /** @type{?proto.grpc.gateway.protoc_gen_openapiv2.options.Contact} */ (
    jspb.Message.getWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.Contact, 4));
};


/**
 * @param {?proto.grpc.gateway.protoc_gen_openapiv2.options.Contact|undefined} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Info} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.setContact = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Info} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.clearContact = function() {
  return this.setContact(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.hasContact = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional License license = 5;
 * @return {?proto.grpc.gateway.protoc_gen_openapiv2.options.License}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.getLicense = function() {
  return /** @type{?proto.grpc.gateway.protoc_gen_openapiv2.options.License} */ (
    jspb.Message.getWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.License, 5));
};


/**
 * @param {?proto.grpc.gateway.protoc_gen_openapiv2.options.License|undefined} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Info} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.setLicense = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Info} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.clearLicense = function() {
  return this.setLicense(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.hasLicense = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string version = 6;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.getVersion = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Info} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.setVersion = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * map<string, google.protobuf.Value> extensions = 7;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.google.protobuf.Value>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.getExtensionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.google.protobuf.Value>} */ (
      jspb.Message.getMapField(this, 7, opt_noLazyCreate,
      proto.google.protobuf.Value));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Info} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Info.prototype.clearExtensionsMap = function() {
  this.getExtensionsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Contact} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Contact}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.Contact;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Contact} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Contact}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Contact} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Contact} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Contact} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string email = 3;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Contact} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Contact.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.License.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.License.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.License} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.License.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.License}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.License.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.License;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.License.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.License} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.License}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.License.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.License.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.License.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.License} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.License.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.License.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.License} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.License.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.License.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.License} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.License.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.toObject = function(includeInstance, msg) {
  var f, obj = {
    description: jspb.Message.getFieldWithDefault(msg, 1, ""),
    url: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string description = 1;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string url = 2;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Schema} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.toObject = function(includeInstance, msg) {
  var f, obj = {
    jsonSchema: (f = msg.getJsonSchema()) && proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.toObject(includeInstance, f),
    discriminator: jspb.Message.getFieldWithDefault(msg, 2, ""),
    readOnly: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    externalDocs: (f = msg.getExternalDocs()) && proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.toObject(includeInstance, f),
    example: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Schema}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.Schema;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Schema} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Schema}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.deserializeBinaryFromReader);
      msg.setJsonSchema(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDiscriminator(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadOnly(value);
      break;
    case 5:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.deserializeBinaryFromReader);
      msg.setExternalDocs(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setExample(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Schema} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJsonSchema();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.serializeBinaryToWriter
    );
  }
  f = message.getDiscriminator();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getReadOnly();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getExternalDocs();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.serializeBinaryToWriter
    );
  }
  f = message.getExample();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional JSONSchema json_schema = 1;
 * @return {?proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.prototype.getJsonSchema = function() {
  return /** @type{?proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} */ (
    jspb.Message.getWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema, 1));
};


/**
 * @param {?proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema|undefined} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Schema} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.prototype.setJsonSchema = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Schema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.prototype.clearJsonSchema = function() {
  return this.setJsonSchema(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.prototype.hasJsonSchema = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string discriminator = 2;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.prototype.getDiscriminator = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Schema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.prototype.setDiscriminator = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool read_only = 3;
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.prototype.getReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Schema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.prototype.setReadOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional ExternalDocumentation external_docs = 5;
 * @return {?proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.prototype.getExternalDocs = function() {
  return /** @type{?proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation} */ (
    jspb.Message.getWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation, 5));
};


/**
 * @param {?proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation|undefined} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Schema} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.prototype.setExternalDocs = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Schema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.prototype.clearExternalDocs = function() {
  return this.setExternalDocs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.prototype.hasExternalDocs = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string example = 6;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.prototype.getExample = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Schema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Schema.prototype.setExample = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.repeatedFields_ = [26,34,35,46];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.toObject = function(includeInstance, msg) {
  var f, obj = {
    ref: jspb.Message.getFieldWithDefault(msg, 3, ""),
    title: jspb.Message.getFieldWithDefault(msg, 5, ""),
    description: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pb_default: jspb.Message.getFieldWithDefault(msg, 7, ""),
    readOnly: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    example: jspb.Message.getFieldWithDefault(msg, 9, ""),
    multipleOf: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    maximum: jspb.Message.getFloatingPointFieldWithDefault(msg, 11, 0.0),
    exclusiveMaximum: jspb.Message.getBooleanFieldWithDefault(msg, 12, false),
    minimum: jspb.Message.getFloatingPointFieldWithDefault(msg, 13, 0.0),
    exclusiveMinimum: jspb.Message.getBooleanFieldWithDefault(msg, 14, false),
    maxLength: jspb.Message.getFieldWithDefault(msg, 15, 0),
    minLength: jspb.Message.getFieldWithDefault(msg, 16, 0),
    pattern: jspb.Message.getFieldWithDefault(msg, 17, ""),
    maxItems: jspb.Message.getFieldWithDefault(msg, 20, 0),
    minItems: jspb.Message.getFieldWithDefault(msg, 21, 0),
    uniqueItems: jspb.Message.getBooleanFieldWithDefault(msg, 22, false),
    maxProperties: jspb.Message.getFieldWithDefault(msg, 24, 0),
    minProperties: jspb.Message.getFieldWithDefault(msg, 25, 0),
    requiredList: (f = jspb.Message.getRepeatedField(msg, 26)) == null ? undefined : f,
    arrayList: (f = jspb.Message.getRepeatedField(msg, 34)) == null ? undefined : f,
    typeList: (f = jspb.Message.getRepeatedField(msg, 35)) == null ? undefined : f,
    format: jspb.Message.getFieldWithDefault(msg, 36, ""),
    enumList: (f = jspb.Message.getRepeatedField(msg, 46)) == null ? undefined : f,
    fieldConfiguration: (f = msg.getFieldConfiguration()) && proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration.toObject(includeInstance, f),
    extensionsMap: (f = msg.getExtensionsMap()) ? f.toObject(includeInstance, proto.google.protobuf.Value.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRef(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setDefault(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setReadOnly(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setExample(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMultipleOf(value);
      break;
    case 11:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaximum(value);
      break;
    case 12:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExclusiveMaximum(value);
      break;
    case 13:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMinimum(value);
      break;
    case 14:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setExclusiveMinimum(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMaxLength(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMinLength(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setPattern(value);
      break;
    case 20:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMaxItems(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMinItems(value);
      break;
    case 22:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setUniqueItems(value);
      break;
    case 24:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMaxProperties(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setMinProperties(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.addRequired(value);
      break;
    case 34:
      var value = /** @type {string} */ (reader.readString());
      msg.addArray(value);
      break;
    case 35:
      var values = /** @type {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.JSONSchemaSimpleTypes>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addType(values[i]);
      }
      break;
    case 36:
      var value = /** @type {string} */ (reader.readString());
      msg.setFormat(value);
      break;
    case 46:
      var value = /** @type {string} */ (reader.readString());
      msg.addEnum(value);
      break;
    case 1001:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration.deserializeBinaryFromReader);
      msg.setFieldConfiguration(value);
      break;
    case 48:
      var value = msg.getExtensionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.google.protobuf.Value.deserializeBinaryFromReader, "", new proto.google.protobuf.Value());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRef();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTitle();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getDefault();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getReadOnly();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getExample();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
  f = message.getMultipleOf();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getMaximum();
  if (f !== 0.0) {
    writer.writeDouble(
      11,
      f
    );
  }
  f = message.getExclusiveMaximum();
  if (f) {
    writer.writeBool(
      12,
      f
    );
  }
  f = message.getMinimum();
  if (f !== 0.0) {
    writer.writeDouble(
      13,
      f
    );
  }
  f = message.getExclusiveMinimum();
  if (f) {
    writer.writeBool(
      14,
      f
    );
  }
  f = message.getMaxLength();
  if (f !== 0) {
    writer.writeUint64(
      15,
      f
    );
  }
  f = message.getMinLength();
  if (f !== 0) {
    writer.writeUint64(
      16,
      f
    );
  }
  f = message.getPattern();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getMaxItems();
  if (f !== 0) {
    writer.writeUint64(
      20,
      f
    );
  }
  f = message.getMinItems();
  if (f !== 0) {
    writer.writeUint64(
      21,
      f
    );
  }
  f = message.getUniqueItems();
  if (f) {
    writer.writeBool(
      22,
      f
    );
  }
  f = message.getMaxProperties();
  if (f !== 0) {
    writer.writeUint64(
      24,
      f
    );
  }
  f = message.getMinProperties();
  if (f !== 0) {
    writer.writeUint64(
      25,
      f
    );
  }
  f = message.getRequiredList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      26,
      f
    );
  }
  f = message.getArrayList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      34,
      f
    );
  }
  f = message.getTypeList();
  if (f.length > 0) {
    writer.writePackedEnum(
      35,
      f
    );
  }
  f = message.getFormat();
  if (f.length > 0) {
    writer.writeString(
      36,
      f
    );
  }
  f = message.getEnumList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      46,
      f
    );
  }
  f = message.getFieldConfiguration();
  if (f != null) {
    writer.writeMessage(
      1001,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration.serializeBinaryToWriter
    );
  }
  f = message.getExtensionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(48, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.google.protobuf.Value.serializeBinaryToWriter);
  }
};


/**
 * @enum {number}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.JSONSchemaSimpleTypes = {
  UNKNOWN: 0,
  ARRAY: 1,
  BOOLEAN: 2,
  INTEGER: 3,
  NULL: 4,
  NUMBER: 5,
  OBJECT: 6,
  STRING: 7
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration.toObject = function(includeInstance, msg) {
  var f, obj = {
    pathParamName: jspb.Message.getFieldWithDefault(msg, 47, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 47:
      var value = /** @type {string} */ (reader.readString());
      msg.setPathParamName(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPathParamName();
  if (f.length > 0) {
    writer.writeString(
      47,
      f
    );
  }
};


/**
 * optional string path_param_name = 47;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration.prototype.getPathParamName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 47, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration.prototype.setPathParamName = function(value) {
  return jspb.Message.setProto3StringField(this, 47, value);
};


/**
 * optional string ref = 3;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getRef = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setRef = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string title = 5;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getTitle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setTitle = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string description = 6;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string default = 7;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getDefault = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setDefault = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool read_only = 8;
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getReadOnly = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setReadOnly = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional string example = 9;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getExample = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setExample = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};


/**
 * optional double multiple_of = 10;
 * @return {number}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getMultipleOf = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setMultipleOf = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional double maximum = 11;
 * @return {number}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getMaximum = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 11, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setMaximum = function(value) {
  return jspb.Message.setProto3FloatField(this, 11, value);
};


/**
 * optional bool exclusive_maximum = 12;
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getExclusiveMaximum = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 12, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setExclusiveMaximum = function(value) {
  return jspb.Message.setProto3BooleanField(this, 12, value);
};


/**
 * optional double minimum = 13;
 * @return {number}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getMinimum = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 13, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setMinimum = function(value) {
  return jspb.Message.setProto3FloatField(this, 13, value);
};


/**
 * optional bool exclusive_minimum = 14;
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getExclusiveMinimum = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 14, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setExclusiveMinimum = function(value) {
  return jspb.Message.setProto3BooleanField(this, 14, value);
};


/**
 * optional uint64 max_length = 15;
 * @return {number}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getMaxLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setMaxLength = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional uint64 min_length = 16;
 * @return {number}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getMinLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setMinLength = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string pattern = 17;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getPattern = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setPattern = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional uint64 max_items = 20;
 * @return {number}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getMaxItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setMaxItems = function(value) {
  return jspb.Message.setProto3IntField(this, 20, value);
};


/**
 * optional uint64 min_items = 21;
 * @return {number}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getMinItems = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setMinItems = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional bool unique_items = 22;
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getUniqueItems = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 22, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setUniqueItems = function(value) {
  return jspb.Message.setProto3BooleanField(this, 22, value);
};


/**
 * optional uint64 max_properties = 24;
 * @return {number}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getMaxProperties = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 24, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setMaxProperties = function(value) {
  return jspb.Message.setProto3IntField(this, 24, value);
};


/**
 * optional uint64 min_properties = 25;
 * @return {number}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getMinProperties = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setMinProperties = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * repeated string required = 26;
 * @return {!Array<string>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getRequiredList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 26));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setRequiredList = function(value) {
  return jspb.Message.setField(this, 26, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.addRequired = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 26, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.clearRequiredList = function() {
  return this.setRequiredList([]);
};


/**
 * repeated string array = 34;
 * @return {!Array<string>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getArrayList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 34));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setArrayList = function(value) {
  return jspb.Message.setField(this, 34, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.addArray = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 34, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.clearArrayList = function() {
  return this.setArrayList([]);
};


/**
 * repeated JSONSchemaSimpleTypes type = 35;
 * @return {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.JSONSchemaSimpleTypes>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getTypeList = function() {
  return /** @type {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.JSONSchemaSimpleTypes>} */ (jspb.Message.getRepeatedField(this, 35));
};


/**
 * @param {!Array<!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.JSONSchemaSimpleTypes>} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setTypeList = function(value) {
  return jspb.Message.setField(this, 35, value || []);
};


/**
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.JSONSchemaSimpleTypes} value
 * @param {number=} opt_index
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.addType = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 35, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.clearTypeList = function() {
  return this.setTypeList([]);
};


/**
 * optional string format = 36;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getFormat = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 36, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setFormat = function(value) {
  return jspb.Message.setProto3StringField(this, 36, value);
};


/**
 * repeated string enum = 46;
 * @return {!Array<string>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getEnumList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 46));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setEnumList = function(value) {
  return jspb.Message.setField(this, 46, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.addEnum = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 46, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.clearEnumList = function() {
  return this.setEnumList([]);
};


/**
 * optional FieldConfiguration field_configuration = 1001;
 * @return {?proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getFieldConfiguration = function() {
  return /** @type{?proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration} */ (
    jspb.Message.getWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration, 1001));
};


/**
 * @param {?proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.FieldConfiguration|undefined} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.setFieldConfiguration = function(value) {
  return jspb.Message.setWrapperField(this, 1001, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.clearFieldConfiguration = function() {
  return this.setFieldConfiguration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.hasFieldConfiguration = function() {
  return jspb.Message.getField(this, 1001) != null;
};


/**
 * map<string, google.protobuf.Value> extensions = 48;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.google.protobuf.Value>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.getExtensionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.google.protobuf.Value>} */ (
      jspb.Message.getMapField(this, 48, opt_noLazyCreate,
      proto.google.protobuf.Value));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.JSONSchema.prototype.clearExtensionsMap = function() {
  this.getExtensionsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Tag} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.toObject = function(includeInstance, msg) {
  var f, obj = {
    name: jspb.Message.getFieldWithDefault(msg, 1, ""),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    externalDocs: (f = msg.getExternalDocs()) && proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.toObject(includeInstance, f),
    extensionsMap: (f = msg.getExtensionsMap()) ? f.toObject(includeInstance, proto.google.protobuf.Value.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Tag}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.Tag;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Tag} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Tag}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.deserializeBinaryFromReader);
      msg.setExternalDocs(value);
      break;
    case 4:
      var value = msg.getExtensionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.google.protobuf.Value.deserializeBinaryFromReader, "", new proto.google.protobuf.Value());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Tag} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getExternalDocs();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation.serializeBinaryToWriter
    );
  }
  f = message.getExtensionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(4, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.google.protobuf.Value.serializeBinaryToWriter);
  }
};


/**
 * optional string name = 1;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Tag} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Tag} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional ExternalDocumentation external_docs = 3;
 * @return {?proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.prototype.getExternalDocs = function() {
  return /** @type{?proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation} */ (
    jspb.Message.getWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation, 3));
};


/**
 * @param {?proto.grpc.gateway.protoc_gen_openapiv2.options.ExternalDocumentation|undefined} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Tag} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.prototype.setExternalDocs = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Tag} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.prototype.clearExternalDocs = function() {
  return this.setExternalDocs(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.prototype.hasExternalDocs = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * map<string, google.protobuf.Value> extensions = 4;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.google.protobuf.Value>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.prototype.getExtensionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.google.protobuf.Value>} */ (
      jspb.Message.getMapField(this, 4, opt_noLazyCreate,
      proto.google.protobuf.Value));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Tag} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Tag.prototype.clearExtensionsMap = function() {
  this.getExtensionsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions.toObject = function(includeInstance, msg) {
  var f, obj = {
    securityMap: (f = msg.getSecurityMap()) ? f.toObject(includeInstance, proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getSecurityMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.deserializeBinaryFromReader, "", new proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSecurityMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.serializeBinaryToWriter);
  }
};


/**
 * map<string, SecurityScheme> security = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions.prototype.getSecurityMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityDefinitions.prototype.clearSecurityMap = function() {
  this.getSecurityMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.toObject = function(includeInstance, msg) {
  var f, obj = {
    type: jspb.Message.getFieldWithDefault(msg, 1, 0),
    description: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    pb_in: jspb.Message.getFieldWithDefault(msg, 4, 0),
    flow: jspb.Message.getFieldWithDefault(msg, 5, 0),
    authorizationUrl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    tokenUrl: jspb.Message.getFieldWithDefault(msg, 7, ""),
    scopes: (f = msg.getScopes()) && proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes.toObject(includeInstance, f),
    extensionsMap: (f = msg.getExtensionsMap()) ? f.toObject(includeInstance, proto.google.protobuf.Value.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.Type} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.In} */ (reader.readEnum());
      msg.setIn(value);
      break;
    case 5:
      var value = /** @type {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.Flow} */ (reader.readEnum());
      msg.setFlow(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAuthorizationUrl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenUrl(value);
      break;
    case 8:
      var value = new proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes;
      reader.readMessage(value,proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes.deserializeBinaryFromReader);
      msg.setScopes(value);
      break;
    case 9:
      var value = msg.getExtensionsMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.google.protobuf.Value.deserializeBinaryFromReader, "", new proto.google.protobuf.Value());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getIn();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getFlow();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getAuthorizationUrl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTokenUrl();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getScopes();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes.serializeBinaryToWriter
    );
  }
  f = message.getExtensionsMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(9, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.google.protobuf.Value.serializeBinaryToWriter);
  }
};


/**
 * @enum {number}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.Type = {
  TYPE_INVALID: 0,
  TYPE_BASIC: 1,
  TYPE_API_KEY: 2,
  TYPE_OAUTH2: 3
};

/**
 * @enum {number}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.In = {
  IN_INVALID: 0,
  IN_QUERY: 1,
  IN_HEADER: 2
};

/**
 * @enum {number}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.Flow = {
  FLOW_INVALID: 0,
  FLOW_IMPLICIT: 1,
  FLOW_PASSWORD: 2,
  FLOW_APPLICATION: 3,
  FLOW_ACCESS_CODE: 4
};

/**
 * optional Type type = 1;
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.Type}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.getType = function() {
  return /** @type {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.Type} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.Type} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string description = 2;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional In in = 4;
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.In}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.getIn = function() {
  return /** @type {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.In} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.In} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.setIn = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional Flow flow = 5;
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.Flow}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.getFlow = function() {
  return /** @type {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.Flow} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.Flow} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.setFlow = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string authorization_url = 6;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.getAuthorizationUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.setAuthorizationUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string token_url = 7;
 * @return {string}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.getTokenUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.setTokenUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional Scopes scopes = 8;
 * @return {?proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.getScopes = function() {
  return /** @type{?proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes} */ (
    jspb.Message.getWrapperField(this, proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes, 8));
};


/**
 * @param {?proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes|undefined} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme} returns this
*/
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.setScopes = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.clearScopes = function() {
  return this.setScopes(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.hasScopes = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * map<string, google.protobuf.Value> extensions = 9;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.google.protobuf.Value>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.getExtensionsMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.google.protobuf.Value>} */ (
      jspb.Message.getMapField(this, 9, opt_noLazyCreate,
      proto.google.protobuf.Value));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityScheme.prototype.clearExtensionsMap = function() {
  this.getExtensionsMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.toObject = function(includeInstance, msg) {
  var f, obj = {
    securityRequirementMap: (f = msg.getSecurityRequirementMap()) ? f.toObject(includeInstance, proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getSecurityRequirementMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.deserializeBinaryFromReader, "", new proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue());
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSecurityRequirementMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.serializeBinaryToWriter);
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.toObject = function(includeInstance, msg) {
  var f, obj = {
    scopeList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addScope(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScopeList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string scope = 1;
 * @return {!Array<string>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.prototype.getScopeList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.prototype.setScopeList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.prototype.addScope = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue.prototype.clearScopeList = function() {
  return this.setScopeList([]);
};


/**
 * map<string, SecurityRequirementValue> security_requirement = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.prototype.getSecurityRequirementMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.SecurityRequirementValue));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.SecurityRequirement.prototype.clearSecurityRequirementMap = function() {
  this.getSecurityRequirementMap().clear();
  return this;};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes.prototype.toObject = function(opt_includeInstance) {
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes.toObject = function(includeInstance, msg) {
  var f, obj = {
    scopeMap: (f = msg.getScopeMap()) ? f.toObject(includeInstance, undefined) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes;
  return proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getScopeMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getScopeMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString);
  }
};


/**
 * map<string, string> scope = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,string>}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes.prototype.getScopeMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,string>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      null));
};


/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes} returns this
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Scopes.prototype.clearScopeMap = function() {
  this.getScopeMap().clear();
  return this;};


/**
 * @enum {number}
 */
proto.grpc.gateway.protoc_gen_openapiv2.options.Scheme = {
  UNKNOWN: 0,
  HTTP: 1,
  HTTPS: 2,
  WS: 3,
  WSS: 4
};

goog.object.extend(exports, proto.grpc.gateway.protoc_gen_openapiv2.options);
