import { IS_SIDE_NAV_PINNED } from "./keys";

export const getIsSideNavPinned = (): boolean => {
  return localStorage.getItem(IS_SIDE_NAV_PINNED) === "true";
};

export const setIsSideNavPinned = (isPinned: boolean) => {
  localStorage.setItem(IS_SIDE_NAV_PINNED, isPinned.toString());
};

export const clearIsSideNavPinned = () => {
  localStorage.removeItem(IS_SIDE_NAV_PINNED);
};
