import { PASSWORD_TOO_SHORT_CODE, PASSWORD_FOUND_IN_DATA_BREACH_CODE } from "../oryAuthErrors";

const EXPECTED_ERRORS = [PASSWORD_TOO_SHORT_CODE, PASSWORD_FOUND_IN_DATA_BREACH_CODE];

export const extractOryErrorMessage = (error: any) => {
  const {
    response: { status, reason, data }
  } = error;

  switch (status) {
    case 403:
      return reason;

    case 400:
      for (const node of data.ui.nodes) {
        const { attributes, messages } = node;

        if (attributes?.autocomplete === "new-password" && messages) {
          const expectedErrorMessage = messages.find((message: any) => {
            const messageType = message?.type;
            const messageId = message?.id;
            return messageType === "error" && EXPECTED_ERRORS.includes(messageId);
          });

          return expectedErrorMessage?.text;
        }
      }
      break;

    default:
      return undefined;
  }
};
