import { IS_PROD } from "../backendConfig";

const getStackTrace = () => {
  const obj = { stack: "" };
  if (Error.captureStackTrace) {
    Error.captureStackTrace(obj, getStackTrace);
  }
  return obj.stack;
};

export const debug = (...args: any[]) => {
  if (!IS_PROD) {
    console.log(...args, getStackTrace());
  }
};
