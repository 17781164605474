import { SocialLoginResponse } from "../generated_protos/admin/admin_signup_pb";
import { PublicAdminServicePromiseClient } from "../generated_protos/services_grpc_web_pb";
import { SocialLoginRequest } from "../generated_protos/admin/admin_signup_pb";

export const LoginWithGoogleAuth = async (
  jwt: string,
  publicAdminService: PublicAdminServicePromiseClient,
  isRegistration = false,
  awsToken?: string
): Promise<SocialLoginResponse.AsObject> => {
  const request = new SocialLoginRequest();
  request.setJwt(jwt);
  request.setRegistration(isRegistration);

  if (awsToken) {
    request.setAwsToken(awsToken);
  }

  const response = await publicAdminService.loginSocial(request);
  return response.toObject();
};
