import createClient from "openapi-fetch";
import type { paths, components } from "./apiv2";
import { restServingUrl } from "../backendConfig";

// See docs at https://github.com/drwpow/openapi-typescript/tree/main/packages/openapi-fetch
export const apiV2Client = createClient<paths>({ baseUrl: restServingUrl });

type ApiV2 = components["schemas"];
export type { ApiV2 };

export type ApiKey = ApiV2["ApiKey"];
export type ApiKeyRole = ApiV2["ApiKeyRole"];
export type Corpus = ApiV2["Corpus"];
export type CorpusCustomDimensions = Exclude<Corpus["custom_dimensions"], undefined>;
export type CorpusFilterAttributes = Exclude<Corpus["filter_attributes"], undefined>;
export type CustomDimension = CorpusCustomDimensions extends Array<infer T> ? T : never;
export type FilterAttribute = ApiV2["FilterAttribute"];
export type FilterAttributeLevel = FilterAttribute["level"];
export type FilterAttributeType = FilterAttribute["type"];
export type GenerationPreset = ApiV2["GenerationPreset"];
export type Job = ApiV2["Job"];
export type JobState = ApiV2["JobState"];
export type JobType = Job["type"];
export type Llm = ApiV2["LLM"];
export type QueryCustomDimension = {
  name: string;
  weight: number;
};

export const JobStateEnum: { [key in JobState]: key } = {
  unknown: "unknown",
  queued: "queued",
  started: "started",
  completed: "completed",
  failed: "failed",
  failed_will_retry: "failed_will_retry",
  aborted: "aborted"
};

export const JobTypeEnum: { [key in Exclude<JobType, undefined>]: key } = {
  rebuild_vector_index: "rebuild_vector_index",
  replace_filter_attributes: "replace_filter_attributes",
  unknown: "unknown"
};
