import { apiV2Client } from "./apiV2Client";

export const sendFetchGenerationPresetsRequest = async (jwt: string, customerId: string) => {
  return await apiV2Client.GET("/v2/generation_presets", {
    headers: {
      "customer-id": customerId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`
    },
    params: {
      query: {
        // Fetch all available presets to avoid pagination.
        limit: 100
      }
    }
  });
};

export const sendFetchLlmsRequest = async (jwt: string, customerId: string) => {
  return await apiV2Client.GET("/v2/llms", {
    headers: {
      "customer-id": customerId,
      "Content-Type": "application/json",
      Authorization: `Bearer ${jwt}`
    },
    params: {
      query: {
        // Fetch all available LLMs to avoid pagination.
        limit: 100
      }
    }
  });
};
