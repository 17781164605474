// source: admin/admin_job.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var status_pb = require('../status_pb.js');
goog.object.extend(proto, status_pb);
goog.exportSymbol('proto.com.vectara.admin.Job', null, global);
goog.exportSymbol('proto.com.vectara.admin.JobState', null, global);
goog.exportSymbol('proto.com.vectara.admin.JobType', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListJobsRequest', null, global);
goog.exportSymbol('proto.com.vectara.admin.ListJobsResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListJobsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.ListJobsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ListJobsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListJobsRequest.displayName = 'proto.com.vectara.admin.ListJobsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.Job = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.admin.Job.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.Job, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.Job.displayName = 'proto.com.vectara.admin.Job';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.admin.ListJobsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, 500, proto.com.vectara.admin.ListJobsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.admin.ListJobsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.admin.ListJobsResponse.displayName = 'proto.com.vectara.admin.ListJobsResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ListJobsRequest.repeatedFields_ = [2,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListJobsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListJobsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListJobsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListJobsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    jobId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    corpusIdList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    epochSecs: jspb.Message.getFieldWithDefault(msg, 3, 0),
    stateList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    numResults: jspb.Message.getFieldWithDefault(msg, 25, 0),
    pageKey: msg.getPageKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListJobsRequest}
 */
proto.com.vectara.admin.ListJobsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListJobsRequest;
  return proto.com.vectara.admin.ListJobsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListJobsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListJobsRequest}
 */
proto.com.vectara.admin.ListJobsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setJobId(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCorpusId(values[i]);
      }
      break;
    case 3:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEpochSecs(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.com.vectara.admin.JobState>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addState(values[i]);
      }
      break;
    case 25:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumResults(value);
      break;
    case 30:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListJobsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListJobsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListJobsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListJobsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJobId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCorpusIdList();
  if (f.length > 0) {
    writer.writePackedUint32(
      2,
      f
    );
  }
  f = message.getEpochSecs();
  if (f !== 0) {
    writer.writeInt64(
      3,
      f
    );
  }
  f = message.getStateList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getNumResults();
  if (f !== 0) {
    writer.writeUint32(
      25,
      f
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      30,
      f
    );
  }
};


/**
 * optional string job_id = 1;
 * @return {string}
 */
proto.com.vectara.admin.ListJobsRequest.prototype.getJobId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.ListJobsRequest} returns this
 */
proto.com.vectara.admin.ListJobsRequest.prototype.setJobId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated uint32 corpus_id = 2;
 * @return {!Array<number>}
 */
proto.com.vectara.admin.ListJobsRequest.prototype.getCorpusIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.com.vectara.admin.ListJobsRequest} returns this
 */
proto.com.vectara.admin.ListJobsRequest.prototype.setCorpusIdList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ListJobsRequest} returns this
 */
proto.com.vectara.admin.ListJobsRequest.prototype.addCorpusId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ListJobsRequest} returns this
 */
proto.com.vectara.admin.ListJobsRequest.prototype.clearCorpusIdList = function() {
  return this.setCorpusIdList([]);
};


/**
 * optional int64 epoch_secs = 3;
 * @return {number}
 */
proto.com.vectara.admin.ListJobsRequest.prototype.getEpochSecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ListJobsRequest} returns this
 */
proto.com.vectara.admin.ListJobsRequest.prototype.setEpochSecs = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * repeated JobState state = 4;
 * @return {!Array<!proto.com.vectara.admin.JobState>}
 */
proto.com.vectara.admin.ListJobsRequest.prototype.getStateList = function() {
  return /** @type {!Array<!proto.com.vectara.admin.JobState>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.com.vectara.admin.JobState>} value
 * @return {!proto.com.vectara.admin.ListJobsRequest} returns this
 */
proto.com.vectara.admin.ListJobsRequest.prototype.setStateList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.com.vectara.admin.JobState} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.ListJobsRequest} returns this
 */
proto.com.vectara.admin.ListJobsRequest.prototype.addState = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ListJobsRequest} returns this
 */
proto.com.vectara.admin.ListJobsRequest.prototype.clearStateList = function() {
  return this.setStateList([]);
};


/**
 * optional uint32 num_results = 25;
 * @return {number}
 */
proto.com.vectara.admin.ListJobsRequest.prototype.getNumResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.ListJobsRequest} returns this
 */
proto.com.vectara.admin.ListJobsRequest.prototype.setNumResults = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};


/**
 * optional bytes page_key = 30;
 * @return {string}
 */
proto.com.vectara.admin.ListJobsRequest.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 30, ""));
};


/**
 * optional bytes page_key = 30;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.admin.ListJobsRequest.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 30;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListJobsRequest.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.admin.ListJobsRequest} returns this
 */
proto.com.vectara.admin.ListJobsRequest.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 30, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.Job.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.Job.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.Job.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.Job} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.Job.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    corpusIdList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    state: jspb.Message.getFieldWithDefault(msg, 4, 0),
    tsCreate: jspb.Message.getFieldWithDefault(msg, 10, 0),
    tsStart: jspb.Message.getFieldWithDefault(msg, 15, 0),
    tsComplete: jspb.Message.getFieldWithDefault(msg, 16, 0),
    userHandle: jspb.Message.getFieldWithDefault(msg, 17, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.Job}
 */
proto.com.vectara.admin.Job.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.Job;
  return proto.com.vectara.admin.Job.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.Job} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.Job}
 */
proto.com.vectara.admin.Job.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.com.vectara.admin.JobType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCorpusId(values[i]);
      }
      break;
    case 4:
      var value = /** @type {!proto.com.vectara.admin.JobState} */ (reader.readEnum());
      msg.setState(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTsCreate(value);
      break;
    case 15:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTsStart(value);
      break;
    case 16:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setTsComplete(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setUserHandle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.Job.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.Job.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.Job} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.Job.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getCorpusIdList();
  if (f.length > 0) {
    writer.writePackedUint32(
      3,
      f
    );
  }
  f = message.getState();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getTsCreate();
  if (f !== 0) {
    writer.writeInt64(
      10,
      f
    );
  }
  f = message.getTsStart();
  if (f !== 0) {
    writer.writeInt64(
      15,
      f
    );
  }
  f = message.getTsComplete();
  if (f !== 0) {
    writer.writeInt64(
      16,
      f
    );
  }
  f = message.getUserHandle();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.vectara.admin.Job.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.Job} returns this
 */
proto.com.vectara.admin.Job.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional JobType type = 2;
 * @return {!proto.com.vectara.admin.JobType}
 */
proto.com.vectara.admin.Job.prototype.getType = function() {
  return /** @type {!proto.com.vectara.admin.JobType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.com.vectara.admin.JobType} value
 * @return {!proto.com.vectara.admin.Job} returns this
 */
proto.com.vectara.admin.Job.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated uint32 corpus_id = 3;
 * @return {!Array<number>}
 */
proto.com.vectara.admin.Job.prototype.getCorpusIdList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.com.vectara.admin.Job} returns this
 */
proto.com.vectara.admin.Job.prototype.setCorpusIdList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.Job} returns this
 */
proto.com.vectara.admin.Job.prototype.addCorpusId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.Job} returns this
 */
proto.com.vectara.admin.Job.prototype.clearCorpusIdList = function() {
  return this.setCorpusIdList([]);
};


/**
 * optional JobState state = 4;
 * @return {!proto.com.vectara.admin.JobState}
 */
proto.com.vectara.admin.Job.prototype.getState = function() {
  return /** @type {!proto.com.vectara.admin.JobState} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.com.vectara.admin.JobState} value
 * @return {!proto.com.vectara.admin.Job} returns this
 */
proto.com.vectara.admin.Job.prototype.setState = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional int64 ts_create = 10;
 * @return {number}
 */
proto.com.vectara.admin.Job.prototype.getTsCreate = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.Job} returns this
 */
proto.com.vectara.admin.Job.prototype.setTsCreate = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional int64 ts_start = 15;
 * @return {number}
 */
proto.com.vectara.admin.Job.prototype.getTsStart = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 15, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.Job} returns this
 */
proto.com.vectara.admin.Job.prototype.setTsStart = function(value) {
  return jspb.Message.setProto3IntField(this, 15, value);
};


/**
 * optional int64 ts_complete = 16;
 * @return {number}
 */
proto.com.vectara.admin.Job.prototype.getTsComplete = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 16, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.admin.Job} returns this
 */
proto.com.vectara.admin.Job.prototype.setTsComplete = function(value) {
  return jspb.Message.setProto3IntField(this, 16, value);
};


/**
 * optional string user_handle = 17;
 * @return {string}
 */
proto.com.vectara.admin.Job.prototype.getUserHandle = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.admin.Job} returns this
 */
proto.com.vectara.admin.Job.prototype.setUserHandle = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.admin.ListJobsResponse.repeatedFields_ = [1000,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.admin.ListJobsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.admin.ListJobsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.admin.ListJobsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListJobsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statusList: jspb.Message.toObjectList(msg.getStatusList(),
    status_pb.Status.toObject, includeInstance),
    jobList: jspb.Message.toObjectList(msg.getJobList(),
    proto.com.vectara.admin.Job.toObject, includeInstance),
    pageKey: msg.getPageKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.admin.ListJobsResponse}
 */
proto.com.vectara.admin.ListJobsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.admin.ListJobsResponse;
  return proto.com.vectara.admin.ListJobsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.admin.ListJobsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.admin.ListJobsResponse}
 */
proto.com.vectara.admin.ListJobsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1000:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.addStatus(value);
      break;
    case 10:
      var value = new proto.com.vectara.admin.Job;
      reader.readMessage(value,proto.com.vectara.admin.Job.deserializeBinaryFromReader);
      msg.addJob(value);
      break;
    case 500:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListJobsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.admin.ListJobsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.admin.ListJobsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.admin.ListJobsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatusList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1000,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getJobList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      10,
      f,
      proto.com.vectara.admin.Job.serializeBinaryToWriter
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      500,
      f
    );
  }
};


/**
 * repeated com.vectara.Status status = 1000;
 * @return {!Array<!proto.com.vectara.Status>}
 */
proto.com.vectara.admin.ListJobsResponse.prototype.getStatusList = function() {
  return /** @type{!Array<!proto.com.vectara.Status>} */ (
    jspb.Message.getRepeatedWrapperField(this, status_pb.Status, 1000));
};


/**
 * @param {!Array<!proto.com.vectara.Status>} value
 * @return {!proto.com.vectara.admin.ListJobsResponse} returns this
*/
proto.com.vectara.admin.ListJobsResponse.prototype.setStatusList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1000, value);
};


/**
 * @param {!proto.com.vectara.Status=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.Status}
 */
proto.com.vectara.admin.ListJobsResponse.prototype.addStatus = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1000, opt_value, proto.com.vectara.Status, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ListJobsResponse} returns this
 */
proto.com.vectara.admin.ListJobsResponse.prototype.clearStatusList = function() {
  return this.setStatusList([]);
};


/**
 * repeated Job job = 10;
 * @return {!Array<!proto.com.vectara.admin.Job>}
 */
proto.com.vectara.admin.ListJobsResponse.prototype.getJobList = function() {
  return /** @type{!Array<!proto.com.vectara.admin.Job>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.admin.Job, 10));
};


/**
 * @param {!Array<!proto.com.vectara.admin.Job>} value
 * @return {!proto.com.vectara.admin.ListJobsResponse} returns this
*/
proto.com.vectara.admin.ListJobsResponse.prototype.setJobList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 10, value);
};


/**
 * @param {!proto.com.vectara.admin.Job=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.admin.Job}
 */
proto.com.vectara.admin.ListJobsResponse.prototype.addJob = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 10, opt_value, proto.com.vectara.admin.Job, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.admin.ListJobsResponse} returns this
 */
proto.com.vectara.admin.ListJobsResponse.prototype.clearJobList = function() {
  return this.setJobList([]);
};


/**
 * optional bytes page_key = 500;
 * @return {string}
 */
proto.com.vectara.admin.ListJobsResponse.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 500, ""));
};


/**
 * optional bytes page_key = 500;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.admin.ListJobsResponse.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 500;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.admin.ListJobsResponse.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.admin.ListJobsResponse} returns this
 */
proto.com.vectara.admin.ListJobsResponse.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 500, value);
};


/**
 * @enum {number}
 */
proto.com.vectara.admin.JobType = {
  JOB__UNKNOWN: 0,
  JOB__CORPUS_REBUILD_VECTOR_INDEX: 1,
  JOB__CORPUS_REPLACE_FILTER_ATTRS: 2
};

/**
 * @enum {number}
 */
proto.com.vectara.admin.JobState = {
  JOB_STATE__UNKNOWN: 0,
  JOB_STATE__QUEUED: 5,
  JOB_STATE__STARTED: 10,
  JOB_STATE__COMPLETED: 15,
  JOB_STATE__FAILED: 20,
  JOB_STATE__TRANSIENT_FAILURE_RETRY_IMMINENT: 25,
  JOB_STATE__ABORTED: 30
};

goog.object.extend(exports, proto.com.vectara.admin);
