// source: chat.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var status_pb = require('./status_pb.js');
goog.object.extend(proto, status_pb);
goog.exportSymbol('proto.com.vectara.chat.Conversation', null, global);
goog.exportSymbol('proto.com.vectara.chat.DeleteConversationsRequest', null, global);
goog.exportSymbol('proto.com.vectara.chat.DeleteConversationsResponse', null, global);
goog.exportSymbol('proto.com.vectara.chat.DeleteTurnsRequest', null, global);
goog.exportSymbol('proto.com.vectara.chat.DeleteTurnsResponse', null, global);
goog.exportSymbol('proto.com.vectara.chat.DisableTurnsRequest', null, global);
goog.exportSymbol('proto.com.vectara.chat.DisableTurnsResponse', null, global);
goog.exportSymbol('proto.com.vectara.chat.ListConversationsPageKey', null, global);
goog.exportSymbol('proto.com.vectara.chat.ListConversationsRequest', null, global);
goog.exportSymbol('proto.com.vectara.chat.ListConversationsResponse', null, global);
goog.exportSymbol('proto.com.vectara.chat.ReadConversationsRequest', null, global);
goog.exportSymbol('proto.com.vectara.chat.ReadConversationsResponse', null, global);
goog.exportSymbol('proto.com.vectara.chat.Turn', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.chat.Turn = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.chat.Turn, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.chat.Turn.displayName = 'proto.com.vectara.chat.Turn';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.chat.Conversation = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.chat.Conversation.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.chat.Conversation, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.chat.Conversation.displayName = 'proto.com.vectara.chat.Conversation';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.chat.ListConversationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.chat.ListConversationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.chat.ListConversationsRequest.displayName = 'proto.com.vectara.chat.ListConversationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.chat.ListConversationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.chat.ListConversationsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.chat.ListConversationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.chat.ListConversationsResponse.displayName = 'proto.com.vectara.chat.ListConversationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.chat.ReadConversationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.chat.ReadConversationsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.chat.ReadConversationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.chat.ReadConversationsRequest.displayName = 'proto.com.vectara.chat.ReadConversationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.chat.ReadConversationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.chat.ReadConversationsResponse.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.chat.ReadConversationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.chat.ReadConversationsResponse.displayName = 'proto.com.vectara.chat.ReadConversationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.chat.DeleteConversationsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.com.vectara.chat.DeleteConversationsRequest.repeatedFields_, null);
};
goog.inherits(proto.com.vectara.chat.DeleteConversationsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.chat.DeleteConversationsRequest.displayName = 'proto.com.vectara.chat.DeleteConversationsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.chat.DeleteConversationsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.chat.DeleteConversationsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.chat.DeleteConversationsResponse.displayName = 'proto.com.vectara.chat.DeleteConversationsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.chat.DeleteTurnsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.chat.DeleteTurnsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.chat.DeleteTurnsRequest.displayName = 'proto.com.vectara.chat.DeleteTurnsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.chat.DeleteTurnsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.chat.DeleteTurnsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.chat.DeleteTurnsResponse.displayName = 'proto.com.vectara.chat.DeleteTurnsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.chat.DisableTurnsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.chat.DisableTurnsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.chat.DisableTurnsRequest.displayName = 'proto.com.vectara.chat.DisableTurnsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.chat.DisableTurnsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.chat.DisableTurnsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.chat.DisableTurnsResponse.displayName = 'proto.com.vectara.chat.DisableTurnsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.com.vectara.chat.ListConversationsPageKey = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.com.vectara.chat.ListConversationsPageKey, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.com.vectara.chat.ListConversationsPageKey.displayName = 'proto.com.vectara.chat.ListConversationsPageKey';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.chat.Turn.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.chat.Turn.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.chat.Turn} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.Turn.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    conversationId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    query: jspb.Message.getFieldWithDefault(msg, 10, ""),
    answer: jspb.Message.getFieldWithDefault(msg, 15, ""),
    enabled: jspb.Message.getBooleanFieldWithDefault(msg, 20, false),
    epochSecs: jspb.Message.getFieldWithDefault(msg, 25, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.chat.Turn}
 */
proto.com.vectara.chat.Turn.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.chat.Turn;
  return proto.com.vectara.chat.Turn.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.chat.Turn} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.chat.Turn}
 */
proto.com.vectara.chat.Turn.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setConversationId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setQuery(value);
      break;
    case 15:
      var value = /** @type {string} */ (reader.readString());
      msg.setAnswer(value);
      break;
    case 20:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEnabled(value);
      break;
    case 25:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setEpochSecs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.chat.Turn.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.chat.Turn.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.chat.Turn} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.Turn.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getConversationId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getQuery();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getAnswer();
  if (f.length > 0) {
    writer.writeString(
      15,
      f
    );
  }
  f = message.getEnabled();
  if (f) {
    writer.writeBool(
      20,
      f
    );
  }
  f = message.getEpochSecs();
  if (f !== 0) {
    writer.writeInt64(
      25,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.vectara.chat.Turn.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.chat.Turn} returns this
 */
proto.com.vectara.chat.Turn.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string conversation_id = 5;
 * @return {string}
 */
proto.com.vectara.chat.Turn.prototype.getConversationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.chat.Turn} returns this
 */
proto.com.vectara.chat.Turn.prototype.setConversationId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string query = 10;
 * @return {string}
 */
proto.com.vectara.chat.Turn.prototype.getQuery = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.chat.Turn} returns this
 */
proto.com.vectara.chat.Turn.prototype.setQuery = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string answer = 15;
 * @return {string}
 */
proto.com.vectara.chat.Turn.prototype.getAnswer = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 15, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.chat.Turn} returns this
 */
proto.com.vectara.chat.Turn.prototype.setAnswer = function(value) {
  return jspb.Message.setProto3StringField(this, 15, value);
};


/**
 * optional bool enabled = 20;
 * @return {boolean}
 */
proto.com.vectara.chat.Turn.prototype.getEnabled = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 20, false));
};


/**
 * @param {boolean} value
 * @return {!proto.com.vectara.chat.Turn} returns this
 */
proto.com.vectara.chat.Turn.prototype.setEnabled = function(value) {
  return jspb.Message.setProto3BooleanField(this, 20, value);
};


/**
 * optional int64 epoch_secs = 25;
 * @return {number}
 */
proto.com.vectara.chat.Turn.prototype.getEpochSecs = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 25, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.chat.Turn} returns this
 */
proto.com.vectara.chat.Turn.prototype.setEpochSecs = function(value) {
  return jspb.Message.setProto3IntField(this, 25, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.chat.Conversation.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.chat.Conversation.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.chat.Conversation.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.chat.Conversation} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.Conversation.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    turnList: jspb.Message.toObjectList(msg.getTurnList(),
    proto.com.vectara.chat.Turn.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.chat.Conversation}
 */
proto.com.vectara.chat.Conversation.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.chat.Conversation;
  return proto.com.vectara.chat.Conversation.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.chat.Conversation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.chat.Conversation}
 */
proto.com.vectara.chat.Conversation.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 5:
      var value = new proto.com.vectara.chat.Turn;
      reader.readMessage(value,proto.com.vectara.chat.Turn.deserializeBinaryFromReader);
      msg.addTurn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.chat.Conversation.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.chat.Conversation.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.chat.Conversation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.Conversation.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTurnList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.com.vectara.chat.Turn.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.com.vectara.chat.Conversation.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.chat.Conversation} returns this
 */
proto.com.vectara.chat.Conversation.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Turn turn = 5;
 * @return {!Array<!proto.com.vectara.chat.Turn>}
 */
proto.com.vectara.chat.Conversation.prototype.getTurnList = function() {
  return /** @type{!Array<!proto.com.vectara.chat.Turn>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.chat.Turn, 5));
};


/**
 * @param {!Array<!proto.com.vectara.chat.Turn>} value
 * @return {!proto.com.vectara.chat.Conversation} returns this
*/
proto.com.vectara.chat.Conversation.prototype.setTurnList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.com.vectara.chat.Turn=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.chat.Turn}
 */
proto.com.vectara.chat.Conversation.prototype.addTurn = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.com.vectara.chat.Turn, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.chat.Conversation} returns this
 */
proto.com.vectara.chat.Conversation.prototype.clearTurnList = function() {
  return this.setTurnList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.chat.ListConversationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.chat.ListConversationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.chat.ListConversationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.ListConversationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    numResults: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pageKey: msg.getPageKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.chat.ListConversationsRequest}
 */
proto.com.vectara.chat.ListConversationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.chat.ListConversationsRequest;
  return proto.com.vectara.chat.ListConversationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.chat.ListConversationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.chat.ListConversationsRequest}
 */
proto.com.vectara.chat.ListConversationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setNumResults(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.chat.ListConversationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.chat.ListConversationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.chat.ListConversationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.ListConversationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getNumResults();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      10,
      f
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.chat.ListConversationsRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.chat.ListConversationsRequest} returns this
 */
proto.com.vectara.chat.ListConversationsRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 num_results = 5;
 * @return {number}
 */
proto.com.vectara.chat.ListConversationsRequest.prototype.getNumResults = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.chat.ListConversationsRequest} returns this
 */
proto.com.vectara.chat.ListConversationsRequest.prototype.setNumResults = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional bytes page_key = 10;
 * @return {string}
 */
proto.com.vectara.chat.ListConversationsRequest.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes page_key = 10;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.chat.ListConversationsRequest.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.chat.ListConversationsRequest.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.chat.ListConversationsRequest} returns this
 */
proto.com.vectara.chat.ListConversationsRequest.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.chat.ListConversationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.chat.ListConversationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.chat.ListConversationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.chat.ListConversationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.ListConversationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    conversationList: jspb.Message.toObjectList(msg.getConversationList(),
    proto.com.vectara.chat.Turn.toObject, includeInstance),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f),
    pageKey: msg.getPageKey_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.chat.ListConversationsResponse}
 */
proto.com.vectara.chat.ListConversationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.chat.ListConversationsResponse;
  return proto.com.vectara.chat.ListConversationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.chat.ListConversationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.chat.ListConversationsResponse}
 */
proto.com.vectara.chat.ListConversationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.chat.Turn;
      reader.readMessage(value,proto.com.vectara.chat.Turn.deserializeBinaryFromReader);
      msg.addConversation(value);
      break;
    case 5:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    case 10:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPageKey(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.chat.ListConversationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.chat.ListConversationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.chat.ListConversationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.ListConversationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConversationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.chat.Turn.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
  f = message.getPageKey_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      10,
      f
    );
  }
};


/**
 * repeated Turn conversation = 1;
 * @return {!Array<!proto.com.vectara.chat.Turn>}
 */
proto.com.vectara.chat.ListConversationsResponse.prototype.getConversationList = function() {
  return /** @type{!Array<!proto.com.vectara.chat.Turn>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.chat.Turn, 1));
};


/**
 * @param {!Array<!proto.com.vectara.chat.Turn>} value
 * @return {!proto.com.vectara.chat.ListConversationsResponse} returns this
*/
proto.com.vectara.chat.ListConversationsResponse.prototype.setConversationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.chat.Turn=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.chat.Turn}
 */
proto.com.vectara.chat.ListConversationsResponse.prototype.addConversation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.chat.Turn, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.chat.ListConversationsResponse} returns this
 */
proto.com.vectara.chat.ListConversationsResponse.prototype.clearConversationList = function() {
  return this.setConversationList([]);
};


/**
 * optional com.vectara.Status status = 5;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.chat.ListConversationsResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 5));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.chat.ListConversationsResponse} returns this
*/
proto.com.vectara.chat.ListConversationsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.chat.ListConversationsResponse} returns this
 */
proto.com.vectara.chat.ListConversationsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.chat.ListConversationsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional bytes page_key = 10;
 * @return {string}
 */
proto.com.vectara.chat.ListConversationsResponse.prototype.getPageKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * optional bytes page_key = 10;
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {string}
 */
proto.com.vectara.chat.ListConversationsResponse.prototype.getPageKey_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPageKey()));
};


/**
 * optional bytes page_key = 10;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPageKey()`
 * @return {!Uint8Array}
 */
proto.com.vectara.chat.ListConversationsResponse.prototype.getPageKey_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPageKey()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.chat.ListConversationsResponse} returns this
 */
proto.com.vectara.chat.ListConversationsResponse.prototype.setPageKey = function(value) {
  return jspb.Message.setProto3BytesField(this, 10, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.chat.ReadConversationsRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.chat.ReadConversationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.chat.ReadConversationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.chat.ReadConversationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.ReadConversationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    conversationIdList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.chat.ReadConversationsRequest}
 */
proto.com.vectara.chat.ReadConversationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.chat.ReadConversationsRequest;
  return proto.com.vectara.chat.ReadConversationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.chat.ReadConversationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.chat.ReadConversationsRequest}
 */
proto.com.vectara.chat.ReadConversationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addConversationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.chat.ReadConversationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.chat.ReadConversationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.chat.ReadConversationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.ReadConversationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getConversationIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.chat.ReadConversationsRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.chat.ReadConversationsRequest} returns this
 */
proto.com.vectara.chat.ReadConversationsRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string conversation_id = 5;
 * @return {!Array<string>}
 */
proto.com.vectara.chat.ReadConversationsRequest.prototype.getConversationIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.vectara.chat.ReadConversationsRequest} returns this
 */
proto.com.vectara.chat.ReadConversationsRequest.prototype.setConversationIdList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.chat.ReadConversationsRequest} returns this
 */
proto.com.vectara.chat.ReadConversationsRequest.prototype.addConversationId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.chat.ReadConversationsRequest} returns this
 */
proto.com.vectara.chat.ReadConversationsRequest.prototype.clearConversationIdList = function() {
  return this.setConversationIdList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.chat.ReadConversationsResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.chat.ReadConversationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.chat.ReadConversationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.chat.ReadConversationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.ReadConversationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    conversationList: jspb.Message.toObjectList(msg.getConversationList(),
    proto.com.vectara.chat.Conversation.toObject, includeInstance),
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.chat.ReadConversationsResponse}
 */
proto.com.vectara.chat.ReadConversationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.chat.ReadConversationsResponse;
  return proto.com.vectara.chat.ReadConversationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.chat.ReadConversationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.chat.ReadConversationsResponse}
 */
proto.com.vectara.chat.ReadConversationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.com.vectara.chat.Conversation;
      reader.readMessage(value,proto.com.vectara.chat.Conversation.deserializeBinaryFromReader);
      msg.addConversation(value);
      break;
    case 5:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.chat.ReadConversationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.chat.ReadConversationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.chat.ReadConversationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.ReadConversationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getConversationList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.com.vectara.chat.Conversation.serializeBinaryToWriter
    );
  }
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Conversation Conversation = 1;
 * @return {!Array<!proto.com.vectara.chat.Conversation>}
 */
proto.com.vectara.chat.ReadConversationsResponse.prototype.getConversationList = function() {
  return /** @type{!Array<!proto.com.vectara.chat.Conversation>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.com.vectara.chat.Conversation, 1));
};


/**
 * @param {!Array<!proto.com.vectara.chat.Conversation>} value
 * @return {!proto.com.vectara.chat.ReadConversationsResponse} returns this
*/
proto.com.vectara.chat.ReadConversationsResponse.prototype.setConversationList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.com.vectara.chat.Conversation=} opt_value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.chat.Conversation}
 */
proto.com.vectara.chat.ReadConversationsResponse.prototype.addConversation = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.com.vectara.chat.Conversation, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.chat.ReadConversationsResponse} returns this
 */
proto.com.vectara.chat.ReadConversationsResponse.prototype.clearConversationList = function() {
  return this.setConversationList([]);
};


/**
 * optional com.vectara.Status status = 5;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.chat.ReadConversationsResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 5));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.chat.ReadConversationsResponse} returns this
*/
proto.com.vectara.chat.ReadConversationsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.chat.ReadConversationsResponse} returns this
 */
proto.com.vectara.chat.ReadConversationsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.chat.ReadConversationsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.com.vectara.chat.DeleteConversationsRequest.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.chat.DeleteConversationsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.chat.DeleteConversationsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.chat.DeleteConversationsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.DeleteConversationsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    conversationIdList: (f = jspb.Message.getRepeatedField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.chat.DeleteConversationsRequest}
 */
proto.com.vectara.chat.DeleteConversationsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.chat.DeleteConversationsRequest;
  return proto.com.vectara.chat.DeleteConversationsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.chat.DeleteConversationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.chat.DeleteConversationsRequest}
 */
proto.com.vectara.chat.DeleteConversationsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.addConversationId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.chat.DeleteConversationsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.chat.DeleteConversationsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.chat.DeleteConversationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.DeleteConversationsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getConversationIdList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      5,
      f
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.chat.DeleteConversationsRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.chat.DeleteConversationsRequest} returns this
 */
proto.com.vectara.chat.DeleteConversationsRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string conversation_id = 5;
 * @return {!Array<string>}
 */
proto.com.vectara.chat.DeleteConversationsRequest.prototype.getConversationIdList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 5));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.com.vectara.chat.DeleteConversationsRequest} returns this
 */
proto.com.vectara.chat.DeleteConversationsRequest.prototype.setConversationIdList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.com.vectara.chat.DeleteConversationsRequest} returns this
 */
proto.com.vectara.chat.DeleteConversationsRequest.prototype.addConversationId = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.com.vectara.chat.DeleteConversationsRequest} returns this
 */
proto.com.vectara.chat.DeleteConversationsRequest.prototype.clearConversationIdList = function() {
  return this.setConversationIdList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.chat.DeleteConversationsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.chat.DeleteConversationsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.chat.DeleteConversationsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.DeleteConversationsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.chat.DeleteConversationsResponse}
 */
proto.com.vectara.chat.DeleteConversationsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.chat.DeleteConversationsResponse;
  return proto.com.vectara.chat.DeleteConversationsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.chat.DeleteConversationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.chat.DeleteConversationsResponse}
 */
proto.com.vectara.chat.DeleteConversationsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.chat.DeleteConversationsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.chat.DeleteConversationsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.chat.DeleteConversationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.DeleteConversationsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.vectara.Status status = 1;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.chat.DeleteConversationsResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.chat.DeleteConversationsResponse} returns this
*/
proto.com.vectara.chat.DeleteConversationsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.chat.DeleteConversationsResponse} returns this
 */
proto.com.vectara.chat.DeleteConversationsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.chat.DeleteConversationsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.chat.DeleteTurnsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.chat.DeleteTurnsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.chat.DeleteTurnsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.DeleteTurnsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    conversationId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    turnId: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.chat.DeleteTurnsRequest}
 */
proto.com.vectara.chat.DeleteTurnsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.chat.DeleteTurnsRequest;
  return proto.com.vectara.chat.DeleteTurnsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.chat.DeleteTurnsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.chat.DeleteTurnsRequest}
 */
proto.com.vectara.chat.DeleteTurnsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setConversationId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTurnId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.chat.DeleteTurnsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.chat.DeleteTurnsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.chat.DeleteTurnsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.DeleteTurnsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getConversationId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTurnId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.chat.DeleteTurnsRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.chat.DeleteTurnsRequest} returns this
 */
proto.com.vectara.chat.DeleteTurnsRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string conversation_id = 5;
 * @return {string}
 */
proto.com.vectara.chat.DeleteTurnsRequest.prototype.getConversationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.chat.DeleteTurnsRequest} returns this
 */
proto.com.vectara.chat.DeleteTurnsRequest.prototype.setConversationId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string turn_id = 10;
 * @return {string}
 */
proto.com.vectara.chat.DeleteTurnsRequest.prototype.getTurnId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.chat.DeleteTurnsRequest} returns this
 */
proto.com.vectara.chat.DeleteTurnsRequest.prototype.setTurnId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.chat.DeleteTurnsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.chat.DeleteTurnsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.chat.DeleteTurnsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.DeleteTurnsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.chat.DeleteTurnsResponse}
 */
proto.com.vectara.chat.DeleteTurnsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.chat.DeleteTurnsResponse;
  return proto.com.vectara.chat.DeleteTurnsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.chat.DeleteTurnsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.chat.DeleteTurnsResponse}
 */
proto.com.vectara.chat.DeleteTurnsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.chat.DeleteTurnsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.chat.DeleteTurnsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.chat.DeleteTurnsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.DeleteTurnsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.vectara.Status status = 1;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.chat.DeleteTurnsResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.chat.DeleteTurnsResponse} returns this
*/
proto.com.vectara.chat.DeleteTurnsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.chat.DeleteTurnsResponse} returns this
 */
proto.com.vectara.chat.DeleteTurnsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.chat.DeleteTurnsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.chat.DisableTurnsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.chat.DisableTurnsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.chat.DisableTurnsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.DisableTurnsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    corpusId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    conversationId: jspb.Message.getFieldWithDefault(msg, 5, ""),
    turnId: jspb.Message.getFieldWithDefault(msg, 10, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.chat.DisableTurnsRequest}
 */
proto.com.vectara.chat.DisableTurnsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.chat.DisableTurnsRequest;
  return proto.com.vectara.chat.DisableTurnsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.chat.DisableTurnsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.chat.DisableTurnsRequest}
 */
proto.com.vectara.chat.DisableTurnsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setConversationId(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setTurnId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.chat.DisableTurnsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.chat.DisableTurnsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.chat.DisableTurnsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.DisableTurnsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getConversationId();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getTurnId();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
};


/**
 * optional uint32 corpus_id = 1;
 * @return {number}
 */
proto.com.vectara.chat.DisableTurnsRequest.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.chat.DisableTurnsRequest} returns this
 */
proto.com.vectara.chat.DisableTurnsRequest.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string conversation_id = 5;
 * @return {string}
 */
proto.com.vectara.chat.DisableTurnsRequest.prototype.getConversationId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.chat.DisableTurnsRequest} returns this
 */
proto.com.vectara.chat.DisableTurnsRequest.prototype.setConversationId = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string turn_id = 10;
 * @return {string}
 */
proto.com.vectara.chat.DisableTurnsRequest.prototype.getTurnId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.chat.DisableTurnsRequest} returns this
 */
proto.com.vectara.chat.DisableTurnsRequest.prototype.setTurnId = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.chat.DisableTurnsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.chat.DisableTurnsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.chat.DisableTurnsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.DisableTurnsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    status: (f = msg.getStatus()) && status_pb.Status.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.chat.DisableTurnsResponse}
 */
proto.com.vectara.chat.DisableTurnsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.chat.DisableTurnsResponse;
  return proto.com.vectara.chat.DisableTurnsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.chat.DisableTurnsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.chat.DisableTurnsResponse}
 */
proto.com.vectara.chat.DisableTurnsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new status_pb.Status;
      reader.readMessage(value,status_pb.Status.deserializeBinaryFromReader);
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.chat.DisableTurnsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.chat.DisableTurnsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.chat.DisableTurnsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.DisableTurnsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatus();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      status_pb.Status.serializeBinaryToWriter
    );
  }
};


/**
 * optional com.vectara.Status status = 1;
 * @return {?proto.com.vectara.Status}
 */
proto.com.vectara.chat.DisableTurnsResponse.prototype.getStatus = function() {
  return /** @type{?proto.com.vectara.Status} */ (
    jspb.Message.getWrapperField(this, status_pb.Status, 1));
};


/**
 * @param {?proto.com.vectara.Status|undefined} value
 * @return {!proto.com.vectara.chat.DisableTurnsResponse} returns this
*/
proto.com.vectara.chat.DisableTurnsResponse.prototype.setStatus = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.chat.DisableTurnsResponse} returns this
 */
proto.com.vectara.chat.DisableTurnsResponse.prototype.clearStatus = function() {
  return this.setStatus(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.chat.DisableTurnsResponse.prototype.hasStatus = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.com.vectara.chat.ListConversationsPageKey.prototype.toObject = function(opt_includeInstance) {
  return proto.com.vectara.chat.ListConversationsPageKey.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.com.vectara.chat.ListConversationsPageKey} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.ListConversationsPageKey.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    customerId: jspb.Message.getFieldWithDefault(msg, 5, 0),
    corpusId: jspb.Message.getFieldWithDefault(msg, 10, 0),
    request: (f = msg.getRequest()) && proto.com.vectara.chat.ListConversationsRequest.toObject(includeInstance, f),
    nextPageToken: msg.getNextPageToken_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.com.vectara.chat.ListConversationsPageKey}
 */
proto.com.vectara.chat.ListConversationsPageKey.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.com.vectara.chat.ListConversationsPageKey;
  return proto.com.vectara.chat.ListConversationsPageKey.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.com.vectara.chat.ListConversationsPageKey} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.com.vectara.chat.ListConversationsPageKey}
 */
proto.com.vectara.chat.ListConversationsPageKey.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCustomerId(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setCorpusId(value);
      break;
    case 15:
      var value = new proto.com.vectara.chat.ListConversationsRequest;
      reader.readMessage(value,proto.com.vectara.chat.ListConversationsRequest.deserializeBinaryFromReader);
      msg.setRequest(value);
      break;
    case 20:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setNextPageToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.com.vectara.chat.ListConversationsPageKey.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.com.vectara.chat.ListConversationsPageKey.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.com.vectara.chat.ListConversationsPageKey} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.com.vectara.chat.ListConversationsPageKey.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getCustomerId();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
  f = message.getCorpusId();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
  f = message.getRequest();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      proto.com.vectara.chat.ListConversationsRequest.serializeBinaryToWriter
    );
  }
  f = message.getNextPageToken_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      20,
      f
    );
  }
};


/**
 * optional string username = 1;
 * @return {string}
 */
proto.com.vectara.chat.ListConversationsPageKey.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.com.vectara.chat.ListConversationsPageKey} returns this
 */
proto.com.vectara.chat.ListConversationsPageKey.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 customer_id = 5;
 * @return {number}
 */
proto.com.vectara.chat.ListConversationsPageKey.prototype.getCustomerId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.chat.ListConversationsPageKey} returns this
 */
proto.com.vectara.chat.ListConversationsPageKey.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};


/**
 * optional uint32 corpus_id = 10;
 * @return {number}
 */
proto.com.vectara.chat.ListConversationsPageKey.prototype.getCorpusId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.com.vectara.chat.ListConversationsPageKey} returns this
 */
proto.com.vectara.chat.ListConversationsPageKey.prototype.setCorpusId = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


/**
 * optional ListConversationsRequest request = 15;
 * @return {?proto.com.vectara.chat.ListConversationsRequest}
 */
proto.com.vectara.chat.ListConversationsPageKey.prototype.getRequest = function() {
  return /** @type{?proto.com.vectara.chat.ListConversationsRequest} */ (
    jspb.Message.getWrapperField(this, proto.com.vectara.chat.ListConversationsRequest, 15));
};


/**
 * @param {?proto.com.vectara.chat.ListConversationsRequest|undefined} value
 * @return {!proto.com.vectara.chat.ListConversationsPageKey} returns this
*/
proto.com.vectara.chat.ListConversationsPageKey.prototype.setRequest = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.com.vectara.chat.ListConversationsPageKey} returns this
 */
proto.com.vectara.chat.ListConversationsPageKey.prototype.clearRequest = function() {
  return this.setRequest(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.com.vectara.chat.ListConversationsPageKey.prototype.hasRequest = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional bytes next_page_token = 20;
 * @return {string}
 */
proto.com.vectara.chat.ListConversationsPageKey.prototype.getNextPageToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 20, ""));
};


/**
 * optional bytes next_page_token = 20;
 * This is a type-conversion wrapper around `getNextPageToken()`
 * @return {string}
 */
proto.com.vectara.chat.ListConversationsPageKey.prototype.getNextPageToken_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getNextPageToken()));
};


/**
 * optional bytes next_page_token = 20;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getNextPageToken()`
 * @return {!Uint8Array}
 */
proto.com.vectara.chat.ListConversationsPageKey.prototype.getNextPageToken_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getNextPageToken()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.com.vectara.chat.ListConversationsPageKey} returns this
 */
proto.com.vectara.chat.ListConversationsPageKey.prototype.setNextPageToken = function(value) {
  return jspb.Message.setProto3BytesField(this, 20, value);
};


goog.object.extend(exports, proto.com.vectara.chat);
