import { Job, JobStateEnum, JobTypeEnum } from "../../admin/apiV2Client";

export const getActiveReplaceFilterJobs = (jobs: Job[]) => {
  return jobs
    .filter((job) => {
      if (!job.state) return false;
      return (
        job.type === JobTypeEnum.replace_filter_attributes &&
        [JobStateEnum.started, JobStateEnum.failed_will_retry, JobStateEnum.queued].some((state) => state === job.state)
      );
    })
    .sort((a, b) => {
      switch (a.state) {
        case JobStateEnum.started:
          return -1;

        case JobStateEnum.queued:
          return 1;

        case JobStateEnum.failed_will_retry:
          if (b.state === JobStateEnum.started) {
            return 1;
          }

          if (b.state === JobStateEnum.queued) {
            return -1;
          }
      }

      return 0;
    });
};
